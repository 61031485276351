

const ProfileReducer = (state=0, action)=>{
    switch(action.type)
    {
        case "Login" :
            return state = action.data;
        default:
            return state;
    }
}
export default ProfileReducer;