import { getCurrencyCountry, getToken, removeToken } from "./Storage";

// export function ConvertCurrency(price,quantity = 1,currency,rate)
// {
//     let amount = 0;
//     if(price != 0 )
//     {
//         amount = parseFloat(price.toString().replace(/,/g,''))
//     }
    
//     let total = (amount * rate) * quantity;
//     let data  = total.toLocaleString('en-'+getCurrencyCountry().countryCode,
//                     { 
//                         style: 'currency', currency: currency 
//                     }, 
//                     {
//                         minimumFractionDigits: 2, 
//                         maximumFractionDigits: 2 
//                     }
//                 )
//     return data;
// }

export function ConvertCurrency(price, quantity = 1, currency, rate) {
    // Parse the price value as a float
    let amount = parseFloat(price.toString().replace(/,/g, ''));
  
    // Calculate the total amount based on the price, quantity, and rate
    let total = amount * quantity * rate;
  
    // Format the total amount as a currency string using the appropriate currency symbol
    const currencySymbol = currency === 'UGX' ? 'code' : 'symbol';
    // let currencySymbol;
    // if (currency === 'UGX') {
    //   currencySymbol = 'code';
    // } else if (currency === 'USD') {
    //   currencySymbol = '$';
    // } else {
    //   currencySymbol = 'symbol';
    // }
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      currencyDisplay: currencySymbol,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currencySign: 'standard',
      currencySymbol: currencySymbol,
    }).format(total);
  
    return formattedValue;
  }
  