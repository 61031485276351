import axios from "axios";
import {CommonBaseUrl,EVMarketUrl} from "./Url";
import { getToken, removeToken } from "./Storage";

const commonBaseUrl = CommonBaseUrl();
const profileUrl = commonBaseUrl+'user/profile';
const token = getToken();

export function ProfileApi(data=""){
    const config = {
      headers: { 'Authorization': `Bearer ${data}` }
  };
  return axios.get(profileUrl,config);
}