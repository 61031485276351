import React, { useState, useEffect } from "react";

import ReactFlagsSelect from "react-flags-select";
import { getToken, setToken, removeToken, removeCartData, removeCartIdData, removeWishlistData, removeWishlistIdData, removePathName, setCurrencyCountry, getCurrencyCountry } from "../API/Storage";
import { UserDetails, cartDetails, wishlistDetails, wishlistCount, currencyRate } from "../Action/action";

import { useSelector, useDispatch } from "react-redux";
import { AllCurrencyList, getGeoInfo, CurrencyRateApi, GetLocationType, CheckCurrency } from '../API/UnAuthApi';

export default function CountryList(props) {
  //const [select, setSelect] = useState(props.selectCountry);
  const profile = useSelector((state) => state.ProfileReducer);
  const [currency, setCurrency] = useState();
  const [select, setSelect] = useState(getCurrencyCountry().countryCode);
  const myown = (select);
  const dispatch = useDispatch();
  // const onSelect = (code) => {
  //   setSelect(code)
  // };
  useEffect(()=>{
    setSelect(getCurrencyCountry().countryCode)
  },[getCurrencyCountry()]);
  function CurrencyClick(code) {
    const selectedCurrency = props.currencyList.find((currency) => currency.country_code === code);
    // setSelect(code);
    let curcoun = selectedCurrency.code + "," + selectedCurrency.country_code;
    let currencyArray = curcoun.split(",");
    let data;
    let errorCountry = currencyArray[0];
    CheckCurrency({ currency: currencyArray[0] }).then((result) => {
        data = code;
        setCurrency(data)
        dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
        setCurrencyCountry(currencyArray[0], currencyArray[1])
    }).catch((err) => {
        data = "USD,US";
        currencyArray = data.split(",")
        setCurrency(data)
        dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
        setCurrencyCountry(currencyArray[0], currencyArray[1])
        props.setErrorMessage("We dont have products on " + errorCountry + " currency")
    });
}
  // useEffect(() => {
  //   const selectedCurrency = props.currencyList.find((currency) => currency.country_code === select);
  //   if (selectedCurrency) {
  //     let curcoun = selectedCurrency.code + "," + selectedCurrency.country_code;
  //     let currencyArray = curcoun.split(",");
  //     let data;
  //     let errorCountry = currencyArray[0];
  //     CheckCurrency({ currency: currencyArray[0] }).then((result) => {
  //       data = curcoun;
  //       setCurrency(data)
  //       dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
  //       setCurrencyCountry(currencyArray[0], currencyArray[1])
  //     }).catch((err) => {
  //       data = "USD,US";
  //       currencyArray = data.split(",")
  //       setCurrency(data)
  //       dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
  //       setCurrencyCountry(currencyArray[0], currencyArray[1])
  //       props.setErrorMessage("We dont have products on " + errorCountry + " currency")
  //     });
  //   }
  // }, [select])
  useEffect(() => {
    CurrencyRateApi(getCurrencyCountry().currencyCode).then((result) => {
      dispatch(currencyRate(result.data.data ? result.data.data.rate : 1))
    }).catch((err) => {

    });
  }, [currency, select])
  const countryCodes = props.currencyList.map(currency => currency.country_code);
  const countryLables = countryCodes.reduce((acc, code) => {
    acc[code.toUpperCase()] = code;
    return acc;
  }, {});
  

  return (
    <div className="App">

      <ReactFlagsSelect
        selected={myown}
        onSelect={CurrencyClick}
        //countries={countryCodes}
        searchable={true}
        placeholder="Select Country"
        countries={countryCodes}
        customLabels={countryLables}
      /*showSelectedLabel={showSelectedLabel}
      selectedSize={selectedSize}
      showOptionLabel={showOptionLabel}
      optionsSize={optionsSize}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      alignOptionsToRight={alignOptionsToRight}
      fullWidth={fullWidth}
      disabled={disabled} */
      />

    </div>
  );
}
