import React,{useState,useEffect} from 'react'
import Carousel from "../components/Carousel/Carousel"
import { ImageUrl } from '../API/Url';
import { Navigate, useNavigate, useSearchParams, useLocation, Link, useParams } from 'react-router-dom';
import { ConvertCurrency } from '../API/currency';
import { useSelector, useDispatch } from "react-redux";
import { IsAuthenticated } from '../API/CheckAuth'
import { AddToCartApi, CartListApi } from '../API/CartApi';
import { setCartData, setCartIdData, getCartData, getCartIdData, removeCartData, removeCartIdData, getWishlistIdData, getWishlistData, setWishlistData, setWishlistIdData, setPathName, getCurrencyCountry } from '../API/Storage'
import { UserDetails, cartDetails, wishlistDetails, wishlistCount, cartCount } from "../Action/action";
import { checkIndex, filterJson } from '../API/UnAuthApi';
import { AddWishListApi, WishlistDetailApi } from "../API/WishlistApi";
import { useAddToCart, useRemoveFromCart } from '../API/CartChanges';
import QuickViewPopup from './quickViewPopup';
import { useAddToWishlist, useRemoveFromWishlist } from '../API/WishlistChanges';

const CategorywiseProductsCarousel = (props) => {
  const dispatch = useDispatch();
    
  const wishlistDataLocalStorage = JSON.parse(getWishlistData());
  const wishlistDataIdLocalStorage = JSON.parse(getWishlistIdData());
  const [wishlistId, setWishlistId] = useState([]);
  const [wishlist, setwishlist] = useState([]);
  const profile = useSelector((state) => state.ProfileReducer);
  const [currency, setCurrency] = useState()
  const CartListDetails = useSelector((state) => state.CartReducer);
 
  const wishlistCountDataReducer = useSelector((state) => state.WishlistCountReducer);
  const WishlistDetailReducer = useSelector((state) => state.WishlistReducer);

  useEffect(() => {
    setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
  });

    const rate = useSelector((state) => state.CurrencyRateReducer);
    const [changeCategory, setChangeCategory] = useState(0)
    const [pageCategoryProduct, setpageCategoryProduct] = useState(0)

    const addToCart = useAddToCart();
    const removeFromCart = useRemoveFromCart();

    const AddWishlist = useAddToWishlist();
    const RemoveWishlist = useRemoveFromWishlist();

    const [showModal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [popProduct, setPopProduct] = useState();
    const [popCartDetails, setPopCartDetails] = useState();

    function QuickView(product) {
        setShow(true)
        setPopCartDetails(CartListDetails);
        setPopProduct(product)
    }
    const isMobile = window.innerWidth <= 768;
    const isTab = window.innerWidth <= 1024;
    return (
        <div style={{marginLeft: 'auto', marginRight: 'auto',}}>
            {props.products.length > 0 ?
            
            <Carousel
            show={
                isMobile ? 1 : isTab ? 2 : 4
              }
            products={props.products} changeProductState={props.changeProductState}setPage={setpageCategoryProduct} currentPage={props.currentPage} categoryId={props.categoryId} setChangeCategory={setChangeCategory} currency={props.currency} productDetails={props.productDetails} index={props.index}>
                {props.products.map((product)=>(
                <div>
                    <div style={{ paddingLeft:8, paddingRight:8,  }}>
                    <article className="single_product">
                            <figure>
                                <div className="product_thumb product-img-width">
                                <Link to={"/products-details/" + product.id + "/" + product.name}>
                                    <a className='primary_img'><img
                                        src={ImageUrl() + props.productImagePath + "/" + product.image} alt="" /></a></Link>
                                    {/* <a className="primary_img"><img
                                        src={ImageUrl() + props.productImagePath + "/" + product.image} alt="" /></a> */}
                                    {/* <a className="secondary_img" href="#"><img
                                        src="assets/img/product/product2.jpg" alt="" /></a> */}
                                        

                                    <div className="action_links">
                                        <ul>
                                            {WishlistDetailReducer !== 0 && WishlistDetailReducer.findIndex(item => item.id === product.id) !== -1 ? 
                                            <li className="wishlist" onClick={() => RemoveWishlist(product)}><a title="Add to Wishlist" className= "wlishlist_links_active"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li> : 
                                            <li className="wishlist" onClick={() => AddWishlist(product)}><a title="Add to Wishlist" className= ""><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li> }
                                            
                                            <li className="quick_button" onClick={() => QuickView(product)}><a title="quick view"> <i class="fa fa-window-maximize" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>

                                </div>
                                <figcaption className="product_content">
                                <h3 className="product_name"><a href="">{product.name}</a></h3>
                                    <p className='product-dis'>{product.mini_description}</p>

                                    
                                    <div className="price_box">
                                    <span className="current_price">{ConvertCurrency(product.price, 1, props.currency,rate)}</span>
                                    </div>
                                </figcaption>

                            </figure>
                            <div className={CartListDetails.some(item => item.id === product.id) ? 'default_btn bg-red' : 'default_btn'}>
                            {CartListDetails.some(item => item.id === product.id) ?
                            <a title="remove from cart" tabIndex="0" onClick={() => removeFromCart(product)}>Remove From Cart</a>
                            :  
                            <a title="add to cart" tabIndex="0" onClick={() => addToCart(product)}>Add To Cart</a>}          
                            </div>
                            {/* <div className="add_to_cart">
                                <a href="#" title="add to cart">Add to cart</a>
                            </div> */}
                        </article>
                    </div>
                </div>
                ))}
                {/* <div>
                    <div style={{ padding: 8 }}>
                        <article className="single_product">
                            <figure>
                                <div className="product_thumb">
                                    <a className="primary_img" href="#"><img
                                        src="assets/img/product/product1.jpg" alt="" /></a>
                                    <a className="secondary_img" href="#"><img
                                        src="assets/img/product/product2.jpg" alt="" /></a>

                                    <div className="action_links">
                                        <ul>
                                            <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                                className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                            <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                                                title="quick view"> <i class="fa fa-window-maximize" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>

                                </div>
                                <figcaption className="product_content">
                                    <div className="price_box">
                                        <span className="current_price">UGX 79.00</span>
                                    </div>
                                    <p className='product-dis'>Natus erro at congue massa commodo
                                        sit</p>
                                    <h3 className="product_name"><a href="">Natus erro</a></h3>
                                </figcaption>

                            </figure>
                            <div className="add_to_cart">
                                <a href="#" title="add to cart">Add to cart</a>
                            </div>
                        </article>
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8 }}>
                    <article className="single_product">
                            <figure>
                                <div className="product_thumb">
                                    <a className="primary_img" href="#"><img
                                        src="assets/img/product/product1.jpg" alt="" /></a>
                                    <a className="secondary_img" href="#"><img
                                        src="assets/img/product/product2.jpg" alt="" /></a>

                                    <div className="action_links">
                                        <ul>
                                            <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                                className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                            <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                                                title="quick view"> <i class="fa fa-window-maximize" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>

                                </div>
                                <figcaption className="product_content">
                                    <div className="price_box">
                                        <span className="current_price">UGX 79.00</span>
                                    </div>
                                    <p className='product-dis'>Natus erro at congue massa commodo
                                        sit</p>
                                    <h3 className="product_name"><a href="">Natus erro</a></h3>
                                </figcaption>

                            </figure>
                            <div className="add_to_cart">
                                <a href="#" title="add to cart">Add to cart</a>
                            </div>
                        </article>
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8 }}>
                    <article className="single_product">
                            <figure>
                                <div className="product_thumb">
                                    <a className="primary_img" href="#"><img
                                        src="assets/img/product/product1.jpg" alt="" /></a>
                                    <a className="secondary_img" href="#"><img
                                        src="assets/img/product/product2.jpg" alt="" /></a>

                                    <div className="action_links">
                                        <ul>
                                            <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                                className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                            <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                                                title="quick view"> <i class="fa fa-window-maximize" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>

                                </div>
                                <figcaption className="product_content">
                                    <div className="price_box">
                                        <span className="current_price">UGX 79.00</span>
                                    </div>
                                    <p className='product-dis'>Natus erro at congue massa commodo
                                        sit</p>
                                    <h3 className="product_name"><a href="">Natus erro</a></h3>
                                </figcaption>

                            </figure>
                            <div className="add_to_cart">
                                <a href="#" title="add to cart">Add to cart</a>
                            </div>
                        </article>
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8 }}>
                    <article className="single_product">
                            <figure>
                                <div className="product_thumb">
                                    <a className="primary_img" href="#"><img
                                        src="assets/img/product/product1.jpg" alt="" /></a>
                                    <a className="secondary_img" href="#"><img
                                        src="assets/img/product/product2.jpg" alt="" /></a>

                                    <div className="action_links">
                                        <ul>
                                            <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                                className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                            <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                                                title="quick view"> <i class="fa fa-window-maximize" aria-hidden="true"></i>
                                                </a></li>
                                        </ul>
                                    </div>

                                </div>
                                <figcaption className="product_content">
                                    <div className="price_box">
                                        <span className="current_price">UGX 79.00</span>
                                    </div>
                                    <p className='product-dis'>Natus erro at congue massa commodo
                                        sit</p>
                                    <h3 className="product_name"><a href="">Natus erro</a></h3>
                                </figcaption>

                            </figure>
                            <div className="add_to_cart">
                                <a href="#" title="add to cart">Add to cart</a>
                            </div>
                        </article>
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8 }}>
                    <article className="single_product">
                            <figure>
                                <div className="product_thumb">
                                    <a className="primary_img" href="#"><img
                                        src="assets/img/product/product1.jpg" alt="" /></a>
                                    <a className="secondary_img" href="#"><img
                                        src="assets/img/product/product2.jpg" alt="" /></a>

                                    <div className="action_links">
                                        <ul>
                                            <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                                className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                            <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                                                title="quick view"> <i class="fa fa-window-maximize" aria-hidden="true"></i>
                                                </a></li>
                                        </ul>
                                    </div>

                                </div>
                                <figcaption className="product_content">
                                    <div className="price_box">
                                        <span className="current_price">UGX 79.00</span>
                                    </div>
                                    <p className='product-dis'>Natus erro at congue massa commodo
                                        sit</p>
                                    <h3 className="product_name"><a href="">Natus erro</a></h3>
                                </figcaption>

                            </figure>
                            <div className="add_to_cart">
                                <a href="#" title="add to cart">Add to cart</a>
                            </div>
                        </article>
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8 }}>
                    <article className="single_product">
                            <figure>
                                <div className="product_thumb">
                                    <a className="primary_img" href="#"><img
                                        src="assets/img/product/product1.jpg" alt="" /></a>
                                    <a className="secondary_img" href="#"><img
                                        src="assets/img/product/product2.jpg" alt="" /></a>

                                    <div className="action_links">
                                        <ul>
                                            <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                                className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                            <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                                                title="quick view"> <i class="fa fa-window-maximize" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>

                                </div>
                                <figcaption className="product_content">
                                    <div className="price_box">
                                        <span className="current_price">UGX 79.00</span>
                                    </div>
                                    <p className='product-dis'>Natus erro at congue massa commodo
                                        sit</p>
                                    <h3 className="product_name"><a href="">Natus erro</a></h3>
                                </figcaption>

                            </figure>
                            <div className="add_to_cart">
                                <a href="#" title="add to cart">Add to cart</a>
                            </div>
                        </article>
                    </div>
                </div>
                <div>
                    <div style={{ padding: 8 }}>
                    <article className="single_product">
                            <figure>
                                <div className="product_thumb">
                                    <a className="primary_img" href="#"><img
                                        src="assets/img/product/product1.jpg" alt="" /></a>
                                    <a className="secondary_img" href="#"><img
                                        src="assets/img/product/product2.jpg" alt="" /></a>

                                    <div className="action_links">
                                        <ul>
                                            <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                                className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                            <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                                                title="quick view"> <i class="fa fa-window-maximize" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>

                                </div>
                                <figcaption className="product_content">
                                    <div className="price_box">
                                        <span className="current_price">UGX 79.00</span>
                                    </div>
                                    <p className='product-dis'>Natus erro at congue massa commodo
                                        sit</p>
                                    <h3 className="product_name"><a href="">Natus erro</a></h3>
                                </figcaption>

                            </figure>
                            <div className="add_to_cart">
                                <a href="#" title="add to cart">Add to cart</a>
                            </div>
                        </article>
                    </div>
                </div> */}
            </Carousel>
            : null }
            <QuickViewPopup setShow={setShow} showModal={showModal} popProduct={popProduct} productImagePath={props.productImagePath} currency={props.currency}/>

        </div>
    )

}
export default CategorywiseProductsCarousel;