import React, {useState, useEffect} from 'react'
import MyAccountMenu from './myaccountmenu';
import { useSelector, useDispatch } from "react-redux";
import { IsAuthenticated } from '../../API/CheckAuth';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { CoreImageUrl, ImageUrl } from '../../API/Url'; 
import Footer from '../../Layouts/footer';
const Profile = (props) => {
    const redirect = useNavigate();
    const profileData = useSelector((state) => state.ProfileReducer);
    const [gender, setGender] = useState();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    if(!IsAuthenticated())
    {
        return <Navigate to="/login" />
    }
    if(profileData && !gender)
    {
        if(profileData.gender == "M")
        {
            setGender("Male")
        }
        else if(profileData.gender == "F")
        {
            setGender("Female")
        }
        else
        {
            setGender("Others")
        }

    }
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li>Profile</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            {profileData != 0 ? 
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                <div className="tab-content dashboard_content bordered padding-full-side40 profile-min-height">
                                    <div className="tab-pane fade show active" id="profile">
                                    <div className="section_title border-none"><h2 className='border-bottom'>Profile</h2></div>
                                        <div className="table-responsive">
                                            <table className="table text-align-left border-none" style={{ width: "60%" }}>
                                                <tbody className='border-td-none'>
                                                    <tr>
                                                        <td>Profile Photo</td>
                                                        <td><img className='profile-user' src={ImageUrl()+"/core/"+profileData.image} alt='' /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Full Name</td>
                                                        <td>{profileData.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gender</td>
                                                        <td>{gender }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email Id</td>
                                                        <td>{profileData.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mobile Number</td>
                                                        <td>+{profileData.mcc ? profileData.mcc.code : null} {profileData.mobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Date of birth</td>
                                                        <td>{profileData.dob}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Region</td>
                                                        <td>{profileData.country.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <Link className='border-none' to={"/edit-profile"}><td><button className='btn btn-warning'>Edit</button></td></Link>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default Profile;