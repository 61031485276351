import React from "react";
import BaseLayout from './Layouts/baseLayout';
import $ from 'jquery';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
function App() {
  return (
    <React.Fragment>
    <BaseLayout/>
  </React.Fragment>    

  );
}

export default App;


