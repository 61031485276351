import React,{useEffect,useState} from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import $ from 'jquery';
import Header from './header';
import Footer from './footer';
import Login from '../Views/login';
import Signup from '../Views/sign-up';
import ContactUs from '../Views/contact-us';
import Home from '../Views/home';
import ElectricVehicles from '../Views/electric-vehicles';
import ShoppingCart from '../Views/shopping-cart';
import {CategoryList} from '../API/HomeApi';
import { MobileCode, AllCountryList, GetLocationType } from '../API/UnAuthApi';
import ProductsDetails from '../Views/products-details';
import Profile from '../Views/my-accounts/profile';
import MyAddress from '../Views/my-accounts/address';
import Wallet from '../Views/my-accounts/wallet';
import EditProfile from '../Views/my-accounts/edit-profile';
import AddAddress from '../Views/my-accounts/addaddress';
import MyWishList from '../Views/my-accounts/wishlist';
import AvailableCoupons from '../Views/my-accounts/availablecoupons';
import GiftCards from '../Views/my-accounts/giftcards';
import MyOrders from '../Views/my-accounts/orders';
import ReturnOrders from '../Views/my-accounts/returnorders';
import Products from '../Views/products';
import ViewProducts from '../Views/products-view';
import { Carousel } from 'bootstrap';
import { getCartData, getCurrencyCountry, getWishlistData, setWishlistData } from "../API/Storage";
import { IsAuthenticated } from '../API/CheckAuth'
import { cartDetails, wishlistDetails, wishlistCount, cartCount } from "../Action/action";
import { CartListApi } from '../API/CartApi';
import { productDetails } from '../API/ProductApi';

import { WishlistDetailApi } from "../API/WishlistApi";
import AllProducts from '../Views/products';
import Cookies from 'universal-cookie';
 import PaymentMethod from '../Views/payment-method';
import CheckOut from '../Views/checkout';
import CheckOutMenu from '../Views/checkoutmenu';
import { useSelector, useDispatch } from "react-redux";
import ProductPopup from '../Views/test-layout';
import ForgotPassword from '../Views/forgotpassword';
import FundTransfer from '../Views/my-accounts/fundtransfer';
import WithdrawFunds from '../Views/my-accounts/withdrawfunds';
import { GoogleApiKey, ImageUrl } from '../API/Url';
import { useGeolocated } from "react-geolocated";
import Geocode from "react-geocode";
import ConfirmPassword from '../Views/confirmpassword';


const BaseLayout = (props) => {

    const [address, setAddress] = useState();
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
    if (coords && !address) {
        Geocode.setApiKey(GoogleApiKey());
        Geocode.setLanguage("en");
        Geocode.setRegion("es");
        Geocode.enableDebug();
        Geocode.fromLatLng(coords.latitude, coords.longitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
                let city, state, country, pincode, street_number, route, area;
                for (let i = 0; i < response.results[0].address_components.length; i++) {
                    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                        switch (response.results[0].address_components[i].types[j]) {
                            case "locality":
                                city = response.results[0].address_components[i].long_name;
                                break;
                            case "administrative_area_level_1":
                                state = response.results[0].address_components[i].long_name;
                                break;
                            case "country":
                                country = response.results[0].address_components[i].long_name;
                                break;
                            case "postal_code":
                                pincode = response.results[0].address_components[i].long_name;
                                break;
                            case "street_number":
                                street_number = response.results[0].address_components[i].long_name;
                                break;
                            case "route":
                                route = response.results[0].address_components[i].long_name;
                                break;
                            case "neighborhood":
                                area = response.results[0].address_components[i].long_name;
                                break;
                        }
                    }
                }
                setAddress({city:city, state:state, country:country, address:street_number && route && area ? street_number+", "+route+", "+area : null, pincode:pincode});
            },
            (error) => {
                console.error(error);
            }
        );
    }



    const cookies = new Cookies();
    useEffect(()=>{
        let string = "abcd-efgh-ijkl-mnop-qrst-uvwx-yz12-3456-7890";
         let randomString = "";
        if(!cookies.get("uniqueId"))
        {
            for(let i =1 ; i<= 50; i++)
            {
                let uniqueId = Math.round(Math.random() * 40); 
                randomString += string[uniqueId];
            }
            cookies.set('uniqueId', randomString, { path: '/' });
        }
        
    },[])
        
    const dispatch = useDispatch();

    const [categoryLists, setCategoryLists] = useState([]);
    const [mobileCode, setMobileCode] = useState({})
    const [allCountryLists, setAllCountryLists] = useState([])

    const cartDataLocalStorage = JSON.parse(getCartData());
    const wishlistDataLocalStorage = JSON.parse(getWishlistData());
    const cartListData = "";
    const [showFooter, setShowFooter] = useState(false)

    const profile = useSelector((state) => state.ProfileReducer);

    const [currency, setCurrency] = useState();
    const [locationTypes, setLocationTypes] = useState([])
    useEffect(()=>{
      setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
    });

    useEffect(() => {
    CategoryList().then((response) => {
        setCategoryLists(response.data.category_details.data);
    }).catch((err) => {
        
    });
        
    }, [])
    
    useEffect(() => {
        MobileCode().then((response) => {
            setMobileCode(response.data.data);
        }).catch((err) => {
            console.log("mobile code errors");
        });
    }, [])

    useEffect(() => {
        AllCountryList().then((response) => {
            setAllCountryLists(response.data.data);
        }).catch((err) => {
            console.log("Country List errors");
        });
    }, [])
    useEffect(()=>{
        if(!IsAuthenticated())
        {
            let cartItem = []
            if(cartDataLocalStorage)
            {
                if(currency)
                {
                    // let products = cartDataLocalStorage != 0 ? cartDataLocalStorage.join() : 0;

                    const data = cartDataLocalStorage.map(item => item.id);
                    productDetails(data,currency).then((result) => {
                        if(!IsAuthenticated())
                        {
                            let id = [];
                            result.data.data.map((item,key)=>{
                                id.push({id:item.id,price:item.price,cartQuantity:cartDataLocalStorage[key].cartQuantity});
                            })
                            
                            dispatch(cartDetails(id));
                        }

                    }).catch((err) => {
            
                    });
                }
            }
        }
        if(IsAuthenticated() && currency)
        {
            CartListApi("",currency).then((response) => {
                if(response.data.status == "success")
                {
                    dispatch(cartDetails(response.data.data))
                }
            }).catch((err) => {
                dispatch(cartDetails([]))
            });
        }  
    },[currency])
    useEffect(()=>{
        if(IsAuthenticated() && currency)
        {    
            WishlistDetailApi("",currency).then((response) => {
                let wishlistProductIds = []
                dispatch(wishlistDetails(response.data.data));  
                // response.data.product_ids.map((item)=>{
                //     wishlistProductIds.push(item.product_id);
                // })
                // dispatch(wishlistCount(wishlistProductIds))
            }).catch((err) => {
                dispatch(wishlistDetails([]));
            });
        }
        if(!IsAuthenticated())
        {
            let wishlistItem = []
            let wishlistProductIds = [];
            if(wishlistDataLocalStorage)
            {
                dispatch(wishlistDetails(wishlistDataLocalStorage.length > 0 ? wishlistDataLocalStorage : []));
                // wishlistDataLocalStorage.map((item)=>{
                //     if(item.currency === currency)
                //     {
                //         wishlistItem.push(item)
                //         wishlistProductIds.push(item.id);
                //     }
                // })
                // if(wishlistItem.length > 0)
                // {
                //     dispatch(wishlistDetails(wishlistItem));
                //     dispatch(wishlistCount(wishlistProductIds));
                // }
                // else
                // {
                //     dispatch(wishlistDetails(0));
                // }
            }
            else
            {
                dispatch(wishlistDetails(0));
            }
        }
    },[currency])
    // show fotter after few seconds

    // Start location type
    useEffect(()=>{
        GetLocationType().then((response)=>{
            setLocationTypes(response.data.data)
        }).catch((err)=>{
            setLocationTypes([]);
        })
    },[])
    // End location type
    useEffect(()=>{
        setInterval(() => {
            setShowFooter(true)
        }, 2000);
    })   


    return (
        <React.Fragment>
            <BrowserRouter>
                <Header categoryListProps={categoryLists} address={address}/>
                <Routes>
                    <Route path="/" element={<Home categoryListProps={categoryLists}/>} />
                    <Route path="/home/:name" element={<Home categoryListProps={categoryLists}/>} />
                    <Route path="/home" element={<Home categoryListProps={categoryLists}/> }  />
                    <Route path="/login" element={<Login mobileCode={mobileCode}/>} />
                    <Route path="/sign-up" element={<Signup mobileCode={mobileCode}  countryLists={allCountryLists}/>} />
                    <Route path='/contact-us' element={<ContactUs />} />
                    <Route path="/electric-vehicles" element={<ElectricVehicles />} />
                    <Route path="/shopping-cart" element={<ShoppingCart categoryListProps={categoryLists} mobileCode={mobileCode}/>} />
                    <Route path="/products-details/:category/:product" element={<ProductsDetails categoryListProps= {categoryLists}/>} />
                    <Route path='/profile' element={<Profile countryLists={allCountryLists}/>}/>
                    <Route path='/address' element={<MyAddress/>} />
                    <Route path='/wallet' element={<Wallet/>} />
                    <Route path='/edit-profile' element={<EditProfile mobileCode={mobileCode} countryLists={allCountryLists}/>} />
                    <Route path='/addaddress' element={<AddAddress mobileCode={mobileCode} countryLists={allCountryLists} locationTypes={locationTypes} address={address}/>} />
                    <Route path='/wishlist' element={<MyWishList/>} />
                    <Route path='/availablecoupons' element={<AvailableCoupons/>} />
                    <Route path='/giftcards' element={<GiftCards/>} />
                    <Route path='/orders' element={<MyOrders/>} />
                    <Route path='/returnorders' element={<ReturnOrders/>} />
                    <Route path='/imgslider' element={<Carousel/>}/>
                    <Route path='/products/:category/:search' element={<AllProducts categoryListProps={categoryLists}/>} />
                    <Route path='/payment-method' element={<PaymentMethod/>}/>
                    <Route path='/checkout' element={<CheckOut countryLists={allCountryLists} mobileCode={mobileCode} locationTypes={locationTypes} address={address}/>}/>
                    {/* <Route path='/search' element={<Products/>} /> */}
                    <Route path='/checkoutmenu' element={<CheckOutMenu/>}/> 
                    <Route path='/forgotpassword' element={<ForgotPassword mobileCode={mobileCode}/>}/>
                    <Route path='/test-layout' element={<ProductPopup/>}/>
                    <Route path='/view/all/:view/:category' element={<ViewProducts categoryListProps={categoryLists}/>}/>
                    <Route path='/fundtransfer' element={<FundTransfer/>}/>
                    <Route path='/withdrawfunds' element={<WithdrawFunds/>}/>
                    <Route path='/confirmpassword' element={<ConfirmPassword/>}/>
                </Routes>
                {/* { showFooter && <Footer /> } */}
            </BrowserRouter>
            </React.Fragment>
    );
}
export default BaseLayout;