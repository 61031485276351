import React, { useState, useEffect } from 'react'
import { BrowserRouter, Link } from 'react-router-dom';
import { ConvertCurrency } from '../../API/currency';
import { GetOrderDetails } from '../../API/OrderApi';
import { ImageUrl } from '../../API/Url';
import MyAccountMenu from './myaccountmenu';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from "react-redux";
import { getCurrencyCountry } from '../../API/Storage';
import Footer from '../../Layouts/footer';
import { Modal, Button } from 'react-bootstrap';
const MyOrders = (props) => {
    const [showContent, setShowContent] = useState();
    const [showModal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const [popProduct, setPopProduct] = useState();
    const rate = useSelector((state) => state.CurrencyRateReducer);

    const [orderDetails, setOrderDetails] = useState([])
    const [ordersPaginate, setOrdersPaginate] = useState(0);
    const [page, setPage] = useState(1);
    const [searchKeyword, setsearchKeyword] = useState();
    const profile = useSelector((state) => state.ProfileReducer);
    const [filter, setFilter] = useState('desc')
    const [productImagePath, setProductImagePath] = useState();
    const [currency, setCurrency] = useState()
    const [orderPopup, setOrderPopup] = useState({})
    useEffect(() => {
        setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
    });

    useEffect(() => {
        if (currency) {
            GetOrderDetails("", page, searchKeyword, currency, filter).then((result) => {
                setOrderDetails(result.data.data.data);
                setOrdersPaginate(result.data.data);
                setProductImagePath('market/products/images/')
                setShowContent(1)

            }).catch((err) => {
                setShowContent(1)
            });
        }
    }, [page, searchKeyword, currency, filter])
    function SearchKey(e) {
        setsearchKeyword(e.target.value)
        setPage(1);
    }
    function PaginationChange(e, value) {
        setPage(value);
    }
    function FilterFunction() {
        setFilter((current) => (current === "desc" ? 'asc' : "desc"))
    }
    function QuickView(orderDetails) {
        setShow(true)
        setOrderPopup(orderDetails);
    }
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li>My Orders</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            { showContent == 1 &&
                            <>
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                <div className="tab-content dashboard_content bordered padding-full-side40">
                                    <div className="section_title border-none"><h2 className='border-bottom'>My Orders</h2></div>
                                    {orderDetails.length > 0 ?
                                        <div className='row mb-20'>
                                            <div className='col-md-10 col-sm-12'>
                                                <div className="search_box my_order_search">
                                                    <input placeholder="Search" type="text" onChange={SearchKey} name="search" />
                                                    <button type="submit"><i className="fa fa-search" aria-hidden="true"></i> Search</button>
                                                </div>
                                            </div>
                                            <div className='col-md-2 col-sm-12 my-order-filler' onClick={FilterFunction}>
                                                <a><img src='/assets/img/icon/filter.png' alt='Filter' />Filter</a>
                                            </div>
                                        </div>
                                        : null}
                                    { orderDetails.length > 0 ?
                                        <div className="row">
                                            {orderDetails.map((orderDetail) => (
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="expanel expanel-default">
                                                        <div className='my_order_header bg-orange'><div><strong>Order ID</strong> : {orderDetail.order_id}</div>
                                                        
                                                            <div><strong>Date</strong>: {new Date(orderDetail.order_date).toISOString().toLocaleString().replace('T', ' ').replace(/\.\d+Z$/, '')}</div>
                                                        </div>
                                                        <div className="expanel-body">
                                                            <div className='row'><div className='col-md-2 img-w100'><img src={ImageUrl() + productImagePath + orderDetail.image} /></div>
                                                                <div className='col-md-7'><p>{orderDetail.name.toUpperCase()} <br />{orderDetail.description}</p>
                                                                    <span className='color-orange'> {ConvertCurrency(orderDetail.price, 1, currency, rate)} X {orderDetail.cartQuantity}</span>
                                                                </div>
                                                                <div className='col-md-3 text-right'>
                                                                    <p>Status: <span className='color-orange'>{orderDetail.order_status}</span></p>
                                                                    <p>Total Amount: <span className='color-orange'>{ConvertCurrency(orderDetail.price, orderDetail.cartQuantity, currency, rate)}</span></p>
                                                                    <div className='my-order-btn'><button onClick={() => QuickView(orderDetail)}>Order Details</button></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div className='row'>No orders found</div>
                                    }
                                </div>
                            </div>
                            </>
                            }
                            <div className="shop_toolbar t_bottom">
                                <div className="pagination">
                                    {ordersPaginate.total_pages && ordersPaginate.total_pages > 0 ?
                                        <Stack spacing={3}>
                                            <Pagination count={ordersPaginate.total_pages} onChange={PaginationChange} page={page} />
                                        </Stack>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Order Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className='table-responsive'>
                                        <table className='table card-table table-vcenter text-nowrap align-items-center border-none'>
                                            <tr>
                                                <th>Status</th>
                                                <td><input type="text" placeholder={orderPopup.order_status} value={orderPopup.order_status} readOnly /></td>
                                            </tr>
                                            <tr>
                                                <th>Courrier</th>
                                                <td><input type="text" placeholder='Courrier Name' readOnly value={orderPopup.courier}/></td>
                                            </tr>
                                            <tr>
                                                <th>Current Location</th>
                                                <td><input type="text" placeholder='location Name' readOnly value={orderPopup.current_location}/></td>
                                            </tr>
                                            <tr>
                                                <th>Est time of arrival</th>
                                                <td><input type="text" placeholder='13.00' readOnly value={orderPopup.arrival_time}/></td>
                                            </tr>
                                            <tr>
                                                <th>Next stop</th>
                                                <td><input type="text" placeholder='Stop Name' readOnly value={orderPopup.next_stop}/></td>
                                            </tr>
                                            <tr>
                                                <th>Added Expenses</th>
                                                <td><input type="text" placeholder='' value={orderPopup.expenses}/></td>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                {orderPopup.price ? 
                                                <td><input type="text" placeholder={ConvertCurrency(orderPopup.price, orderPopup.cartQuantity, currency, rate)} value={ConvertCurrency(orderPopup.price, orderPopup.cartQuantity, currency, rate)}/></td>
                                                :null}
                                            </tr>
                                            <tr>
                                                <th>Track Link</th>
                                                <td>Google Map link</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                        
                </Modal.Body>
            </Modal>
            {showContent == 1 && 
            <Footer />
            }

        </>
    );
}
export default MyOrders;