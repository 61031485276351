import React, { useState } from "react";
import { Navigate, useNavigate, Link, useLocation } from 'react-router-dom';
import Footer from "../Layouts/footer";
import { ResetPassword } from "../API/Auth";
const ConfirmPassword= () => {
    const redirect = useNavigate();
    const location = useLocation();
    const data = location.state;

    const [passwordError, setPasswordError] = useState({});


    if(!data || !data.token)
    {
        redirect("/login");
    }
    function handleSubmit(e){
        e.preventDefault();
        if(e.target.password.value)
        {
            if(e.target.confirm_password.value == e.target.password.value)
            {
                ResetPassword(data.token,e.target.password.value).then((res)=>{
                    const updatedLocation = { ...location, state: {} }; 
                    redirect(updatedLocation);
                    redirect("/login");
                }).catch((err)=>{
                         
                })
            }
            else
            {
                setPasswordError({confirm_passwordDisplayError:"passowrd and confirm password not match"});   
            }
        }
        else
        {
            setPasswordError({passwordDisplayError:"password field is required"});
        }
    }
    console.log(passwordError);
    return (
        <>
            <div className="forgotpassword-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            {/* <div className='sign-up-error-msg'>
                                <div class="alert alert-danger" role="alert">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>Danger alert—At vero eos et accusamus praesentium! Danger alert—At vero eos et accusamus praesentium!
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="account_form login-form">
                                <form action="" onSubmit={handleSubmit}>
                                    <div className='login-header'>
                                        <h3><span className='border-bottom'>Password</span></h3>
                                        <div className="form-group m-0">
                                            <div className="row gutters-xs">
                                                <div className='col-12 postion-rel mb-2'>
                                                    <input type="text" className="form-control" placeholder="Enter New Password" name="password" onChange={()=>{setPasswordError({})}}/>
                                                    <span style={{color:"red"}}>
                                                        {passwordError.passwordDisplayError}</span>

                                                </div>
                                                <div className='col-12 postion-rel'>
                                                    <input type="text" className="form-control" placeholder="Confirm Password" name="confirm_password" onChange={()=>{setPasswordError({})}}/>
                                                    <span style={{color:"red"}}>
                                                        {passwordError.confirm_passwordDisplayError}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="login_submit mt-20">
                                            <div className='login-submit-btn'>
                                                <button type="submit">Submit</button>
                                            </div>
                                        </div>
                                        <div className="text-center mt-20"><Link> <i class="fa fa-long-arrow-left mr-20" aria-hidden="true"></i>
 Back to Login</Link></div>
                                    </div>
                                    <div className='login-footer'>
                                        <div><button className='google-btn' href='#'><i class="fa fa-google" aria-hidden="true"></i> &nbsp; Sign in with google</button></div>
                                        <div className='dont-account'>
                                            <Link to={'/sign-up'}>
                                                Dont' have an account?
                                                <span className='signup-hint'>Sign up for Free</span>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <Footer />

        </>
    );
}
export default ConfirmPassword