import React, { useEffect } from 'react'
const ProductPopup = () => {

    return (
        <>
        <section className="product_area mb-46">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title">
                <h2>Computer & Laptop</h2>
              </div>
            </div>
          </div>
          <div className="product_carousel product_column5 owl-carousel">
            <article className="single_product">
              <figure>
                <div className="product_thumb">
                  <a className="primary_img" href="product-details.html"><img
                    src="assets/img/product/product1.jpg" alt="" /></a>
                  <a className="secondary_img" href="product-details.html"><img
                    src="assets/img/product/product2.jpg" alt="" /></a>
                  
                  <div className="action_links">
                    <ul>
                      <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                      
                      <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                        title="quick view"> <span className="ion-ios-search-strong"></span></a></li>
                    </ul>
                  </div>
                  <div className="add_to_cart">
                    <a href="cart.html" title="add to cart">Add to cart</a>
                  </div>
                </div>
                <figcaption className="product_content">
                  <div className="price_box">
                    <span className="old_price">$86.00</span>
                    <span className="current_price">$79.00</span>
                  </div>
                  <h3 className="product_name"><a href="product-details.html">Natus erro at congue massa commodo
                    sit</a></h3>
                </figcaption>
              </figure>
            </article>
            <article className="single_product">
              <figure>
                <div className="product_thumb">
                  <a className="primary_img" href="product-details.html"><img
                    src="assets/img/product/product3.jpg" alt="" /></a>
                  <a className="secondary_img" href="product-details.html"><img
                    src="assets/img/product/product4.jpg" alt="" /></a>
                 
                  <div className="action_links">
                    <ul>
                      <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                      
                      <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                        title="quick view"> <span className="ion-ios-search-strong"></span></a></li>
                    </ul>
                  </div>
                  <div className="add_to_cart">
                    <a href="cart.html" title="add to cart">Add to cart</a>
                  </div>
                </div>
                <figcaption className="product_content">
                  <div className="price_box">
                    <span className="old_price">$86.00</span>
                    <span className="current_price">$79.00</span>
                  </div>
                  <h3 className="product_name"><a href="product-details.html">Itaque earum velit elementum</a>
                  </h3>
                </figcaption>
              </figure>
            </article>
            <article className="single_product">
              <figure>
                <div className="product_thumb">
                  <a className="primary_img" href="product-details.html"><img
                    src="assets/img/product/product5.jpg" alt="" /></a>
                  <a className="secondary_img" href="product-details.html"><img
                    src="assets/img/product/product6.jpg" alt="" /></a>
                  
                  <div className="action_links">
                    <ul>
                      <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                      
                      <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                        title="quick view"> <span className="ion-ios-search-strong"></span></a></li>
                    </ul>
                  </div>
                  <div className="add_to_cart">
                    <a href="cart.html" title="add to cart">Add to cart</a>
                  </div>
                </div>
                <figcaption className="product_content">
                  <div className="price_box">
                    <span className="old_price">$86.00</span>
                    <span className="current_price">$79.00</span>
                  </div>
                  <h3 className="product_name"><a href="product-details.html">Mauris tincidunt eros posuere
                    placerat</a></h3>
                </figcaption>
              </figure>
            </article>
            <article className="single_product">
              <figure>
                <div className="product_thumb">
                  <a className="primary_img" href="product-details.html"><img
                    src="assets/img/product/product7.jpg" alt="" /></a>
                  <a className="secondary_img" href="product-details.html"><img
                    src="assets/img/product/product8.jpg" alt="" /></a>
                 
                  <div className="action_links">
                    <ul>
                      <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                      
                      <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                        title="quick view"> <span className="ion-ios-search-strong"></span></a></li>
                    </ul>
                  </div>
                  <div className="add_to_cart">
                    <a href="cart.html" title="add to cart">Add to cart</a>
                  </div>
                </div>
                <figcaption className="product_content">
                  <div className="price_box">
                    <span className="old_price">$86.00</span>
                    <span className="current_price">$79.00</span>
                  </div>
                  <h3 className="product_name"><a href="product-details.html">Morbi ornare vestibulum massa</a>
                  </h3>
                </figcaption>
              </figure>
            </article>
            <article className="single_product">
              <figure>
                <div className="product_thumb">
                  <a className="primary_img" href="product-details.html"><img
                    src="assets/img/product/product9.jpg" alt="" /></a>
                  <a className="secondary_img" href="product-details.html"><img
                    src="assets/img/product/product10.jpg" alt="" /></a>
                  
                  <div className="action_links">
                    <ul>
                      <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                      
                      <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                        title="quick view"> <span className="ion-ios-search-strong"></span></a></li>
                    </ul>
                  </div>
                  <div className="add_to_cart">
                    <a href="cart.html" title="add to cart">Add to cart</a>
                  </div>
                </div>
                <figcaption className="product_content">
                  <div className="price_box">
                    <span className="old_price">$86.00</span>
                    <span className="current_price">$79.00</span>
                  </div>
                  <h3 className="product_name"><a href="product-details.html">Porro quisquam eget feugiat
                    pretium</a></h3>
                </figcaption>
              </figure>
            </article>
            <article className="single_product">
              <figure>
                <div className="product_thumb">
                  <a className="primary_img" href="product-details.html"><img
                    src="assets/img/product/product11.jpg" alt="" /></a>
                  <a className="secondary_img" href="product-details.html"><img
                    src="assets/img/product/product12.jpg" alt="" /></a>
                  
                  <div className="action_links">
                    <ul>
                      <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                      
                      <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                        title="quick view"> <span className="ion-ios-search-strong"></span></a></li>
                    </ul>
                  </div>
                  <div className="add_to_cart">
                    <a href="cart.html" title="add to cart">Add to cart</a>
                  </div>
                </div>
                <figcaption className="product_content">
                  <div className="price_box">
                    <span className="old_price">$86.00</span>
                    <span className="current_price">$79.00</span>
                  </div>
                  <h3 className="product_name"><a href="product-details.html">Laudantium enim fringilla dignissim
                    ipsum primis</a></h3>
                </figcaption>
              </figure>
            </article>
            <article className="single_product">
              <figure>
                <div className="product_thumb">
                  <a className="primary_img" href="product-details.html"><img
                    src="assets/img/product/product4.jpg" alt="" /></a>
                  <a className="secondary_img" href="product-details.html"><img
                    src="assets/img/product/product5.jpg" alt="" /></a>
                  
                  <div className="action_links">
                    <ul>
                      <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                      
                      <li className="quick_button"><a href="#" data-bs-toggle="modal" data-bs-target="#modal_box"
                        title="quick view"> <span className="ion-ios-search-strong"></span></a></li>
                    </ul>
                  </div>
                  <div className="add_to_cart">
                    <a href="cart.html" title="add to cart">Add to cart</a>
                  </div>
                </div>
                <figcaption className="product_content">
                  <div className="price_box">
                    <span className="old_price">$86.00</span>
                    <span className="current_price">$79.00</span>
                  </div>
                  <h3 className="product_name"><a href="product-details.html">Natus erro at congue massa commodo
                    sit</a></h3>
                </figcaption>
              </figure>
            </article>
          </div>
        </div>
      </section>
   
      <div className="modal fade" id="modal_box" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal_body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    <div className="modal_tab">
                      <div className="tab-content product-details-large">
                        <div className="tab-pane fade show active" id="tab1" role="tabpanel">
                          <div className="modal_tab_img">
                            <a href="#"><img src="assets/img/product/product1.jpg" alt="" /></a>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tab2" role="tabpanel">
                          <div className="modal_tab_img">
                            <a href="#"><img src="assets/img/product/product2.jpg" alt="" /></a>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tab3" role="tabpanel">
                          <div className="modal_tab_img">
                            <a href="#"><img src="assets/img/product/product3.jpg" alt="" /></a>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tab4" role="tabpanel">
                          <div className="modal_tab_img">
                            <a href="#"><img src="assets/img/product/product5.jpg" alt="" /></a>
                          </div>
                        </div>
                      </div>
                      <div className="modal_tab_button">
                        <ul className="nav product_navactive owl-carousel" role="tablist">
                          <li>
                            <a className="nav-link active" data-bs-toggle="tab" href="#tab1" role="tab"
                              aria-controls="tab1" aria-selected="false"><img
                                src="assets/img/product/product1.jpg" alt="" /></a>
                          </li>
                          <li>
                            <a className="nav-link" data-bs-toggle="tab" href="#tab2" role="tab"
                              aria-controls="tab2" aria-selected="false"><img
                                src="assets/img/product/product2.jpg" alt="" /></a>
                          </li>
                          <li>
                            <a className="nav-link button_three" data-bs-toggle="tab" href="#tab3"
                              role="tab" aria-controls="tab3" aria-selected="false"><img
                                src="assets/img/product/product3.jpg" alt="" /></a>
                          </li>
                          <li>
                            <a className="nav-link" data-bs-toggle="tab" href="#tab4" role="tab"
                              aria-controls="tab4" aria-selected="false"><img
                                src="assets/img/product/product5.jpg" alt="" /></a>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-12">
                    <div className="modal_right">
                      <div className="modal_title mb-10">
                        <h2>Handbag feugiat</h2>
                      </div>
                      <div className="modal_price mb-10">
                        <span className="new_price">$64.99</span>
                        <span className="old_price">$78.99</span>
                      </div>
                      <div className="modal_description mb-15">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia iste
                          laborum ad impedit pariatur esse optio tempora sint ullam autem deleniti nam
                          in quos qui nemo ipsum numquam, reiciendis maiores quidem aperiam, rerum vel
                          recusandae </p>
                      </div>
                      <div className="variants_selects">
                       
                        
                        <div className="modal_add_to_cart">
                          <form action="#">
                            
                            <button type="submit">add to cart</button>
                          </form>
                        </div>
                      </div>
                      <div className="modal_social">
                        <h2>Share this product</h2>
                        <ul>
                          <li className="facebook"><a href="#"><i className="fa fa-facebook"></i></a></li>
                          <li className="twitter"><a href="#"><i className="fa fa-twitter"></i></a></li>
                          <li className="pinterest"><a href="#"><i className="fa fa-pinterest"></i></a></li>
                          <li className="google-plus"><a href="#"><i className="fa fa-google-plus"></i></a>
                          </li>
                          <li className="linkedin"><a href="#"><i className="fa fa-linkedin"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
}
export default ProductPopup;