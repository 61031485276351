function domain(){
    return process.env.REACT_APP_API_URL;
}
function publicDomain()
{
    return process.env.REACT_APP_PUBLIC_URL;
}
export function CommonBaseUrl(){
    // return "https://ev.heptotechnologies.org/api/v1.0/common/core";
    // return "https://api.evzone.app/api/v1.0/app/core/";
    return domain()+"/api/v1.0/app/core/"
}

export function EVMarketUrl(){
    // return "https://ev.heptotechnologies.org/api/v1.0/web/market";
    // return "https://api.evzone.app/api/v1.0/web/market/buyer/";
    return domain()+"/api/v1.0/web/market/buyer/";
}
export function ImageUrl(){
    // return "https://ev.public.heptotechnologies.org/";
    // return "https://public.evzone.app/"
    return publicDomain()+"/";
}
export function LocalUrl(){
    return "http://127.0.0.1:8000";
}
export function CoreImageUrl(){
    // return "https://ev.public.heptotechnologies.org/core/";
    return publicDomain()+"/core/"
}
export function GoogleApiKey(){
    return "AIzaSyBCCR-G5jmWLLIbhKRzlU_JgoVXLheTNtQ";
}