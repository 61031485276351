import React, { useState, useEffect } from 'react'
import { BrowserRouter, Link } from 'react-router-dom';
import Footer from '../../Layouts/footer';
import MyAccountMenu from './myaccountmenu';


const AvailableCoupons = (props) => {
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li>Available Coupons</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                <div className="tab-content dashboard_content bordered padding-full-side40">
                                <div className="section_title border-none"><h2 className='border-bottom'>Avilabale Coupons</h2></div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="expanel expanel-default">
                                                <div className="expanel-body">
                                                    <h4>Extra 15% Offer</h4>
                                                    <p><a href="">Get extra 15% off upto ₹1000 on 1 item(s) (price inclusive of cashback/coupon)</a></p>
                                                </div>
                                                <div className="expanel-footer bg-orange"><a href='#'>Valid till 25 Dec 2022</a><a className="viewtc" href='#'>View T&amp;C</a></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="expanel expanel-default">
                                                <div className="expanel-body">
                                                    <h4>Extra 15% Offer</h4>
                                                    <p><a href="">Get extra 15% off upto ₹1000 on 1 item(s) (price inclusive of cashback/coupon)</a></p>
                                                </div>
                                                <div className="expanel-footer bg-orange"><a href='#'>Valid till 25 Dec 2022</a><a className="viewtc" href='#'>View T&amp;C</a></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="expanel expanel-default">
                                                <div className="expanel-body">
                                                    <h4>Extra 15% Offer</h4>
                                                    <p><a href="">Get extra 15% off upto ₹1000 on 1 item(s) (price inclusive of cashback/coupon)</a></p>
                                                </div>
                                                <div className="expanel-footer bg-orange"><a href='#'>Valid till 25 Dec 2022</a><a className="viewtc" href='#'>View T&amp;C</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="shop_toolbar t_bottom">
                                <div className="pagination">
                                    <ul>
                                        <li className="current">1</li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li className="next"><a href="#">next</a></li>
                                        <li><a href="#">&gt; &gt;</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer />
        </>
    );
}
export default AvailableCoupons;