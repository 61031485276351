import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { GetPackagingDetailsApi } from '../API/OrderApi';
import CartSummery from './cartSummery';
import CheckOutMenu from './checkoutmenu';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { setPathName } from '../API/Storage';
import { AddressGetApi, AddressDeleteApi, AddressSetPrimaryApi } from '../API/Address';
import { Navigate, useNavigate, useSearchParams, useLocation, Link, useParams } from 'react-router-dom';
import Footer from '../Layouts/footer';
import { Modal, Button } from 'react-bootstrap';
import AddressPage from './my-accounts/addressPage';

const CheckOut = (props) => {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    const finalCountryRef = useRef();
    // const shipmentRouteRef = useRef();
    // const packagingDetailsRef = useRef();
    // const DestinationPortRef = useRef();
    const profileData = useSelector((state) => state.ProfileReducer);
    const [packagingDetails, setPackagingDetails] = useState([]);
    const [packageDetailsData, setPackageDetailsData] = useState({});
    const [isPackagingError, setIsPackagingError] = useState(false)
    useEffect(() => {
        setPathName(window.location.pathname)
    }, [])
    useEffect(() => {
        GetPackagingDetailsApi().then((result) => {
            setPackagingDetails(result.data.data);
        }).catch((err) => {

        });
    }, [])
    function packagingData(e) {
        setPackageDetailsData({ ...packageDetailsData, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        setTimeout(() => {
            setIsPackagingError((current) => current ? false : false)
        }, 5000)
    }, [isPackagingError])
    const [allAddress, setAllAddress] = useState([]);
    const [isDelete, setIsDelete] = useState(false)
    const [isPrimary, setIsPrimary] = useState(false)
    useEffect(() => {
        AddressGetApi().then((result) => {
            setAllAddress(result.data.data);
        }).catch((err) => {
            console.log(err)
        })
    }, [isDelete, isPrimary,showModal])
    function AddressDelete(address_id) {
        let data = { id: address_id }
        AddressDeleteApi(data).then((result) => {
            setIsDelete(!isDelete)
        }).catch((err) => {
            console.log(err);
        })
    }
    function AddressSetPrimary(address_id) {
        let data = { id: address_id }
        AddressSetPrimaryApi(data).then((result) => {
            setIsPrimary(!isPrimary)
        }).catch((err) => {
            console.log(err);
        })
    }
// Start Address

// End Address
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li><a href="#">Products</a></li>
                                    <li>Checkout</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="product_area mb-46">
                <div className="container bg-white pt-20 pb-20">

                    {isPackagingError &&
                        <div className="alert alert-danger" role="alert">Fill all the details</div>}
                    <div className='row'>
                        <div className='col-lg-8 col-md-8 col-sm-12'>
                            <div className="row">
                                <div className="col-12">
                                    <div className="section_title border-none">
                                        <h2 className='border-bottom'>Checkout</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="card tab-border">
                                <div className="card-body p-0">
                                    <div className="tab_wrapper second_tab">
                                        <Box sx={{ display: 'flex', backgroundColor: "white", textAlign: 'right' }}>
                                            <Tabs
                                                value={tabIndex}
                                                onChange={handleTabChange}
                                                orientation="vertical"
                                                sx={{ backgroundColor: "#ebebeb", color: "white", border: "none", }}>
                                                <Tab label="Final Country" style={tabIndex === 0 ? { backgroundColor: "#f77f00", color: "white", } : null} />
                                                <Tab label="Shipment Route" style={tabIndex === 1 ? { backgroundColor: "#f77f00", color: "white" } : null} />
                                                <Tab label="Packaging Method" style={tabIndex === 2 ? { backgroundColor: "#f77f00", color: "white" } : null} />
                                                <Tab label="Destination Port" style={tabIndex === 3 ? { backgroundColor: "#f77f00", color: "white" } : null} />
                                                <Tab label="Tax Clearance Office" style={tabIndex === 4 ? { backgroundColor: "#f77f00", color: "white" } : null} />
                                                <Tab label="Address" style={tabIndex === 5 ? { backgroundColor: "#f77f00" } : null} />
                                            </Tabs>
                                            <Box sx={{ margin: 2 }}>
                                                {tabIndex === 0 && (
                                                    <div className="tab_content active">
                                                        <div className="form-group">
                                                            <select className="form-control select2" data-placeholder="Choose Country" name='finalCountry' onChange={packagingData} ref={finalCountryRef}>
                                                                <option>Select country</option>

                                                                {props.countryLists.map((countryList) => (
                                                                    <option value={countryList.id} selected={profileData.country_id === countryList.id ? "selected" : ""} >{countryList.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                )}
                                                {tabIndex === 1 && (
                                                    <div className="tab_content">
                                                        <div className="form-group form-elements">
                                                            <div className="custom-controls-stacked">
                                                                {packagingDetails.shippingRoutes ?
                                                                    packagingDetails.shippingRoutes.map((shippingRoute) => (
                                                                        <label className="custom-control custom-radio">
                                                                            <input type="radio" className="custom-control-input" name="shipmentRoutes" value={shippingRoute.id} onChange={packagingData} checked={packageDetailsData.shipmentRoutes == shippingRoute.id ? "checked" : null} />
                                                                            <span className="custom-control-label">{shippingRoute.name.toUpperCase()}</span>
                                                                        </label>
                                                                    )) : null}


                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {tabIndex === 2 && (
                                                    <div className="tab_content">
                                                        <div className="form-group form-elements">
                                                            <div className="custom-controls-stacked">
                                                                {packagingDetails.packagingMethods ?
                                                                    packagingDetails.packagingMethods.map((packagingMethod) => (
                                                                        <label className="custom-control custom-radio" style={{ textAlign: "left" }}>
                                                                            <input type="radio" className="custom-control-input" name="packingMethods" value={packagingMethod.id} onChange={packagingData} checked={packageDetailsData.packingMethods == packagingMethod.id ? "checked" : null} />
                                                                            <span className="custom-control-label">{packagingMethod.name}</span>
                                                                        </label>
                                                                    )) : null}


                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {tabIndex === 3 && (
                                                    <div className="tab_content active">
                                                        <div className="form-group">
                                                            <select className="form-control select2" data-placeholder="Choose Country" name='destinationPorts' onChange={packagingData}>
                                                                <option>Select country </option>
                                                                {props.countryLists.map((countryList) => (
                                                                    <option value={countryList.id} selected={packageDetailsData.destinationPorts == countryList.id ? "selected" : ""} >{countryList.name}</option>
                                                                ))}
                                                                {/* {packagingDetails.destinationParts ? 
                                                            packagingDetails.destinationParts.map((destinationPort)=>(
                                                            <option selected={packageDetailsData.destinationPorts == destinationPort.id ? "selected" : null} value={destinationPort.id}>{destinationPort.name}</option>
                                                            )) : null} */}
                                                            </select>
                                                        </div>
                                                    </div>

                                                )}
                                                {tabIndex === 4 && (
                                                    <div className="tab_content">
                                                        <div className="form-group">
                                                            <select className="form-control select2" data-placeholder="Choose Country" name='taxClearenceOffice' onChange={packagingData}>
                                                                <option>Select country</option>

                                                                {props.countryLists.map((countryList) => (
                                                                    <option value={countryList.id} selected={packageDetailsData.taxClearenceOffice == countryList.id ? "selected" : ""} >{countryList.name}</option>
                                                                ))}
                                                                {/* {packagingDetails.taxClearenceOffices ? 
                                                        packagingDetails.taxClearenceOffices.map((taxClearenceOffice)=>(
                                                        <option selected={packageDetailsData.taxClearenceOffice == taxClearenceOffice.id} value={taxClearenceOffice.id}>{taxClearenceOffice.name}</option>
                                                        )) : null} */}
                                                            </select>
                                                        </div>
                                                    </div>
                                                )}
                                                {tabIndex === 5 && (
                                                    <div className="tab_content">
                                                        <div className='row'>
                                                            <div className="col-lg-12 col-md-12 bg-green add-address" onClick={() => { (setShowModal(true)) }}><h3><a><i className="fa fa-plus mr-5" aria-hidden="true"></i>
                                                                Add New Address</a></h3></div>
                                                            {/* <Link to='/addaddress'><div className="col-lg-12 col-md-12 bg-green add-address"><h3><a href=""><i className="fa fa-plus mr-5" aria-hidden="true"></i>
                                                        Add New Address</a></h3></div></Link> */}
                                                        </div>
                                                        <div className="row">
                                                            {allAddress.length > 0 ?
                                                                allAddress.map((address) => (
                                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                                        <div className="expanel expanel-default">
                                                                            <div className="expanel-heading">
                                                                                <h3 className="expanel-title color-green"><i className="fa fa-map-marker mr-5" aria-hidden="true"></i>
                                                                                    {address.location_type ? address.location_type.name : null}</h3>
                                                                                <div className="col-auto">
                                                                                    <label className="colorinput">

                                                                                        <input name="address_id" type="radio" onClick={() => AddressSetPrimary(address.id)} onChange={packagingData} className="colorinput-input" value={address.id} />
                                                                                        <span className="colorinput-color bg-green"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="expanel-body">
                                                                                <h4><strong>{address.first_name}  {address.last_name}</strong></h4>
                                                                                <p>{address.address_line_1}, {address.state.name}, {address.city.name}, {address.country.name}</p>
                                                                            </div>
                                                                            <div className="expanel-footer bg-orange"><a >Edit</a><a className="mydelete" onClick={() => AddressDelete(address.id)}>Delete</a></div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                : null}

                                                        </div>
                                                    </div>
                                                )}
                                            </Box>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CartSummery packageDetailsData={packageDetailsData} setIsPackagingError={setIsPackagingError} />
                    </div>
                </div>
            </section>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Order Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <AddressPage mobileCode={props.mobileCode} countryLists={props.countryLists} locationTypes={props.locationTypes} setShowModal={setShowModal} address={props.address}/>
                    </div>

                </Modal.Body>
            </Modal>
            <Footer />

        </>
    );
}
export default CheckOut;