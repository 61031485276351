import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { UserDetails, cartDetails, wishlistDetails, wishlistCount, currencyRate } from "../Action/action";

import { ProfileApi } from '../API/User';
import { getToken, setToken, removeToken, removeCartData, removeCartIdData, removeWishlistData, removeWishlistIdData, removePathName, setCurrencyCountry, getCurrencyCountry } from "../API/Storage";

import { IsAuthenticated } from '../API/CheckAuth'
import { GoogleApiKey, ImageUrl } from '../API/Url';

import { useGeolocated } from "react-geolocated";
import { RefreshTokenApi } from '../API/Auth';
import { AllCurrencyList, getGeoInfo, CurrencyRateApi, GetLocationType, CheckCurrency } from '../API/UnAuthApi';
import Geocode from "react-geocode";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import CountryList from '../Views/countrylist';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const MobMenu = (props) => {
    const [isOpen, setOpen] = useState(false);
    const profile = useSelector((state) => state.ProfileReducer);

    return (
        <div className='humble-menu'>
            <button
                onClick={() => setOpen(!isOpen)}
                className={`hamburger-button ${isOpen ? "open" : "close"}`}
            />
            <div className={`panel ${isOpen ? "open" : "close"}`}>

                {IsAuthenticated() == true ? 
                <div className='hum-icon-height w-100'>
                    <img className='mob-user-icon2' src={profile.image ? ImageUrl() + "/core/" + profile.image : "/assets/img/logo/profileAvatar.png"}></img>
                    <h4 className='hum-signup-txt'>{profile.name}</h4>
                </div>
                :
                <div className='hum-icon-height w-100'>
                    <a className='hum-login-icon' href=''>
                        <i className="hum-user-i fa fa-user-o" aria-hidden="true"></i>
                    </a>
                    <h4 className='hum-signup-txt' onClick={() => setOpen(!isOpen)}><Link to="/sign-up"> Signup</Link></h4>
                </div>}
                <div className='hum-menu-cat-links'>
                    <ul>
                        <li key="home" ><Link to={'/home'} onClick={() => setOpen(!isOpen)}>Home</Link></li>
                        {props.categoryLists.map((categoryList, key) => (
                            <li>
                                <Link to={'/home/' + categoryList.name.replace(/ /g, '-')} key={key} onClick={() => setOpen(!isOpen)}>{categoryList.name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        </div>
    );
};

const Header = (props) => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 8
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 6
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 4
        }
    };

    const currencyRef = useRef(null);
    const dispatch = useDispatch();
    const redirect = useNavigate();
    let token = getToken();
    const [refreshToken, setRefreshToken] = useState()
    const [searchKeyWord, setSearchKeyWord] = useState();
    const [currencyList, setCurrencyList] = useState([])
    const [currency, setCurrency] = useState();
    const [countryCode, setCountryCode] = useState();
    const profile = useSelector((state) => state.ProfileReducer);
    const cart = useSelector((state) => state.CartReducer);
    const wishlist = useSelector((state) => state.WishlistReducer);

    const wishlistCountDataReducer = useSelector((state) => state.WishlistCountReducer);
    const cartCountDataReducer = useSelector((state) => state.CartCountReducer);
    const WishlistDetailReducer = useSelector((state) => state.WishlistReducer);
    const [errorMessage, setErrorMessage] = useState('')
    let categoryLists = props.categoryListProps;
    useEffect(() => {
        AllCurrencyList().then((result) => {
            setCurrencyList(result.data.data);
        }).catch((err) => {

        });
    }, [])
    function CurrencyClick() {
        let currencyArray = currencyRef.current.value.split(",");
        let data;
        let errorCountry = currencyArray[0];
        CheckCurrency({ currency: currencyArray[0] }).then((result) => {
            data = currencyRef.current.value;
            setCurrency(data)
            dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
            setCurrencyCountry(currencyArray[0], currencyArray[1])
        }).catch((err) => {
            data = "USD,US";
            currencyArray = data.split(",")
            setCurrency(data)
            dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
            setCurrencyCountry(currencyArray[0], currencyArray[1])
            setErrorMessage("We dont have products on " + errorCountry + " currency")
        });
    }
    useEffect(() => {
        if (errorMessage != "") {
            setTimeout(() => {
                setErrorMessage("");
            }, 5000);
        }
    }, [errorMessage])
    useEffect(() => {
        CurrencyRateApi(getCurrencyCountry().currencyCode).then((result) => {
            dispatch(currencyRate(result.data.data ? result.data.data.rate : 1))
        }).catch((err) => {

        });
    }, [currency])
    useEffect(() => {
        if (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            let data = JSON.parse(jsonPayload);
            var exp = data.exp * 1000;
            let expdate = (new Date(exp));
            let expiry = expdate.toUTCString();
            let date = new Date().toUTCString();
            var diff = (new Date(expiry) - new Date(date));
            diff = diff - 10000;
            if (diff < 10000) {
                RefreshTokenApi().then((result) => {
                    let newToken = result.data.data.access_token;
                    setToken(newToken);
                    setRefreshToken(newToken);
                }).catch((err) => {
                    removeToken();
                    redirect('/login')
                });
            }

            setTimeout(() => {
                RefreshTokenApi().then((result) => {
                    let newToken = result.data.data.access_token;
                    setToken(newToken);
                    setRefreshToken(newToken);
                }).catch((err) => {
                    removeToken();
                    redirect('/login')
                });
            }, diff);
        }
    }, [refreshToken])


    useEffect(() => {
        if (IsAuthenticated()) {
            ProfileApi(token).then((result) => {
                dispatch(UserDetails(result.data.data));
                let c;
                if (result.data.data.currency) {
                    c = result.data.data.currency.code + "," + result.data.data.currency.country_code;
                }
                else {
                    c = "USD,US"
                }
                let currencyArray = c.split(",");
                let data;
                CheckCurrency({ currency: currencyArray[0] }).then((result) => {
                    data = currencyRef.current.value;
                    setCurrency(data)
                    // dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
                    setCurrencyCountry(currencyArray[0], currencyArray[1])
                }).catch((err) => {
                    data = "USD,US";
                    currencyArray = data.split(",")
                    setCurrency(data)
                    // dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
                    setCurrencyCountry(currencyArray[0], currencyArray[1])
                });
                // if (!getCurrencyCountry().currencyCode || !getCurrencyCountry().countryCode) {
                //     setCurrencyCountry(result.data.data.currency.code, result.data.data.currency.country_code)
                // }
            }).catch((err) => {
                dispatch(UserDetails([]));
                if (err.response.status == 401) {
                    if (getToken()) {
                        removeToken();
                        return redirect("/login");
                    }
                }
            });
        }
        else {
            if (!IsAuthenticated()) {
                getGeoInfo().then((result) => {
                    let c = result.data.currency + "," + result.data.country_code;
                    let currencyArray = c.split(",");
                    let data;
                    if (!getCurrencyCountry().currencyCode || !getCurrencyCountry().countryCode) {
                        CheckCurrency({ currency: currencyArray[0] }).then((result) => {
                            data = currencyRef.current.value;
                            setCurrency(data)
                            dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
                            setCurrencyCountry(currencyArray[0], currencyArray[1])
                        }).catch((err) => {
                            data = "USD,US";
                            currencyArray = data.split(",")
                            setCurrency(data)
                            dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
                            setCurrencyCountry(currencyArray[0], currencyArray[1])
                        });
                    }
                }).catch((err) => {
                    let data = "USD,US";
                    let currencyArray = data.split(",")
                    setCurrency(data)
                    dispatch(UserDetails({ ...profile, currency: { country_code: currencyArray[1], code: currencyArray[0] } }));
                    setCurrencyCountry(currencyArray[0], currencyArray[1])
                });
            }
        }
    }, [])
    // Start Search
    function Search(e) {
        e.preventDefault();
        let searchRmSpace;
        let categoryRmSpace;
        if (searchKeyWord && searchKeyWord.search) {
            searchRmSpace = searchKeyWord.search.replace(/ /g, "-")
        }
        if (searchKeyWord && searchKeyWord.category) {
            categoryRmSpace = searchKeyWord.category.replace(/ /g, '-')
        }
        else {
            categoryRmSpace = "all";
        }
        return redirect("/products/" + categoryRmSpace + "/" + searchRmSpace);
    }
    function searchDetails(e) {
        setSearchKeyWord({ ...searchKeyWord, [e.target.name]: e.target.value });
    }
    // End Search
    // useEffect(()=>{
    //     if(!IsAuthenticated() && wishlist.length > 5)
    //     {
    //         return redirect("/login");
    //     }
    // })
    function Logout() {
        removeToken();
        removeCartData();
        removeWishlistData();
        removeWishlistIdData();
        removePathName();
        dispatch(cartDetails([]))
        dispatch(wishlistDetails([]))
        dispatch(wishlistCount(0));
        localStorage.removeItem('path')
        return redirect("/home");
    }
    return (
        <header>
            {console.log('run header')}
            {categoryLists.length > 0 ?

                <div className="sticky_header_area sticky-header">
                    <div className="main_header">
                        <div className="header_top">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="support_info">
                                            {/* <p>The one-stop marketplace for Electric vehicle</p> */}
                                            <p>One stop center for EV's and related accessories</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="header_middle">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="mob-top col-lg-1 col-md-1 col-sm-12">
                                        <MobMenu categoryLists={props.categoryListProps}></MobMenu>
                                        <div className="logo">
                                            <Link to='/'><img src="/assets/img/logo/ev-zone-logo.png" alt="EV Market" /></Link>
                                        </div>
                                        <div className="mob-filter wishlist-space">
                                            {IsAuthenticated() == true ? 
                                            <div className="header_wishlist mob-wishlist">
                                                <span className='mob-login-txt'>{profile.name}</span>
                                                <img className='mob-user-icon' src={profile.image ? ImageUrl() + "/core/" + profile.image : "/assets/img/logo/profileAvatar.png"}></img>
                                            </div>
                                            :<div className="header_wishlist mob-wishlist">
                                                <Link to="/login"><span className='mob-login-txt'>Login</span><i className="fa fa-user-o" aria-hidden="true"></i></Link>
                                            </div>}

                                            <div className="mini_cart_wrapper mob-cart-warapper">
                                                <div className="dropdown">
                                                    <button className="dropbtncart">
                                                        <span className="cart_quantity mob-cart-quantity">{cart !== 0 ? cart.length : cart}</span>
                                                        <i className="fa fa-shopping-cart" aria-hidden="true"></i><i className="fa fa-angle-down"></i></button>
                                                    {cart.length > 0 &&
                                                        <div className="dropdown-content">
                                                            <Link to='/shopping-cart'>View Cart</Link>
                                                            <a href="#">Checkout</a>
                                                        </div>
                                                    }
                                                </div>

                                                {/* <a href="#"><i className="fa fa-shopping-cart"
                                                    aria-hidden="true"></i><i className="fa fa-angle-down"></i></a> */}
                                                {/* <span className="cart_quantity">{cart !== 0 ? cart.length : cart}</span> */}
                                                {/* <div className="mini_cart">
                                                        <div className="mini_cart_footer">
                                                            <div className="cart_button">
                                                                <Link to='/shopping-cart'>View Cart</Link>
                                                            </div>
                                                            <div className="cart_button">
                                                                <a>Checkout</a>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_box mob-search">
                                        <input placeholder="Search products..." type="text" name='search' onChange={searchDetails} />
                                        <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 logo-border'>
                                        <div className="search_container">
                                            <form onSubmit={Search}>
                                                <div className="hover_category">
                                                    {/* <select className="nice-select select_option" name="select">
                                                    <option >All Categories</option>
                                                    {categoryLists.map((categoryList, key) => (
                                                        <option key={categoryList.id}><Link to={'/home?&id='+categoryList.id}>{categoryList.name}</Link></option>

                                                    ))}
                                                </select> */}
                                                    <select name="category" id="" className='nice-select' onChange={searchDetails}>
                                                        <option value="all">All Categories</option>
                                                        {categoryLists.map((categoryList, key) => (
                                                            <option key={categoryList.id}>{categoryList.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="search_box">
                                                    <input placeholder="Search products..." type="text" name='search' onChange={searchDetails} />
                                                    <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12 ">
                                        <div className="middel_right">
                                            <div className="middel_right_info wishlist-space">
                                                <div className="header_wishlist">
                                                    <a href="#"><i className="fa fa-envelope-o" aria-hidden="true"></i></a>
                                                    <span className="wishlist_quantity">0</span>
                                                </div>

                                                <Link to="/wishlist"><div className="header_wishlist">
                                                    <a><i className="fa fa-heart-o" aria-hidden="true"></i></a>
                                                    <span className="wishlist_quantity">{WishlistDetailReducer === 0 ? 0 : WishlistDetailReducer.length}</span>
                                                </div></Link>
                                                <div className="header_wishlist">
                                                    <a href="#"><i className="fa fa-bell-o" aria-hidden="true"></i></a>
                                                    <span className="wishlist_quantity">0</span>
                                                </div>
                                                <div className="mini_cart_wrapper">
                                                    <a href="#"><i className="fa fa-shopping-cart"
                                                        aria-hidden="true"></i><i className="fa fa-angle-down"></i></a>
                                                    <span className="cart_quantity">{cart !== 0 ? cart.length : cart}</span>
                                                    {cart.length > 0 ?
                                                        <div className="mini_cart">

                                                            <div className="mini_cart_footer">
                                                                <div className="cart_button">
                                                                    {/* <a href="">View cart</a> */}
                                                                    <Link to='/shopping-cart'>View Cart</Link>
                                                                </div>
                                                                <div className="cart_button">
                                                                    <a>Checkout</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>

                                            </div>
                                            {/* {IsAuthenticated() === true ?

                                        <div className="post_wrapper">
                                            <div className="post_thumb">
                                                <a href="#"><img src={ImageUrl() + "/core/" + profile.image} alt="" /></a>
                                            </div>
                                            <div className="post_info">
                                                <a href="#">{profile.name ? profile.name.toUpperCase() : ""}</a>
                                            </div>
                                        </div>
                                        : <div>

                                            <button className="btn btn-danger"><Link to='/login'>Login</Link></button>


                                        </div>} */}
                                            <div className="middel_right_info border-0 user-login">
                                                {IsAuthenticated() === true ?
                                                    <div className="mini_cart_wrapper">
                                                        <a className='user-icon' ><img src={profile.image ? ImageUrl() + "/core/" + profile.image : "/assets/img/logo/profileAvatar.png"}></img> <span className='user-name'> {profile.name}</span> <i className="fa fa-angle-down"></i>

                                                        </a>

                                                        <div className="mini_cart">
                                                            <div className="cart_item">
                                                                <div className="cart_info">
                                                                    <Link to="/profile">Profile</Link>
                                                                    {/* <a href="#">My Account</a> */}
                                                                    {/* <a href="#">Profile</a> */}
                                                                </div>
                                                            </div>
                                                            <div className="mini_cart_footer">
                                                                <a onClick={Logout}>Sign Out</a></div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <Link className='btn btn-warning' to='/login'>Login</Link>}
                                            </div>
                                            <div className='top-button'>
                                            
                                                {/* <a href='https://ev-web.heptotechnologies.org/seller-admin/profile.php' target="_blank">Become a Seller</a> */}
                                                <a href='https://seller.evzone.app/' target="_blank">Become a Seller</a>
                                                </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main_menu_area">
                            <div className='mob-address'>
                                <ul className='address-right'>
                                    <li>
                                        <span className='deliver-address'><i className="fa fa-map-mar ker" aria-hidden="true"></i>
                                            Deliver to</span>
                                        <a href="#" className='address-text'>{props.address ? props.address.city + "," + props.address.state : null}</a></li>
                                    <li>
                                        {window.location.pathname !== "/checkout" && window.location.pathname !== "/payment-method" ?
                                            <select className='currency-selector' onChange={CurrencyClick} ref={currencyRef}>
                                                {currencyList.length > 0 ?
                                                    currencyList.map((currency) => (
                                                        <option value={currency.code + "," + currency.country_code} selected={getCurrencyCountry().currencyCode ?
                                                            getCurrencyCountry().currencyCode == currency.code ?
                                                                "selected"
                                                                : null
                                                            : profile.currency ?
                                                                profile.currency.code == currency.code ? "selected" : null : null
                                                        }>{currency.code}</option>
                                                    ))
                                                    : null}
                                            </select>
                                            : <p style={{ color: 'white' }}>{getCurrencyCountry().currencyCode}</p>}
                                    </li>
                                    <li><CountryList currencyList={currencyList} selectCountry={getCurrencyCountry().countryCode ? getCurrencyCountry().countryCode : !profile.currency ? "" : profile.currency.country_code} setErrorMessage={setErrorMessage} /></li>
                                    <li style={{ borderRight: "none" }}><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>
                            {/* <div className='mob-cat-icons'>

                                <Carousel responsive={responsive}>
                                    <div><img src="/assets/img/icon/home.png" title='EV Market' alt="EV Market" /></div>
                                    <div><img src="/assets/img/icon/electric-vehicles.png" title='Electric Vehicles' alt="Electric Vehicles" /></div>
                                    <div><img src="/assets/img/icon/electric-chargers.png" title='Electric Chargers' alt="Electric Chargers" /></div>
                                    <div><img src="/assets/img/icon/electric-cable-connectors.png" title='Electric Cable Connectors' alt="Electric Cable Connectors" /></div>
                                    <div><img src="/assets/img/icon/llectirc-parts.png" title='Electric parts' alt="Electric parts" /></div>
                                    <div><img src="/assets/img/icon/electric-accessories.png" title='Electric accessories' alt="Electric accessories" /></div>
                                    <div><img src="/assets/img/icon/electric-vehicles.png" title='Electric Vehicles' alt="Electric Vehicles" /></div>
                                    <div><img src="/assets/img/icon/electric-chargers.png" title='Electric Chargers' alt="Electric Chargers" /></div>
                                    <div><img src="/assets/img/icon/electric-cable-connectors.png" title='Electric Cable Connectors' alt="Electric Cable Connectors" /></div>
                                    <div><img src="/assets/img/icon/llectirc-parts.png" title='Electric parts' alt="Electric parts" /></div>
                                    <div><img src="/assets/img/icon/electric-accessories.png" title='Electric accessories' alt="Electric accessories" /></div>
                                </Carousel>

                            </div> */}
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-1 col-md-1 col-sm-12 pr-0 mob-cat-none">
                                        <div className="cat-dropdown">
                                            <button className="dropbtn">Categories <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </button>
                                            <div className="cat-dropdown-content">
                                                {categoryLists.map((categoryList, key) => (
                                                    // <li key={key}><Link to={'/home?&id=' + categoryList.id}>{categoryList.name}</Link></li>
                                                    <Link to={'/home/' + categoryList.name.replace(/ /g, '-')} key={key}>{categoryList.name}</Link>
                                                ))}</div>
                                        </div>

                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12 tab-view pl-0">
                                        <div className="main_menu menu_position">
                                            <nav>
                                                <ul>
                                                    {categoryLists.length > 0 ?
                                                        <li key="home"><Link className={window.location.pathname === "/home" || window.location.pathname == "/" ? 'active' : ""} to={'/home'}><i className="fa fa-home" aria-hidden="true"></i>
                                                        </Link></li>
                                                        : null}

                                                    {/* <li key="{electric-vehicles}"><Link to={'/electric-vehicles'}>Electric Vehicles</Link></li>
                                            <li><a href="#">EV Chargers </a></li>
                                            <li><a href="#">EV Cables & Connectors</a></li>
                                            <li><a href="#"> EV Accessories</a></li>
                                            <li><a href="#"> EV Spare Parts</a></li> */}
                                                    {categoryLists.map((categoryList, key) => (
                                                        // <li key={categoryList.id}><Link to={'/home?&id=' + categoryList.id}>{categoryList.name}</Link></li>
                                                        <li key={categoryList.id}><Link className={window.location.pathname === "/home/" + (categoryList.name.replace(/ /g, '-')) ? "active" : ""} to={'/home/' + categoryList.name.replace(/ /g, '-')}>{categoryList.name}</Link></li>
                                                        // <li><a href="#">{categoryList.name}</a></li>
                                                    ))}
                                                    {categoryLists.length > 0 ?
                                                        <li className="mega_items"><a href="shop.html">Shopping & Delivery<i
                                                            className="fa fa-angle-down"></i></a>
                                                            <div className="mega_menu">
                                                                <ul className="mega_menu_inner">
                                                                    <li>
                                                                        <ul>
                                                                            <li><a href="#">Food & Drinks</a></li>
                                                                            <li><a href="#">Supermarket / Groceries</a></li>
                                                                            <li><a href="#">Pharmacy</a></li>
                                                                            <li><a href='#'>Phones & Tablets</a></li>
                                                                            <li><a href='#'>Computer & Office</a></li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <ul>
                                                                            <li><a href='#'>Renewable Energy</a></li>
                                                                            <li><a href='#'>Electronics & Appliances</a></li>
                                                                            <li><a href='#'>Health & Beauty</a></li>
                                                                            <li><a href='#'>Clothing & Accessories</a></li>
                                                                            <li><a href='#'>Sports & Gaming</a></li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <ul>
                                                                            <li><a href='#'>Baby Products</a></li>
                                                                            <li><a href='#'>Shoes, Luggage & Bags</a></li>
                                                                            <li><a href='#'>Underwears/Loungewear</a></li>
                                                                            <li><a href='#'>Garden & Farm supplies</a></li>
                                                                            <li><a href='#'>Home & Outdoors</a></li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <ul>
                                                                            <li><a href='#'>Other Categories</a></li>
                                                                            <li><a href='#'>Parcel/Cargo Delivery</a></li>
                                                                            <li><a href='#'>Delivery Genie</a></li>
                                                                            <li><a href='#'>Delivery Runner</a></li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        : null}
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="main_menu nav top_right">
                                            <nav>
                                                <ul className='address-right'>
                                                    <li>
                                                        <span className='deliver-address'><i className="fa fa-map-mar ker" aria-hidden="true"></i>
                                                            Deliver to</span>
                                                        <a href="#" className='address-text'>{props.address ? props.address.city + "," + props.address.state : null}</a></li>
                                                    <li>
                                                        {window.location.pathname !== "/checkout" && window.location.pathname !== "/payment-method" ?
                                                            <select className='currency-selector' onChange={CurrencyClick} ref={currencyRef}>
                                                                {currencyList.length > 0 ?
                                                                    currencyList.map((currency) => (
                                                                        <option value={currency.code + "," + currency.country_code} selected={getCurrencyCountry().currencyCode ?
                                                                            getCurrencyCountry().currencyCode == currency.code ?
                                                                                "selected"
                                                                                : null
                                                                            : profile.currency ?
                                                                                profile.currency.code == currency.code ? "selected" : null : null
                                                                        }>{currency.code}</option>
                                                                    ))
                                                                    : null}
                                                            </select>
                                                            : <p style={{ color: 'white' }}>{getCurrencyCountry().currencyCode}</p>}
                                                    </li>
                                                    {/* <li><a href="#">Eng-{getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code} <i className="fa fa-angle-down"></i></a>
                                                {window.location.pathname !== "/checkout" && window.location.pathname !== "/payment-method" ?
                                                    <ul className="sub_menu pages">
                                                        <li><select className='form-control' ref={currencyRef}>
                                                            <option value="">Select Currency1</option>
                                                            {currencyList.length > 0 ?
                                                                currencyList.map((currency) => (
                                                                    <option value={currency.code + "," + currency.country_code} selected={getCurrencyCountry().currencyCode ?
                                                                        getCurrencyCountry().currencyCode == currency.code ?
                                                                            "selected"
                                                                            : null
                                                                        : profile.currency ?
                                                                            profile.currency.code == currency.code ? "selected" : null : null
                                                                    }>{currency.code}</option>
                                                                ))
                                                                : null}
                                                        </select></li>
                                                        <p className='mt-20 text-right'><a className='btn btn-success' onClick={CurrencyClick}>Submit</a></p>
                                                     </ul>
                                                     : null}
                                             </li> */}
                                                    {/* {getCurrencyCountry().countryCode ? getCurrencyCountry().countryCode : !profile.currency ? "" : profile.currency.country_code} */}
                                                    {/*  <li><a href="#">Country <span className={getCurrencyCountry().countryCode ? "fi fi-" + getCurrencyCountry().countryCode.toLowerCase() : !profile.currency ? "" : "fi fi-" + profile.currency.country_code.toLowerCase()}></span> </a> </li> */}
                                                    <li><CountryList currencyList={currencyList} selectCountry={getCurrencyCountry().countryCode ? getCurrencyCountry().countryCode : !profile.currency ? "" : profile.currency.country_code} setErrorMessage={setErrorMessage} /></li>
                                                    <li style={{ borderRight: "none" }}><Link to="/contact-us">Contact Us</Link></li>
                                                </ul>
                                            </nav>
                                        </div></div>
                                </div>
                            </div>
                        </div>
                        {errorMessage !== "" ?
                            <div className="alert alert-danger" role="alert">{errorMessage}</div>
                            : null}
                    </div>
                </div>
                : null}
        </header>
    );
}
export default Header;