import React, { useEffect, useState } from 'react'
import { removeDuplicates, ViewProductListApi } from '../API/ProductApi';
import { Navigate, useNavigate, useSearchParams, useLocation, Link, useParams } from 'react-router-dom';

import { ConvertCurrency } from '../API/currency'
import { ImageUrl } from '../API/Url';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { IsAuthenticated } from '../API/CheckAuth'
import { AddWishListApi, WishlistDetailApi } from "../API/WishlistApi";
import { setCartData, setCartIdData, getCartData, getCartIdData, removeCartData, removeCartIdData, getWishlistIdData, getWishlistData, setWishlistData, setWishlistIdData, getCurrencyCountry } from '../API/Storage';
import { useSelector, useDispatch } from "react-redux";
import { UserDetails, cartDetails, wishlistDetails, wishlistCount, cartCount } from "../Action/action";
import { AddToCartApi, CartListApi } from '../API/CartApi';
import { AddRecommendProductApi } from "../API/ProductApi";
import { setPathName } from '../API/Storage';
import { checkIndex, filterJson } from '../API/UnAuthApi';
import Footer from '../Layouts/footer';
import { useAddToCart, useRemoveFromCart } from '../API/CartChanges';
import QuickViewPopup from './quickViewPopup';
import { useAddToWishlist, useRemoveFromWishlist } from '../API/WishlistChanges';

const ViewProducts = (props) => {
    const rate = useSelector((state) => state.CurrencyRateReducer);
    const params = useParams();
    const redirect = useNavigate();
    const dispatch = useDispatch();
    let parameters = params.view;
    const location = useLocation();
    const cartDataLocalStorage = JSON.parse(getCartData());
    const cartDataIDLocalStorage = JSON.parse(getCartIdData());
    const cartDataReducer = useSelector((state) => state.CartReducer);
    const wishlistCountDataReducer = useSelector((state) => state.WishlistCountReducer);

    const [urlParameter, setUrlParameter] = useState(parameters);
    const [searchProducts, setSearchProducts] = useState([]);
    const [searchProductsDetails, setSearchProductsDetails] = useState([]);
    const [withoutSearchProduct, setWithoutSearchProduct] = useState('Loading...')
    const [productImageLocation, setProductImageLocation] = useState();
    const [page, setPage] = useState(1);
    const [count, setCount] = useState()
    const [filterOptions, setFilterOptions] = useState([])
    const [sortBy, setSortBy] = useState('popularity')
    
    const wishlistDataLocalStorage                  = JSON.parse(getWishlistData());
    const wishlistDataIdLocalStorage                = JSON.parse(getWishlistIdData());
    const [wishlistId, setWishlistId] = useState([]);
    const [wishlist, setwishlist] = useState([]);
    const [cart, setCart] = useState([]);
    const [cartId, setCartId] = useState([]);
    const [urlCatId, setUrlCatId] = useState();
    const profile = useSelector((state) => state.ProfileReducer);
    const [currency, setCurrency] = useState();
    const [filterProducts, setFilterProducts] = useState([]);
    const [checked, setChecked] = useState(false); 
    const CartListDetails = useSelector((state) => state.CartReducer);
    const WishlistDetailReducer = useSelector((state) => state.WishlistReducer);
    const [showFooter, setShowFooter] = useState(false)

    const [showModal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [popCartDetails, setPopCartDetails] = useState();
    const [popProduct, setPopProduct] = useState();
  
    useEffect(()=>{
      setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
    });
    useEffect(()=>{
        setPathName(window.location.pathname)
    },[])
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [page]);
      function QuickView(product) {
        setShow(true)
        setPopCartDetails(CartListDetails);
        setPopProduct(product)
    }
    useEffect(()=>{
        let id ;
        if(props.categoryListProps.length > 0)
        {
            props.categoryListProps.map((category)=>{
                if(params.category)
                {
                    if(category.name === params.category.replace(/-/g," "))
                    {
                        id = category.id;          
                    }
                }
            })
            if(!id)
            {
                id = params.category;
            }
            setUrlCatId(id)
        }
    })
    if(parameters && parameters !== urlParameter)
    {
        setUrlParameter(parameters);
        setFilterProducts([]);
        setPage(1);
    }    
    const groupedProducts = {};
    useEffect(() => {   
        ViewProductListApi(urlParameter,urlCatId,page,currency,filterProducts,sortBy).then((result) => {
            if(result.data.filter)
            {
                result.data.filter.forEach((product) => {
                if(product.specification_name)
                {
                    if (!groupedProducts[product.specification_name]) {
                        groupedProducts[product.specification_name] = [];
                    }
                    if (
                        product.specification_type &&
                        !groupedProducts[product.specification_name].some(
                        (type) => type.id === product.specification_type_id
                        )
                    ) {
                        groupedProducts[product.specification_name].push({
                        id: product.specification_type_id,
                        name: product.specification_type,
                        });
                    }
                }
              });
              setFilterOptions(groupedProducts)
            }
            setSearchProducts(result.data.products.data);
            setSearchProductsDetails(result.data.products);
            setProductImageLocation('/market/products/images/'); 
            setCount( ((page * result.data.products.per_page)-result.data.products.per_page)); 
            setTimeout(() => {
                setShowFooter(true)                
            }, 3000);

        }).catch((err) => {
            setFilterOptions([]);
            setSearchProducts([]);
            setWithoutSearchProduct("No Products Found");
        setShowFooter(true)

        });
    }, [urlParameter,page,urlCatId,currency,filterProducts,checked,sortBy])
    function PaginationChange(e,value){
        setPage(value);
    }

    const addToCart = useAddToCart();
    const removeFromCart = useRemoveFromCart();

    const AddWishlist = useAddToWishlist();
    const RemoveWishlist = useRemoveFromWishlist();
  
    function FliterFunction(e,data)
    {
        if(e.target.checked)
        {
            setFilterProducts((prev)=>([...prev,data]));
        }
        else
        {
            const updatedArray = filterProducts.filter((value) => value !== data);
            setFilterProducts(updatedArray);
        }
    }
    function SortByFunction(e){
        setSortBy(e.target.value)
    }
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li> Products </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="shop_area shop_reverse pt-40 pb-40 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12">
                            <aside className="sidebar_widget">
                                <div className="widget_inner">
                                    {/* {filterOptions.length > 0 ?  */}
                                    {/* {Object.keys(filterOptions).map((filterOption,key)=>(
                                    <div className="widget_list widget_categories">
                                        {key == 0 ?<h2 >Filter</h2> : null } 
                                                        <div>
                                                            <h4>{filterOption}</h4>
                                                            <ul className='custom-controls-stacked'>
                                                                
                                                                {Object.keys(filterOptions[filterOption]).map((type,key)=>{
                                                                    return(
                                                                <li>
                                                                    <label className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input" name=""  onChange={(e)=>(FliterFunction(e,filterOptions[filterOption][type]))} value={filterOptions[filterOption][type]}/>
                                                                        <span className="custom-control-label">{type}</span>
                                                                    </label>
                                                                </li>
                                                                )})}
                                                            </ul>
                                                        </div>
                                    </div>
                                    ))
                                    } */}
                                    {Object.keys(filterOptions).map((specificationName) => (
                                    <div className="widget_list widget_categories">
                                        <h4>{specificationName}</h4>
                                        <ul className='custom-controls-stacked'>
                                        {specificationName ?  filterOptions[specificationName].map((specificationType) => (
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value={specificationType.id} onChange={(e)=>(FliterFunction(e,specificationType.id))}/>
                                                <span className="custom-control-label">{specificationType.name}</span>
                                            </label></li>
                                            )) : null}
                                            {/* <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Model02</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Model03</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Model04</span>
                                            </label></li> */}
                                        </ul>
                                    </div> ))}
                                    {/* <div className="widget_list widget_categories">
                                        <h4>Body Model</h4>
                                        <ul className='custom-controls-stacked'>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">City</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Truck</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Estate</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Saloon</span>
                                            </label></li>
                                        </ul>
                                    </div>
                                    <div className="widget_list widget_categories">
                                        <h4>Year</h4>
                                        <ul className='custom-controls-stacked'>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">2023</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">2022</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">2021</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">2020</span>
                                            </label></li>
                                        </ul>
                                    </div>
                                    <div className="widget_list widget_filter">
                                        <h2>Filter by price</h2>
                                        <form action="#">
                                            <div id="slider-range"></div>
                                            <button type="submit">Filter</button>
                                            <input type="text" name="text" id="amount" />

                                        </form>
                                    </div>
                                    <div className="widget_list widget_categories">
                                        <h4>Transmission</h4>
                                        <ul className='custom-controls-stacked'>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">AT</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">MT</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">AM</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">CVT</span>
                                            </label></li>
                                        </ul>
                                    </div>
                                    <div className="widget_list widget_categories">
                                        <h4>Exterior colour</h4>
                                        <ul className='custom-controls-stacked'>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Black</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">White</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Red</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Green</span>
                                            </label></li>
                                        </ul>
                                    </div>
                                    <div className="widget_list widget_categories">
                                        <h4>Interior colour</h4>
                                        <ul className='custom-controls-stacked'>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Black</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">White</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Red</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Green</span>
                                            </label></li>
                                        </ul>
                                    </div>
                                    <div className="widget_list widget_categories">
                                        <h4>Seats</h4>
                                        <ul className='custom-controls-stacked'>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">2</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">4</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">5</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">7</span>
                                            </label></li>
                                        </ul>
                                    </div>
                                    <div className="widget_list widget_categories">
                                        <h4>Steering</h4>
                                        <ul className='custom-controls-stacked'>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Right</span>
                                            </label></li>
                                            <li><label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" />
                                                <span className="custom-control-label">Left</span>
                                            </label></li>

                                        </ul>
                                    </div> */}
                                    <div className="widget_list tags_widget">
                                        <h2>Product tags</h2>
                                        <div className="tag_cloud">
                                            <a href="#">Nissan</a>
                                            <a href="#">Honda</a>
                                            <a href="#">BMW</a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                        <div className="col-lg-9 col-md-12">
                            <div className="shop_toolbar_wrapper">
                                <div className="shop_toolbar_btn">
                                    <button data-role="grid_3" type="button" className="btn-grid-3" data-bs-toggle="tooltip"
                                        title="3"></button>
                                    <button data-role="grid_4" type="button" className="btn-grid-4 active" data-bs-toggle="tooltip"
                                        title="4"></button>
                                    <button data-role="grid_list" type="button" className="btn-list" data-bs-toggle="tooltip"
                                        title="List"></button>
                                </div>
                                <div>
                                <form >
                                        <select name="sort_by" id="short" onChange={SortByFunction}>
                                            <option value="popularity">Popularity</option>
                                            <option value="name_a_z">Name: A to Z</option>
                                            <option value="name_z_a">Name: Z to A</option>
                                            <option value="price_l_h">price: low to high</option>
                                            <option value="price_h_l">price: high to low</option>
                                            <option value="newest">Newest First</option>
                                        </select>
                                    </form>
                                </div>
                                
                                <div className="page_amount">
                                {searchProducts.length > 0 ? 
                                    <p>Showing {count+1}&#45;{count+searchProductsDetails.count} of {searchProductsDetails.total} results</p>
                                    : null }
                                    </div>
                            </div>

                            {searchProducts.length > 0 ?
                            <div className="row shop_wrapper grid_4">
                                {searchProducts.map((searchProduct)=>{
                                    
                                    let image =searchProduct.more_images ? searchProduct.more_images.split(",") : ""; 
                                    
                                    return (
                                <div className="col-md-4 col-sm-6 col-lg-4">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <Link to={'/products-details/'+searchProduct.id+'/'+searchProduct.name}>
                                                <a className="primary_img product-view-img">
                                                    <img
                                                    src={ImageUrl()+productImageLocation+searchProduct.image}
                                                     alt="" />
                                                 </a>
                                                <a className="secondary_img">
                                                    <img
                                                    src={ImageUrl()+productImageLocation+image.length > 0 ? image[1] : searchProduct.image} alt="" />
                                                </a>
                                                </Link>
                                                {/* <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div> */}
                                                <div className="action_links">
                                                    <ul>
                                                    {WishlistDetailReducer !== 0 && WishlistDetailReducer.findIndex(item => item.id === searchProduct.id) !== -1 ? 
                                                        <li className="wishlist" onClick={() => RemoveWishlist(searchProduct)}><a title="Add to Wishlist" className= "wlishlist_links_active"><i
                                                        className="fa fa-heart-o" aria-hidden="true"></i></a></li> : 
                                                        <li className="wishlist" onClick={() => AddWishlist(searchProduct)}><a title="Add to Wishlist" className= ""><i
                                                        className="fa fa-heart-o" aria-hidden="true"></i></a></li> }
                                                        <li className="quick_button" onClick={() => QuickView(searchProduct)}><a title="quick view"> <i className="fa fa-window-maximize" aria-hidden="true"></i></a></li>
                                                        {/* <li className="quick_button">
                                                            <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">{searchProduct.name.toUpperCase()}</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">{ConvertCurrency(searchProduct.price,1,currency,rate)}</span>
                                                    <span className="current_price">{ ConvertCurrency(searchProduct.price,1,currency,rate) }</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                        <a className='talk-btn' href="#" title="Talk to an EV Expert"><i className="fa fa-phone" aria-hidden="true"></i></a> 
                                                {CartListDetails.some(item => item.id === searchProduct.id) ?
                                                    <a className='remove-btn' title="Remove cart" tabIndex="0" onClick={() => removeFromCart(searchProduct)}><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i><i className="fa fa-minus-square" aria-hidden="true"></i>
                                                    </a>
                                                    : 
                                                    <a className='buy-btn' title="Add to cart" tabIndex="0" onClick={() => addToCart(searchProduct)}><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i></a>                                                   
                                                }
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">{ConvertCurrency(searchProduct.price,1,currency,rate)}</span>
                                                        <span className="current_price">{ConvertCurrency(searchProduct.price,1,currency,rate)}</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>{searchProduct.description}</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                            
                                                {CartListDetails.some(item => item.id === searchProduct.id) ?
                                                <div className="add_to_cart">
                                                <a className='buy-btn' title="add to cart" tabIndex="0" onClick={() => removeFromCart(searchProduct)}>Remove From Cart</a>
                                            </div>
                                                : 
                                                <div className="add_to_cart">
                                                        <a className='buy-btn' title="add to cart" tabIndex="0" onClick={() => addToCart(searchProduct)}>Add To Cart</a>
                                                    </div>
                                                 }

                                                    <div className="action_links">
                                                        <ul>
                                                        {WishlistDetailReducer !== 0 && WishlistDetailReducer.findIndex(item => item.id === searchProduct.id) !== -1 ? 
                                                        <li className="wishlist" onClick={() => RemoveWishlist(searchProduct)}><a title="Add to Wishlist" className= "wlishlist_links_active"><i
                                                        className="fa fa-heart-o" aria-hidden="true"></i></a></li> : 
                                                        <li className="wishlist" onClick={() => AddWishlist(searchProduct)}><a title="Add to Wishlist" className= ""><i
                                                        className="fa fa-heart-o" aria-hidden="true"></i></a></li> }
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div>
                                )})}
                                {/* <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-w-full.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div> */}
                                {/* <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-orange.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div>
                                <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-blue.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div>
                                <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-w-full.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div>
                                <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-w-full.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div>
                                <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-w-full.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div>
                                <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-w-full.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div>
                                <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-w-full.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div>
                                <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-w-full.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div>
                                <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-w-full.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div>
                                <div className="col-md-4 col-sm-6 col-lg-3">
                                    <article className="single_product">
                                        <figure>
                                            <div className="product_thumb">
                                                <a className="primary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-w-full.jpg" alt="" /></a>
                                                <a className="secondary_img" href="#"><img
                                                    src="/assets/img/product/cars/nissan-nismo.jpg" alt="" /></a>
                                                <div className="label_product">
                                                    <span className="label_sale">27%</span>
                                                </div>
                                                <div className="action_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i
                                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#modal_box" title="quick view"> <span
                                                                className="ion-ios-search-strong"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div> 

                                            <div className="product_content grid_content">
                                                <h3 className="product_name grid_name"><a href="#">Nissan 2022</a></h3>
                                                <div className="price_box">
                                                    <span className="old_price">UGX - 999999</span>
                                                    <span className="current_price">UGX - 88888</span>
                                                </div>
                                                <div className="product_ratings">
                                                    <ul>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-button">
                                                <a className='talk-btn' href="#" title="Talk to an EV Expert">Talk to an EV Expert</a> <a className='buy-btn' href="#" title="Buy Now"><i className="fa fa-cart-plus mr-2" aria-hidden="true"></i>
                                                    Buy Now</a>
                                            </div>
                                            <div className="product_content list_content">
                                                <div className="left_caption">
                                                    <div className="price_box">
                                                        <span className="old_price">UGX - 999999</span>
                                                        <span className="current_price">UGX - 88888</span>
                                                    </div>
                                                    <h3 className="product_name"><a href="#">Nissan 2022</a></h3>
                                                    <div className="product_ratings">
                                                        <ul>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                            <li><a href="#"><i className="ion-android-star-outline"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product_desc">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                            ad minim veniam, quis nostrud exercitation ullamco,Proin lectus
                                                            ipsum, gravida et mattis vulputate, tristique ut lectus</p>
                                                    </div>
                                                </div>
                                                <div className="right_caption">
                                                    <div className="add_to_cart">
                                                        <a href="cart.html" title="add to cart">Add to cart</a>
                                                    </div>
                                                    <div className="action_links">
                                                        <ul>
                                                            <li className="wishlist"><a href="wishlist.html"
                                                                title="Add to Wishlist"><i className="fa fa-heart-o"
                                                                    aria-hidden="true"></i> Add to Wishlist</a></li>
                                                            <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#modal_box" title="quick view"> <span
                                                                    className="ion-ios-search-strong"></span> Quick View</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </figure>
                                    </article>
                                </div> */}
                            </div>
                            : <div>{withoutSearchProduct}</div> }
                            {searchProducts.length > 0 ?
                            <div className="shop_toolbar t_bottom">
                                <div className="pagination">
                                {<Stack spacing={3}>
                                                <Pagination count={searchProductsDetails.total_pages} onChange={ PaginationChange }  page={page}/>
                                                {/* <Pagination count={10} color="primary" />
                                                <Pagination count={10} color="secondary" />
                                                <Pagination count={10} disabled /> */}
                                                </Stack>
                                                }
                                    {/* <ul>
                                        <li className="current">1</li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li className="next"><a href="#">next</a></li>
                                        <li><a href="#">&gt;&gt;</a></li>
                                    </ul> */}
                                </div>
                            </div>
                            : null }

                        </div>
                    </div>
                </div>
            </div>
            <QuickViewPopup setShow={setShow} showModal={showModal} popProduct={popProduct} productImagePath={productImageLocation} currency={currency}/>
            {showFooter && <Footer />}
        </>
    );
}
export default ViewProducts;