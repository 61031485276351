import React,{useEffect} from 'react'
import { setPathName } from '../API/Storage'
import Footer from '../Layouts/footer'
const ContactUs = () => {
    useEffect(()=>{
        setPathName(window.location.pathname)
    },[])
    return (
        <>
        <div className="signup-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="account_form login-form">
                            <div className='login-header'>
                                <h3><span className='border-bottom'>Contact Us</span></h3>
                                <div className="form-group m-0">
                                    <div className="row">
                                    <div className="col-md-12 form-group">
                                            <input type="text" className="form-control" placeholder="Name" name="username" />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <input type="text" className="form-control" placeholder="Mobile Number" name="" />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <input type="text" className="form-control" placeholder="Email Address" name="" />
                                        </div>
                                        <div className="col-md-12 form-group contact_message">
                                            <textarea className='form-control2' placeholder='Message'></textarea>
                                        </div>

                                    </div>
                                </div>
                                <div className="login_submit">
                                    
                                    <div className='login-submit-btn'>
                                        <button type="submit" >Submit</button>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}
export default ContactUs;