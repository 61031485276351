import React, { useState } from "react";
import { Navigate, useNavigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Footer from "../Layouts/footer";
import ReactCountryFlag from 'react-country-flag';
import { ForgotPasswordApi, FpVerifyOtp, ResendOtpApi } from "../API/Auth";
// import { setFPToken } from "../API/Storage";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const ForgotPassword = ({mobileCode}) => {
    const redirect = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(true);

    const [isMobile, setIsMobile] = useState(false);
    const [username, setUsername] = useState();
    const [fpToken, setFpToken] = useState();
    const [sentOtp, setSentOtp] = useState();
    const [showErrors, setShowErrors] = useState({});
    function handleInput(e){
        if (isNaN(e.target.value) || e.target.value == "") {
            setIsMobile(false);
        }
        else {
            setIsMobile(true);
        }
        setShowErrors({})
    }
    function handleSubmit(e){
        e.preventDefault();
        if(e.target.username.value)
        {
            ForgotPasswordApi(e.target.username.value,e.target.mcc_id ? e.target.mcc_id.value : null).then((res)=>{
                setOpen(true);
                setFpToken(res.data.data.access_token);
                setSentOtp(res.data.data.email);
                e.target.reset();
            }).catch((err)=>{
                if(err.response.status == 401){
                    error401();
                }
                else
                {
                    setShowErrors({usernameError:err.response.data.message});
                }
            })
            
        }
        else
        {
            setShowErrors({usernameError:"Email or Mobile number is required"});
        }
    }

    function handleOtpSubmit(e){
        e.preventDefault();
        if(e.target.otp.value){
            FpVerifyOtp(fpToken,e.target.otp.value).then((res)=>{
                let data = {token : res.data.data.access_token}
                redirect('/confirmpassword', { state: data });
            e.target.reset();

            }).catch((err)=>{
                if(err.response.status == 401){
                    error401();
                }
                else
                {
                    setShowErrors({otpError:err.response.data.message});
                }
            e.target.reset();

            })
        }
        else
        {
            setShowErrors({otpError:"Otp field is required"});
        }
    }
    function resendOtp(){
        ResendOtpApi(fpToken,isMobile ? "sms" : "email" ).then((res)=>{
            console.log(res);
        }).catch((err)=>{
            if(err.response.status == 401){
                error401();
            }
        })
    }
    function error401(){
        setOpen(false);
        setShowErrors({usernameError: "Retry the mobile or email"});

    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Enter OTP
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>Otp sent on {sentOtp}</p>
                    <form onSubmit={handleOtpSubmit}>
                        <input type="text" className="form-control" placeholder="Enter Otp" name="otp" onChange={()=>{setShowErrors({})}}/>
                        <span style={{color:"red"}}>{showErrors.otpError}</span>
                        <div className="login_submit mt-20">
                            <div className='login-submit-btn'>
                                <button className="btn btn-success">Submit</button>
                            </div>
                            <a onClick={resendOtp}> resend otp</a>
                        </div>
                    </form>
                    </Typography>
                </Box>
            </Modal>

            <div className="forgotpassword-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            {/* <div className='sign-up-error-msg'>
                                <div className="alert alert-danger" role="alert">
                                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>Danger alert—At vero eos et accusamus praesentium! Danger alert—At vero eos et accusamus praesentium!
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="account_form login-form">
                                <form action="" onSubmit={handleSubmit}>
                                    <div className='login-header'>
                                        <h3><span className='border-bottom'>Forgot Password</span></h3>
                                        <p>No Worries, we’ll send you reset instructions</p>
                                        <div className="form-group m-0">
                                            <div className="row gutters-xs">   
                                            {isMobile && <div className="col-3">
                                                <select className="form-control select2 custom-select" name="mcc_id" onChange={handleInput} >
                                                {
                                                    mobileCode.map((mcc) => { 
                                                        return(
                                                        <option value={mcc.id}  key={mcc.id}><ReactCountryFlag countryCode={mcc.country_code}/>  +{mcc.code}</option>
                                                    )})
                                                }
                                                </select>
                                            </div> }
                                                <div className={`${isMobile ? "col-9" : "col-12"} postion-rel`}>
                                                    <input type="text" className="form-control" placeholder="Email ID / Mobile Number" name="username"  onChange={handleInput}/>
                                                </div>
                                            </div>
                                            <span style={{color:"red"}}>{showErrors.usernameError}</span>
                                        </div>
                                        <div className="login_submit mt-20">
                                            <div className='login-submit-btn'>
                                                <button >Submit</button>
                                            </div>
                                        </div>
                                        <div className="text-center mt-20"><Link> <i className="fa fa-long-arrow-left mr-20" aria-hidden="true"></i>
                                            Back to Login</Link></div>
                                    </div>
                                    <div className='login-footer'>
                                        <div><button className='google-btn' href='#'><i className="fa fa-google" aria-hidden="true"></i> &nbsp; Sign in with google</button></div>
                                        <div className='dont-account'>
                                            <Link to={'/sign-up'}>
                                                Dont' have an account?
                                                <span className='signup-hint'>Sign up for Free</span>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </>
    );
}
export default ForgotPassword