import axios from "axios";
import { getToken } from "./Storage";
import {CommonBaseUrl} from "./Url";
// axios.defaults.baseURL = CommonBaseUrl();
const commonBaseUrl     = CommonBaseUrl();
const mobileCodeUrl     = commonBaseUrl+'general/mcc/list/all';
const allCountryListUrl = commonBaseUrl+'general/country/list/all';
const allCurrencyListUrl = '/get/currency/list';
const currencyRateUrl = '/get/currency/rate';
const stateListUrl = commonBaseUrl+"general/state/list/all";
const cityListUrl = commonBaseUrl+"general/city/list/all";
const locationTypeUrl = commonBaseUrl+"user/location/types";
const checkCurrencyUrl = '/check/currency';
export function MobileCode(){
    return axios.get(mobileCodeUrl);
}

export function AllCountryList(){
    return axios.get(allCountryListUrl)
}
export function getGeoInfo(){
    return axios.get("https://ipapi.co/json/")
};
export function AllCurrencyList(){
    return axios.get(allCurrencyListUrl);
}
export function CurrencyRateApi(currency){
    return axios.get(currencyRateUrl+"?currency="+currency);
}
export function checkIndex(arr,id)
{
    return arr.findIndex(function(item, i){
        return item === id
      });     
}
export function filterJson(arr,id){
    if(!arr)
    {
        arr = [];
    }
    return arr.filter(function(item) { return item === id; });
}
export function GetStateList(countryId){
    return axios.get(stateListUrl+"?country_id="+countryId);
}
export function GetCityList(countryId,stateId){
    return axios.get(cityListUrl+"?country_id="+countryId+"&state_id="+stateId);
}
export function GetLocationType(){
    const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
    return axios.get(locationTypeUrl,config);
}
export function CheckCurrency(data){
    return axios.post(checkCurrencyUrl,data)
}