import React, { useState, useEffect, useRef } from "react";
import { ProductFindApi, AddRecommendProductApi, RecommendProductListApi, SimilarProductListApi, UsersLikedProductListApi} from "../API/ProductApi";
import {  useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import { ImageUrl } from '../API/Url';

import { setCartData, setCartIdData, getCartData, getCartIdData, removeCartData, removeCartIdData, setWishlistData, setWishlistIdData, getWishlistData, getWishlistIdData, getCurrencyCountry } from '../API/Storage';
import { useSelector, useDispatch } from "react-redux";
import { AddToCartApi, CartListApi } from '../API/CartApi';
import { IsAuthenticated } from '../API/CheckAuth'
import { UserDetails, cartDetails, wishlistDetails, wishlistCount, cartCount } from "../Action/action";
import { AddWishListApi, WishlistDetailApi } from "../API/WishlistApi";
import { ConvertCurrency } from "../API/currency";
import { ProductListApi } from '../API/HomeApi';
import { setPathName } from "../API/Storage";
import { checkIndex, filterJson } from '../API/UnAuthApi';
import ProductDetailsimgcarousel from "./productdetailsimgcarousel";
import Footer from "../Layouts/footer";
import { Helmet } from 'react-helmet';
import { useAddToCart, useRemoveFromCart } from '../API/CartChanges';
import { useAddToWishlist, useRemoveFromWishlist } from "../API/WishlistChanges";
const ProductsDetails = (props) => {
  const rate = useSelector((state) => state.CurrencyRateReducer);

    const redirect = useNavigate();
    const location = useLocation();
    const specifactionRef = useRef();
    const recommendProductsRef = useRef();
    const similarProductsRef = useRef();
    const userAlsoLikedRef = useRef();
    // const params = new URLSearchParams(location.search)
    const params = new useParams();
    let productId = params.category;
    const dispatch = useDispatch();
    const CartListDetails = useSelector((state) => state.CartReducer);
    const WishlistDetailReducer = useSelector((state) => state.WishlistReducer);
    const [totalRatings, seTtotalRatings] = useState(0)
    const [productDetails, setProductDetails]   = useState([]);
    const [productRatings, setProductRatings]   = useState([]);
    const [productReviews, setProductReviews]   = useState([]);
    const [specification, setSpecification]   = useState([]);
    const cartDataLocalStorage                  = JSON.parse(getCartData());
    const cartDataIDLocalStorage                = JSON.parse(getCartIdData());
    const wishlistDataLocalStorage                  = JSON.parse(getWishlistData());
    const wishlistDataIdLocalStorage                = JSON.parse(getWishlistIdData());
    const cartDataReducer = useSelector((state) => state.CartReducer);
    const wishlistCountDataReducer = useSelector((state) => state.WishlistCountReducer);
    const [cart, setCart] = useState([]);
    const [cartId, setCartId] = useState([]);
    const [wishlistId, setWishlistId] = useState([]);
    const [wishlist, setwishlist] = useState([]);
    const [specifactionVisible, setspecifactionVisible] = useState(false);
    const [recommendProductsVisible, setRecommendProductsVisible] = useState(false);
    const [similarProductsVisible, setSimilarProductsVisible] = useState(false);
    const [recommendProducts, setRecommendProducts] = useState([]);
    const [similarProducts, setSimilarProducts] = useState([]);
    const [userLikedProducts, setuserLikedProducts] = useState([]);
    const [productImagePath, setProductImagePath] = useState();
    const [products, setProducts] = useState([]);
    const [categoryCount, setCategoryCount] = useState(0);
    const [userLikedProductsVisible, setUserLikedProductsVisible] = useState(false);
    const [getCategoryId, setGetCategoryId] = useState([]);
    const [moreImages, setMoreImages] = useState([])
    const profile = useSelector((state) => state.ProfileReducer);
    const [averageRating, setAverageRating] = useState(0)
    const [currency, setCurrency] = useState()
    const [displayRows, setDisplayRows] = useState(1)
    const [showFooter, setShowFooter] = useState(false)
    useEffect(()=>{
      setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
    });
    useEffect(()=>{
        setPathName(window.location.pathname)
    },[])
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[params])
    useEffect(()=>{
        if(currency)
        {
            ProductFindApi(productId,currency).then((result) => {

                if(result.data.status == "success")
                {
                    let total = 0;
                    let average = 0;
                    let total_star = 0;
                    let t = 0
                    setProductDetails(result.data.product_details);
                    setProductRatings(result.data.ratings);
                    result.data.ratings.map((r)=>{
                        total = total += r.counts
                        total_star += ((r.rating*r.counts))
                        t += r.rating+r.counts
                    })
                    setAverageRating(total_star/total)
                    seTtotalRatings(total)
                    setProductReviews(result.data.reviews);
                    setMoreImages(result.data.more_image)
                    let specLength = (result.data.specification.length / 3)
                    let specDevide = [];
                    let d;
                    for(let i=1; i<=specLength; i++)
                    {
                        d = result.data.specification.splice(-3);
                        specDevide.push(d)

                    }
                    if(result.data.specification.length > 0)
                    {
                        specDevide.push(result.data.specification)
                    }
                    setSpecification(specDevide)
                    setShowFooter(true)
                }
            }).catch((err) => {
                setProductDetails([]);
                setProductRatings([]);
                setProductReviews([]);
            });
        }
    },[currency,params]);
    const addToCart = useAddToCart();
    const removeFromCart = useRemoveFromCart();

    const AddWishlist = useAddToWishlist();
    const RemoveWishlist = useRemoveFromWishlist();

    useEffect(()=>{
        AddRecommendProductApi(productId);
    },[]);

    // Start recommend products
    useEffect(()=>{
        if(specifactionRef.current)
        {
            const specificationObserver = new IntersectionObserver((entries)=>{
                const entry = entries[0];
                setspecifactionVisible(entry.isIntersecting);
              })
            specificationObserver.observe(specifactionRef.current)
        }
    })
  useEffect(()=>{
    if(specifactionVisible === true)
    {
        RecommendProductListApi(currency).then((result) => {
            setRecommendProducts(result.data.data);
            setProductImagePath(result.data.product_image_path);  
        }).catch((err) => {
            setRecommendProducts([]);
        });
    }
  },[specifactionVisible,currency])
    //End Recommend products
    // Start Similar products
    useEffect(()=>{
        if(recommendProductsRef.current)
        {
            const recommendProductsObserver = new IntersectionObserver((entries)=>{
                const entry = entries[0];
                setRecommendProductsVisible(entry.isIntersecting);
              })
            recommendProductsObserver.observe(recommendProductsRef.current)
        }
    })
    useEffect(()=>{
        if(recommendProductsVisible === true)
        {
            let limit = 4;
            SimilarProductListApi(productId,limit,currency).then((result) => {
                setSimilarProducts(result.data.data.data);
                // setProductImagePath(result.data.data.product_image_location);  
            }).catch((err) => {
                
            });
        }
      },[recommendProductsVisible,currency])
    // End Similar products
    // Start user like products
    useEffect(()=>{
        if(similarProductsRef.current)
        {
            const similarProductsObserver = new IntersectionObserver((entries)=>{
                const entry = entries[0];
                setSimilarProductsVisible(entry.isIntersecting);
              })
              similarProductsObserver.observe(similarProductsRef.current)
        }
    })
    useEffect(()=>{
        
        if(similarProductsVisible === true)
        {
            let limit = 4;
            UsersLikedProductListApi(currency).then((result) => {
                setuserLikedProducts(result.data.data);
                // setProductImagePath(result.data.data.product_image_location);  
            }).catch((err) => {
                
            });
        }
      },[similarProductsVisible,currency])
    // End user like products
    // Start category wise product list
  function getProductsData(data = 0, length = 0, cc = 0) {
    let catid = data;
    
      ProductListApi(catid,currency,1,4).then((response) => {
        if (response.data.status == 'success') {
          products.push(response.data.products);
        }
      }).catch((err) => {

      }).finally(() => {
        if (length - 1 > cc) {
          setCategoryCount(categoryCount + 1);
        }
      });
  }
    useEffect(() => {
        if(userAlsoLikedRef.current)
        {
            const userAlsoLikedobserver = new IntersectionObserver((entries) => {
                const newArrivalEntry = entries[0];
                setUserLikedProductsVisible(newArrivalEntry.isIntersecting);
                let category_id;
                // if(props.categoryListProps.length == 0)
                // {
                //   category_id = 0;
                // }
                if (props.categoryListProps.length > 0) {
                    category_id = props.categoryListProps[categoryCount].id;
                    let index = getCategoryId.indexOf(category_id);
                    if (index === -1) {
                        getProductsData(category_id, props.categoryListProps.length, categoryCount);
                        getCategoryId.push(category_id);
                    }
                }
            })
            userAlsoLikedobserver.observe(userAlsoLikedRef.current);
        }
    })
  // }, [categoryCount, newArrivalVisable])
  // End category wise product list
 
function addQuantity(quantity,data){
    setProductDetails([{...data,cartQuantity:quantity}])
}
function handleShowMoreClick() {
    setDisplayRows(specification.length);
  }
  function handleShowLessClick(){
    setDisplayRows(1)
  }
    return (
        <>
        {productDetails[0] ? 
        <Helmet>
            <title>Electric Vehicle Marketplace | Evmarket</title>
<link rel="canonical" href={`https://ev-market-frontend.heptotechnologies.org/products-details/${productDetails[0].id}/${productDetails[0].name}`} />
<meta name="robots" content="index, follow" />
<meta name="description" content="Visit Evmarket, established marketplace for Electric Vehicles. Explore the website and browse through our full range of offers." />
<meta name="description" content="My page description" />
        <meta property="og:title" content="Electric Vehicle Marketplace | EV MarketPlace" />
        <meta property="og:description" content="My page description" />
        <meta property="og:image" content={ImageUrl()+"market/products/images/"+productDetails[0].image} />
        <meta property="og:url" content={`https://ev-market-frontend.heptotechnologies.org/products-details/${productDetails[0].id}/${productDetails[0].name}`} />
        {/* <meta name="twitter:title" content="My Page Title" />
        <meta name="twitter:description" content="My page description" />
        <meta name="twitter:image" content="https://example.com/image.png" />
        <meta name="twitter:card" content="summary_large_image" /> */}
            </Helmet>
            : null}
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li><a href="#">Products</a></li>
                                    <li>product details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {productDetails[0] ?
            <div className="product_details pt-40 pb-40 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="product-details-tab">
                                <ProductDetailsimgcarousel moreImages = {moreImages}></ProductDetailsimgcarousel>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="product_d_right">
                                {/* <form action="#"> */}
                                    
                                    <h1>{productDetails[0].name}</h1>
                                    <h3>{productDetails[0].description}</h3>
                                    {/* <div className="product_nav">
                                        <ul>
                                            <li className="prev"><a href="#"><i className="fa fa-share-alt" aria-hidden="true"></i>
                                            </a></li>
                                        </ul>
                                    </div> */}
                                    <div className=" product_ratting">
                                        <ul>
                                            <li><a href="#"><i className="fa fa-star"></i></a></li>
                                            <li><a href="#"><i className="fa fa-star"></i></a></li>
                                            <li><a href="#"><i className="fa fa-star"></i></a></li>
                                            <li><a href="#"><i className="fa fa-star"></i></a></li>
                                            <li><a href="#"><i className="fa fa-star"></i></a></li>
                                            <li className="review"><a href="#"> {totalRatings ? totalRatings : <span className="ratingnum">0</span> } Ratings </a></li>
                                        </ul>
                                    </div>
                                    <div className="price_box"> 
                                    <span className="current_price"> 
                                    {/* { ConvertCurrency(productDetails[0].price, 1, currency,rate)} */}
                                    { ConvertCurrency((productDetails[0].price - (productDetails[0].price * productDetails[0].offer / 100)) * rate, 1, currency, 1 )  }
                                    </span> 
                                    {/* {productDetails[0].offer ? <span className="offer_price">({productDetails[0].offer}%)</span> : null}  */}
                                    </div>
                                    <div className="price_box">
                                        {/* <span className="save_price">{ConvertCurrency((productDetails[0].price - (productDetails[0].price * productDetails[0].offer / 100)), 1, currency,1)*productDetails[0]}</span> */}
                                    {/* <span className="old_price">{ConvertCurrency(productDetails[0].mrp_price, 1, currency,rate)}</span><span>(Inc of all taxes)</span> */}
                                    </div>
                                    <div className="wish_add_btn mb-15">
                                        <div className="product_variant quantity">
                                            <label>quantity</label>
                                            <input min="1" max="100" type="number"  
                                            value={!productDetails[0].cartQuantity ? 1 : productDetails[0].cartQuantity} onChange={(e) => addQuantity(e.target.value, productDetails[0])}/>
                                            <div className={CartListDetails.some(item => item.id === productDetails[0].id) ? 'default_btn bg-red' : 'default_btn'}>
                                                {CartListDetails.some(item => item.id === productDetails[0].id) ?
                                                <a title="remove from cart" tabIndex="0" onClick={() => addToCart(productDetails[0])}>Update From Cart</a>
                                                :  
                                                <a title="add to cart" tabIndex="0" onClick={() => addToCart(productDetails[0])}>Add To Cart</a>}          
                                            </div>
                                                {/* <button className="button" onClick={() => addToCart(productDetails[0])}>
                                                <img className="img-icon" src="/assets/img/icon/shopping-bag.png" />
                                            add to cart</button> */}
                                            
                                            {WishlistDetailReducer !== 0 && WishlistDetailReducer.findIndex(item => item.id === productDetails[0].id) !== -1 ? 
                                            <button className="button ert wish-btn" type="submit" onClick={()=>RemoveWishlist(productDetails[0])}><i className="fa fa-heart-o" aria-hidden="true"></i> Remove From Wishlist</button> : 
                                            <button className="button ert wish-btn" type="submit" onClick={()=>AddWishlist(productDetails[0])}><i className="fa fa-heart-o" aria-hidden="true"></i> Wishlist</button> }
                        
                                        </div>
                                    </div>
                                    {/* <div className="bank_offer">
                                        <ul>
                                            <li>Flat UGX750 Instant Discount* with HDFC Bank Credit Card EMI <a href="#">Know more</a></li>
                                            <li>Save upto UGX-1,500 and 0% interest on 6 and 8-month EMI with ZestMoney <a href="#">Know more</a></li>
                                            <li>Limited Deal: Get backpack at UGX-149 on checkout <a href="#">Know more</a></li>
                                            <li>Protect phone against accidental & liquid damage at only UGX 2/ day <a href="#">Know more</a></li>
                                        </ul>
                                    </div> */}
                                    {/* <div className="delivery-option">
                                        <h3>Delivery Option</h3>
                                        <div className="row">
                                            <div className="col-12 pb-10">
                                                <span><input className="form-control" type="text" placeholder="Check your postal code" /> <i className="fa fa-truck ship-icon" aria-hidden="true"></i></span> <span><button className="delivery-btn button">Check</button></span>
                                            </div>
                                            <div className="col-12"><p>Please enter PIN code to check delivery time & Pay on Delivery Availability</p></div>
                                        </div>
                                    </div> */}
                                    <div ref={specifactionRef}></div>
                                    {specification.length > 0 ? 
                                    <div className="spec" >
                                        <h3>Specifications</h3>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <table className="table card-table">
                                                        <tbody>
                                                        {specification.slice(0, displayRows).map((specDevideArray,key)=>{
                                                            return(
                                                            <tr>
                                                                {specDevideArray.map((m)=>{return(
                                                                <td><span>{m.name}</span>
                                                                    <h5>{m.type}</h5>
                                                                </td>
                                                                )})}
                                                            </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    {specification.length > 1 ?
                                                    displayRows < specification.length ? (
                                                    <span><a className="more-btn" onClick={handleShowMoreClick}>Show More</a></span>
                                                    ) : <span><a className="more-btn" onClick={handleShowLessClick}>Show Less</a></span>
                                                     : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        :null}
{productRatings.length > 0 ? 
                                    <div className="rating rating-top">
                                        <div className="title">
                                            <h3>Ratings</h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 border-right">
                                                <div className="rating">
                                                    <div className="score">
                                                        <div className="average-score">
                                                            <p className="numb queue">{averageRating}  <i className="fa fa-star" aria-hidden="true"></i></p>
                                                            <p className="text">Verified ratings &#40; {totalRatings ? totalRatings : 0 } &#41;</p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-8 col-sm-12">
                                                <ul className="queue-box">
                                                    <li className="five-star">
                                                        <span>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                        </span>
                                                        <span className="numb-star">{productRatings[0] ? productRatings[0].counts : 0}</span>
                                                    </li>
                                                    <li className="four-star">
                                                        <span>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                        </span>
                                                        <span className="numb-star">{productRatings[1] ? productRatings[1].counts : 0}</span>
                                                    </li>
                                                    <li className="three-star">
                                                        <span>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                        </span>
                                                        <span className="numb-star">{productRatings[2] ? productRatings[2].counts : 0}</span>
                                                    </li>
                                                    <li className="two-star">
                                                        <span>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                        </span>
                                                        <span className="numb-star">{productRatings[3]? productRatings[3].counts : 0}</span>
                                                    </li>
                                                    <li className="one-star">
                                                        <span>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                        </span>
                                                        <span className="numb-star">{productRatings[4] ? productRatings[4].counts : 0}</span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="col-md-12">
                                                <ul className="review-list">
                                                    {/* <li>
                                                        <div className="review-metadata">
                                                            <div className="name">
                                                                Ali Tufan : <span>April 3, 2016</span>
                                                            </div>
                                                            <div className="queue">
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <div className="review-content">
                                                            <p>
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                                        </div>
                                                    </li> */}
                                                    {/* <li>
                                                        <div className="review-metadata">
                                                            <div className="name">
                                                                Peter Tufan : <span>April 3, 2016</span>
                                                            </div>
                                                            <div className="queue">
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <div className="review-content">
                                                            <p>
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="review-metadata">
                                                            <div className="name">
                                                                Jon Tufan : <span>April 3, 2016</span>
                                                            </div>
                                                            <div className="queue">
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <div className="review-content">
                                                            <p>
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                                                        </div>
                                                    </li> */}
                                                </ul>

                                            </div>
                                        </div>
                                        
                                        <span><a className="more-btn" href="#">View all reviews</a></span>
                                    </div>
                                    : null}
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :null}
            {productDetails[0] &&
            recommendProducts.length > 0 ? 
            <section className="top_category_product mb-40 bg-grey" ref={recommendProductsRef}>
                <div className="container pt-20 pb-20 bg-grey">
                    <div className="row">
                        <div className="section_title"><h2 className='border-bottom'>You May also like</h2></div>
                        {recommendProducts.map((recommendProduct)=>(
                        <div className="col-lg-3">
                            <article className="single_category">
                                <Link to={"/products-details/" + recommendProduct.id + "/" + recommendProduct.name}>
                                <figure>
                                    <div className="category_thumb">
                                        <a ><img src={ImageUrl()+productImagePath+recommendProduct.image} alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h3 className='product-name'><a>{recommendProduct.name}</a></h3>
                                    </figcaption>
                                </figure>
                                </Link>
                     
                                
                                <div className={CartListDetails.some(item => item.id === recommendProduct.id) ? 'default_btn bg-red' : 'default_btn'}>
                                {CartListDetails.some(item => item.id === recommendProduct.id) ? 
                                <a title="add to cart" tabIndex="0" onClick={() => removeFromCart(recommendProduct)}>Remove From Cart</a>
                                :
                                <a title="add to cart" tabIndex="0" onClick={() => addToCart(recommendProduct)}>Add To Cart</a>
                                }
                                    {/* <a href="#" title="add to cart" tabIndex="0">Shop Now</a> */}
                                </div>    
                                
                            </article>
                        </div>
                        ))}
                    </div>
                </div>
            </section>
            : null}
            {productDetails[0] &&
            similarProducts.length > 0 ? 
            <section className="top_category_product mb-40 bg-grey" ref={similarProductsRef}>
                <div className="container pt-20 pb-20 bg-grey">
                    <div className="row">
                        <div className="section_title"><h2 className='border-bottom'>Similar products</h2></div>
                        {similarProducts.map((similarProduct)=>(
                        <div className="col-lg-3">
                            <article className="single_category">
                            <Link to={"/products-details/" + similarProduct.id + "/" + similarProduct.name}>
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src={ImageUrl()+productImagePath+similarProduct.image} alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h3 className='product-name'><a href="#">{similarProduct.name}</a></h3>
                                    </figcaption>
                                </figure>
                                </Link>
                                <div className={CartListDetails.some(item => item.id === similarProduct.id) ? 'default_btn bg-red' : 'default_btn'}>
                                {CartListDetails.some(item => item.id === similarProduct.id) ? 
                                <a title="add to cart" tabIndex="0" onClick={() => removeFromCart(similarProduct)}>Remove From Cart</a>
                                :
                                <a title="add to cart" tabIndex="0" onClick={() => addToCart(similarProduct)}>Add To Cart</a>
                                }
                                </div>
                            </article>
                        </div>
                        ))}
                    </div>
                </div>
            </section>
            : null}
            {productDetails[0] &&
            userLikedProducts.length > 0 ? 
            <section className="top_category_product mb-40 bg-grey" ref={userAlsoLikedRef}>
                <div className="container pt-20 pb-20 bg-grey">
                    <div className="row">
                        <div className="section_title"><h2 className='border-bottom'>Customers also liked</h2></div>
                        {userLikedProducts.map((userLikedProduct)=>(
                        <div className="col-lg-3">
                            <article className="single_category">
                            <Link to={"/products-details/" + userLikedProduct.id + "/" + userLikedProduct.name}>
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src={ImageUrl()+productImagePath+userLikedProduct.image} alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h3 className='product-name'><a href="#">{userLikedProduct.name}</a></h3>
                                    </figcaption>
                                </figure>
                                </Link>
                                <div className={CartListDetails.some(item => item.id === userLikedProduct.id) ? 'default_btn bg-red' : 'default_btn'}>
                                    { CartListDetails.some(item => item.id === userLikedProduct.id) ?
                                    <a title="add to cart" tabIndex="0" onClick={() => removeFromCart(userLikedProduct)}>Remove From Cart</a>
                                    :
                                    <a title="add to cart" tabIndex="0" onClick={() => addToCart(userLikedProduct)}>Add To Cart</a>
                                    }
                                </div>
                            </article>
                        </div>
                        ))}
                    </div> 
                </div>
            </section>
            : null}
            {products.length > 0 ? 
            products.map((product,key)=>{ 
                return(
            <section className="top_category_product mb-40 bg-grey" key={key}>
                <div className="container pt-20 pb-20 bg-grey">
                    <div className="row">
                        <div className="section_title"><h2 className='border-bottom'>{product.categoryName}</h2></div>
                        {product.data.map((getProduct,key2) => (
                        <div className="col-lg-3" key={key2}>
                            <article className="single_category">
                            <Link to={"/products-details/" + getProduct.id + "/" + getProduct.name}>
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src={ImageUrl()+productImagePath + getProduct.image} alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h3 className='product-name'><a href="#">{getProduct.name}</a></h3>
                                    </figcaption>
                                </figure>
                                </Link>
                                <div className={CartListDetails.some(item => item.id === getProduct.id) ? 'default_btn bg-red' : 'default_btn'}>
                                    {CartListDetails.some(item => item.id === getProduct.id) ? 
                      <a title="add to cart" tabIndex="0" onClick={() => removeFromCart(getProduct)}>Remove From Cart</a>
                      :
                      <a title="add to cart" tabIndex="0" onClick={() => addToCart(getProduct)}>Add To Cart</a>
                       }
                                </div>
                            </article>
                        </div>
                        ))}
                    </div>
                </div>
            </section>
            ) })
            : null}
            {showFooter && 
        <Footer /> }
        </>
    );
}
export default ProductsDetails