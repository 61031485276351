import React,{useEffect, useState} from 'react'
import Footer from '../../Layouts/footer';
import AddressPage from './addressPage';
import MyAccountMenu from './myaccountmenu';
const AddAddress = (props) => {    
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li><a href='#'>My Account</a></li>
                                    <li>Add Address</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                <div className="tab-content dashboard_content bordered profile-min-height padding-full-side40">
                                    <div className="tab-pane fade show active" id="profile">
                                        <div className="section_title border-none"><h2 className='border-bottom'>Add Address</h2></div>
                                        <AddressPage mobileCode={props.mobileCode} countryLists={props.countryLists} locationTypes={props.locationTypes} address={props.address}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default AddAddress;