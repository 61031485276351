import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { ImageUrl } from '../API/Url';

export default function ProductDetailsimgcarousel(props) {
    return (
        <div class="carousel-wrapper">
            <Carousel infiniteLoop autoPlay={false} showArrows={false} thumbnails={true} showIndicators={false}
            thumbWidth="130px">
                {props.moreImages.map((image)=>(
                <div>
                    <img src={ImageUrl()+"market/products/more_images/"+image.more_image} />
                </div>
                ))}
                {/* <div>
                    <img src="/assets/img/product/blue-car.jpg" />
                </div>
                <div>
                    <img src="/assets/img/product/tesla-front.jpg" />
                </div>
                <div>
                    <img src="/assets/img/product/teslamotorsmodels-medium.jpg" />
                </div>
                <div>
                    <img src="/assets/img/product/tyire.jpg" />
                </div> */}
            </Carousel>
        </div>
    );
}

