import React, { useState, useEffect } from 'react'
import { BrowserRouter, Link } from 'react-router-dom';
import MyAccountMenu from './myaccountmenu';
import { setCartData, setCartIdData, getCartData, getCartIdData, removeCartData, removeCartIdData, setWishlistData, setWishlistIdData, getWishlistData, getWishlistIdData, getCurrencyCountry } from '../../API/Storage';
import { useSelector, useDispatch } from "react-redux";
import { IsAuthenticated } from '../../API/CheckAuth'
import { AddWishListApi, WishlistDetailApi } from "../../API/WishlistApi";
import { UserDetails, cartDetails, wishlistDetails, wishlistCount, cartCount } from "../../Action/action";
import { ImageUrl } from '../../API/Url';
import { AddToCartApi, CartListApi } from '../../API/CartApi';
import { ConvertCurrency } from '../../API/currency';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { checkIndex, filterJson } from '../../API/UnAuthApi';
import { productDetails } from '../../API/ProductApi';
import Footer from '../../Layouts/footer';
import { useAddToCart, useRemoveFromCart } from '../../API/CartChanges';
import { useAddToWishlist, useRemoveFromWishlist } from '../../API/WishlistChanges';

const MyWishList = (props) => {
    const dispatch = useDispatch();
    const rate = useSelector((state) => state.CurrencyRateReducer);

    const cartDataLocalStorage                  = JSON.parse(getCartData());
    const cartDataIDLocalStorage                = JSON.parse(getCartIdData());
    const wishlistDataLocalStorage                  = JSON.parse(getWishlistData());
    const wishlistDataIdLocalStorage                = JSON.parse(getWishlistIdData());
    const cartDataReducer = useSelector((state) => state.CartReducer);
    const wishlistCountDataReducer = useSelector((state) => state.WishlistCountReducer);
    const [cart, setCart] = useState([]);
    const [cartId, setCartId] = useState([]);
    const [wishlistId, setWishlistId] = useState([]);
    const [wishlist, setwishlist] = useState([]);
    const [wislistPaginate, setWislistPaginate] = useState([])
    const [page, setPage] = useState(1);
    const CartListDetails = useSelector((state) => state.CartReducer);
    const WishlistDetailReducer = useSelector((state) => state.WishlistReducer);
    const [changeWishlist, setChangeWishlist] = useState(true)
    const [productImagePath, setProductImagePath] = useState()
    const profile = useSelector((state) => state.ProfileReducer);
    const [showFooter, setShowFooter] = useState(false)

    const [currency, setCurrency] = useState(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code)
    useEffect(()=>{
      setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
    });  
  useEffect(()=>{
    if(currency)
    {
        const data = WishlistDetailReducer.length > 0 ? WishlistDetailReducer.map(item => item.id) : [];
        productDetails(data,currency,page,"true").then((result) => {
            setwishlist(result.data.data.data)
            setWislistPaginate(result.data.data)
            if(result.data.data.data.length === 0)
            {
                setPage((prev)=>(prev === 0 ? 0 : prev-1));
            }
            setShowFooter(true);
            setProductImagePath('/market/products/images/')

        }).catch((err) => {
            setShowFooter(true);
            setwishlist([])
        });
    }
  },[currency,WishlistDetailReducer,page])


// Start Add to cart
const addToCart = useAddToCart();
const removeFromCart = useRemoveFromCart();

const AddWishlist = useAddToWishlist();
const RemoveWishlist = useRemoveFromWishlist();

function PaginationChange(e,value){
    setPage(value);
}
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li>Address</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                {wishlist.length > 0 ? 
                                <div className="tab-content dashboard_content bordered padding-full-side40">

                                    <div className="row">
                                        <div className="section_title border-none"><h2 className='border-bottom'>Wishlist &#40; {wishlist.length} &#41;</h2></div>
                                        {wishlist.map((wlist)=>(
                                        <div className="col-lg-3">
                                            <article className="single_category">
                                                <figure>
                                                    <div className="category_thumb">
                                                        <a ><img src={ImageUrl()+productImagePath+wlist.image} alt="" /></a>
                                                    </div>
                                                    <figcaption className="category_name">
                                                        <h2 className='wishlist-price text-left'>{ ConvertCurrency(wlist.price, 1, currency,rate) }</h2>
                                                        <h3 className='wishlist-product-name text-left'>{wlist.name}</h3>
                                                    </figcaption>
                                                </figure>
                                                <div className="wlishlist_links">
                                                    <ul>
                                                        <li className="wishlist" onClick={()=>RemoveWishlist(wlist)}><a className='wlishlist_links_active' title="Add to Wishlist"><i className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                               
                                               
                                                <div className={CartListDetails.some(item => item.id === wlist.id) ? 'wishlist_btn bg-red' : 'wishlist_btn'}>
                                                    { CartListDetails.some(item => item.id === wlist.id) ?
                                                    <a title="add to cart" tabIndex="0" onClick={() => removeFromCart(wlist)}>Remove From Cart</a>
                                                    :
                                                    <a title="add to cart" tabIndex="0" onClick={() => addToCart(wlist)}>Add To Cart</a>
                                                    }
                                                </div>
                                            </article>
                                        </div>
                                        ))}
                                        {/* <div className="col-lg-3">
                                            <article className="single_category">
                                                <figure>
                                                    <div className="category_thumb">
                                                        <a href="#"><img src="/assets/img/product/upto-offer/ev-charge-factroy.jpg" alt="" /></a>
                                                    </div>
                                                    <figcaption className="category_name">
                                                        <h2 className='wishlist-price text-left'>UGX 117,699</h2>
                                                        <h3 className='wishlist-product-name text-left'>Home Accessories</h3>
                                                    </figcaption>
                                                </figure>
                                                <div className="wlishlist_links">
                                                    <ul>
                                                        <li className="wishlist"><a className='wlishlist_links_active' href="#" title="Add to Wishlist"><i className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="wishlist_btn">
                                                    <a href="#" title="add to cart" tabIndex="0"><img src="/assets/img/icon/shopping-bag.png" /> Add to Cart</a>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-lg-3">
                                            <article className="single_category">
                                                <figure>
                                                    <div className="category_thumb">
                                                        <a href="#"><img src="/assets/img/product/upto-offer/wall-mounted.jpg" alt="" /></a>
                                                    </div>
                                                    <figcaption className="category_name">
                                                        <h2 className='wishlist-price text-left'>UGX 117,699</h2>
                                                        <h3 className='wishlist-product-name text-left'>Home Accessories</h3>
                                                    </figcaption>
                                                </figure>
                                                <div className="wlishlist_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="wishlist_btn">
                                                    <a href="#" title="add to cart" tabIndex="0"><img src="/assets/img/icon/shopping-bag.png" /> Add to Cart</a>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-lg-3">
                                            <article className="single_category">
                                                <figure>
                                                    <div className="category_thumb">
                                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                                    </div>
                                                    <figcaption className="category_name">
                                                        <h2 className='wishlist-price text-left'>UGX 117,699</h2>
                                                        <h3 className='wishlist-product-name text-left'>Home Accessories</h3>
                                                    </figcaption>
                                                </figure>
                                                <div className="wlishlist_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="wishlist_btn">
                                                    <a href="#" title="add to cart" tabIndex="0"><img src="/assets/img/icon/shopping-bag.png" /> Add to Cart</a>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-lg-3 mt-20">
                                            <article className="single_category">
                                                <figure>
                                                    <div className="category_thumb">
                                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                                    </div>
                                                    <figcaption className="category_name">
                                                        <h2 className='wishlist-price text-left'>UGX 117,699</h2>
                                                        <h3 className='wishlist-product-name text-left'>Home Accessories</h3>
                                                    </figcaption>
                                                </figure>
                                                <div className="wlishlist_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="wishlist_btn">
                                                    <a href="#" title="add to cart" tabIndex="0"><img src="/assets/img/icon/shopping-bag.png" /> Add to Cart</a>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-lg-3 mt-20">
                                            <article className="single_category">
                                                <figure>
                                                    <div className="category_thumb">
                                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                                    </div>
                                                    <figcaption className="category_name">
                                                        <h2 className='wishlist-price text-left'>UGX 117,699</h2>
                                                        <h3 className='wishlist-product-name text-left'>Home Accessories</h3>
                                                    </figcaption>
                                                </figure>
                                                <div className="wlishlist_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="wishlist_btn">
                                                    <a href="#" title="add to cart" tabIndex="0"><img src="/assets/img/icon/shopping-bag.png" /> Add to Cart</a>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-lg-3 mt-20">
                                            <article className="single_category">
                                                <figure>
                                                    <div className="category_thumb">
                                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                                    </div>
                                                    <figcaption className="category_name">
                                                        <h2 className='wishlist-price text-left'>UGX 117,699</h2>
                                                        <h3 className='wishlist-product-name text-left'>Home Accessories</h3>
                                                    </figcaption>
                                                </figure>
                                                <div className="wlishlist_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="wishlist_btn">
                                                    <a href="#" title="add to cart" tabIndex="0"><img src="/assets/img/icon/shopping-bag.png" /> Add to Cart</a>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-lg-3 mt-20">
                                            <article className="single_category">
                                                <figure>
                                                    <div className="category_thumb">
                                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                                    </div>
                                                    <figcaption className="category_name">
                                                        <h2 className='wishlist-price text-left'>UGX 117,699</h2>
                                                        <h3 className='wishlist-product-name text-left'>Home Accessories</h3>
                                                    </figcaption>

                                                </figure>
                                                <div className="wlishlist_links">
                                                    <ul>
                                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div className="wishlist_btn">
                                                    <a href="#" title="add to cart" tabIndex="0"><img src="/assets/img/icon/shopping-bag.png" /> Add to Cart</a>
                                                </div>
                                            </article>
                                        </div> */}
                                    </div>
                                    
                                    <div className="shop_toolbar t_bottom">
                                        <div className="pagination">
                                            {wislistPaginate.total_pages && wislistPaginate.total_pages > 0 ? 
                                            <Stack spacing={3}>
                                                <Pagination count={wislistPaginate.total_pages} onChange={ PaginationChange }  page={page}/>
                                            </Stack>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                                : <div className="tab-content dashboard_content bordered padding-full-side40"> You didn't add any items to your favorites </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showFooter && <Footer />}
        </>
    );
}
export default MyWishList;