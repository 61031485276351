import React, { useState, useEffect } from 'react'
import MyAccountMenu from './myaccountmenu';
import { useSelector, useDispatch } from "react-redux";
import { ProfileApi } from '../../API/User';
import { UpdateProfileApi } from '../../API/Auth';
import { UserDetails } from "../../Action/action";
import ReactCountryFlag from 'react-country-flag';
import Footer from '../../Layouts/footer';
import { useNavigate, useSearchParams } from 'react-router-dom';

const EditProfile = (props) => {
    // let mobileCode = props.mobileCode ? props.mobileCode : [];
    // const [mobileCode, setMobileCode] = useState(props.mobileCode)
    // useEffect(()=>{
    //     setTimeout(()=>{
    //         setMobileCode(props.mobileCode)
    //     },2000)
    // },[])
    const redirect = useNavigate();
    const dispatch = useDispatch();
    const profileData = useSelector((state) => state.ProfileReducer);
    const [profileEditData, setProfileEditData] = useState()
    const [fileName, setFileName] = useState("Choose Profile Picture")
    const [hideDob, setHideDob] = useState(false)
    // useEffect(()=>{
    //     setprofileEditData(profileData)
    // })
    function handleInput(e){
        if(e.target.name == "image")
        {
            convert(e)        
        }
        else
        {
            setProfileEditData((prev)=>({...prev,[e.target.name]:e.target.value}))        
        }
        // setProfileEditData({[e.target.name]:e.target.value})
    }
    function handleSubmit(e){
        UpdateProfileApi(profileEditData).then((result) => {
            if(result.status === 200)
            {
                dispatch(UserDetails(result.data.data))     
                redirect('/profile');
                 
            }
        }).catch((err) => {
            
        });
        e.preventDefault();        
    }
    let base64String = "";
    function convert(e) {
       
        var file = e.target.files[0];
        setFileName(file.name);
        var reader = new FileReader();
         
        reader.onload = function () {
            base64String = reader.result;
            setProfileEditData((prev)=>({...prev,image:base64String}))        
        }
        reader.readAsDataURL(file);
      }
    return (
        
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li><a href='#'>My Account</a></li>
                                    <li>Edit Profile</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {profileData && profileData != 0 ? 
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                <div className="tab-content dashboard_content bordered padding-full-side40">
                                    <div className="tab-pane fade show active" id="profile">
                                        <div className="section_title border-none"><h2 className='border-bottom'>Edit Profile</h2></div>
                                        <div className="card-body">
                                            <form onSubmit={(e)=>handleSubmit(e)}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="form-label">Change Profile Picture</div>
                                                            <div className="custom-file">
                                                                <input type="file" className="custom-file-input" name="image" onChange={(e)=>handleInput(e)}/>
                                                                <label className="custom-file-label">{fileName}</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-label">Full Name</label>
                                                            <input type="text" className="form-control" name="name" placeholder="Full Name"  onChange={ (e)=>handleInput(e) } value={ profileEditData ? profileEditData.name : profileData.name }/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-label">Gender</label>
                                                            <div className='gender-column'>
                                                                <label className={profileData ? profileData.gender === "M" ? "gen-btn gen-active" : "gen-btn" : "gen-btn"}><input type="radio" name='gender' value="M" checked={profileEditData ? profileEditData.gender ? profileEditData.gender === "M" ? "checked" :null :null : profileData ? profileData.gender === "M" ? "checked": null:null} onChange={ (e)=>handleInput(e) }/>Male</label>
                                                                <label className={profileData ? profileData.gender === "F" ? "gen-btn gen-active" : "gen-btn" : "gen-btn"}><input type="radio" name='gender' value="F" checked={profileEditData ? profileEditData.gender ? profileEditData.gender === "F" ? "checked" :null :null : profileData ? profileData.gender === "F" ? "checked": null:null} onChange={ (e)=>handleInput(e) }/>Female</label>
                                                                <label className={profileData ? profileData.gender === "O" ? "gen-btn gen-active" : "gen-btn" : "gen-btn"}><input type="radio" name='gender' value="O" checked={profileEditData ? profileEditData.gender ? profileEditData.gender === "O" ? "checked" :null :null : profileData ? profileData.gender === "O" ? "checked": null:null} onChange={ (e)=>handleInput(e) }/>Others</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-label">Email Id</label>
                                                            <input type="text" className="form-control" name="email" placeholder="test@gmail.com" value={profileEditData ? profileEditData.email : profileData.email } onChange={(e)=>handleInput(e)}/>
                                                        </div>
                                                        <div className='row'>
                                                        <div className="form-group col-md-3">
                                                            <label className="form-label">Phone Number</label>
                                                            <select className="form-control select2 custom-select" name="mcc_id" onChange={(e)=>handleInput(e)} >
                                                            {
                                                                props.mobileCode && props.mobileCode.length > 0 ? 
                                                                props.mobileCode.map((mcc) => (
                                                                    <option value={mcc.id} selected={profileData.mcc_id === mcc.id ? "selected" : null} key={mcc.id}><ReactCountryFlag countryCode={mcc.country_code}/>  +{mcc.code}</option>
                                                                ))
                                                                :null
                                                            }
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-md-9">
                                                            <label className="form-label">Phone Number</label>
                                                            <input type="text" className="form-control" name="mobile" placeholder="+256 987 456 1230" value={profileEditData ? profileEditData.mobile : profileData.mobile } onChange={(e)=>handleInput(e)}/>
                                                        </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-label">Date Of Birth</label>
                                                            <input type="date" className="form-control" name="dob" placeholder="21-12-1980" onChange={(e)=>handleInput(e)} required pattern="\d{4}-\d{2}-\d{2}" onBlur={()=>{setHideDob(true)}} value={hideDob ? profileEditData ?profileEditData.dob : null : profileData.dob}/>
                                                        </div>
                                                        <div className="form-group">
                                                            
                                                            <label className="form-label">Region</label>
                                                            <select name="country_id" id="" onChange={(e)=>handleInput(e)}className='form-control'>
                                                            {props.countryLists.map((countryList) => (
                                                                <option value={countryList.id} selected={countryList.id === profileData.country_id ? "selected" : null}>{countryList.name}</option>
                                                            ))}
                                                            </select>
                                                            {/* <input type="text" className="form-control" name="example-disabled-input" placeholder="India" onChange={(e)=>handleInput(e)}/> */}
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-success w-100">Save Changes</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            : null }
        <Footer />
        </>
    );
}
export default EditProfile;