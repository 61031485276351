

const CurrencyRateReducer = (state=1, action)=>{
    switch (action.type) {
        case "CurrencyRate":
            return state = action.data;
        default:
            return state;
    }
}
export default CurrencyRateReducer;