import axios, { Axios } from "axios";
import { EVMarketUrl} from "./Url";
import { getCurrencyCountry, getToken, removeToken } from "./Storage";
axios.defaults.baseURL = EVMarketUrl();

const HomePageUrl = '/home';
const TodayTrendingUrl = '/product/trending/today';
const NewArrivalUrl = '/product/arrivals/new';
const CategoryListUrl = '/category/list';
const ProductListUrl = '/product/list';
const TopDealsApiUrl = '/top/deal/product/list?'



export function HomePageApi(data="",currency,page=1,code='',limit=8){
const catid = data ? "catid="+data : "" ;
  return axios({
    method: "get",
    url: HomePageUrl+"?currency="+currency+"&"+catid+"&page="+page+"&code="+code+"&limit="+limit
  })
}
export function TrendingProductsApi(data="",currency, page=1, limit=5){
const catid = data ? "catid="+data : "" ;
  return axios({
    method:"get",
    url: TodayTrendingUrl+"?currency="+currency+"&"+catid+"&page="+page+"&limit="+limit
  })
}
export function NewArrivalsApi(data,currency,page=1,limit=5){
const catid = data ? "catid="+data : "" ;

  return axios({
    method:"get",
    url:NewArrivalUrl+"?currency="+currency+"&"+catid+"&page="+page+"&limit="+limit
  })
}
export function CategoryList(data,currency){
  return axios({
    method:"get",
    url:CategoryListUrl+"?currency"+currency
  })
}

export function ProductListApi(data,currency,page=1,limit=5){
const catid = data ? "catid="+data : "" ;
  return axios({
    method:"get",
    url:ProductListUrl+"?catid="+data+'&currency='+currency+'&'+catid+"&page="+page+"&limit="+limit
  })
}
export function TopDealsApi(data,currency){
  return axios({
    method:"get",
    url:TopDealsApiUrl+data+'&currency='+currency
  })
}
export function OtherApi(){
  return axios({
    method:"get",
    url:"https://ev.heptotechnologies.org/api/v1.0/common/core/modules/user"
  })
}