import React from "react";
import { Carousel } from 'react-responsive-carousel';
import { ImageUrl } from '../API/Url';
// class Gallery extends React.Component {
function MobGallery(props) {
    return (
        <div key={"imageSlider"}>
            <Carousel autoPlay interval="5000" transitionTime="2000" infiniteLoop showThumbs={false}>
               
                <div className="mob-slider1 text-left">
                    <p className="text-slide-top-p">Most Popular</p>
                        <h1>Electway EV Portable Quick Charger</h1>
                        <p className="text-slide-bottom-p">Festival Offer <span className="off-value">50%</span></p>
                    </div>
                    <div className="mob-slider2 text-left">
                    <p className="text-slide-top-p">Popular</p>
                        <h1>Electway EV Portable Quick Charger</h1>
                        <p className="text-slide-bottom-p">Festival Offer <span className="off-value">20%</span></p>
                    </div> 
            </Carousel>
        </div>
    )
    // };
}

export default MobGallery