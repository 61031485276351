import React from 'react';
import './modal.css';
export default function Modal({ title, content, close }) {
    return (
        <div className="sign-modal">
            <div className="sign-modal_container">
                <div className="sign-modal_close" onClick={() => close(false)}>&times;</div>
                <div className="sign-modal_title">{title}</div>
                <div className="sign-model_content">{content}</div>
                <div className="sign-modal_footer">
                    {/* <button className="popup-close-btn" onClick={() => close(false)}>close</button> */}
                </div>
            </div>
        </div>
    );

}