import React from "react";
import { Carousel } from 'react-responsive-carousel';
import { ImageUrl } from '../API/Url';
// class Gallery extends React.Component {
function Gallery(props) {
    let banners = props.topBanners
    let bannersImagePath = props.bannersImagePath;
    let data = [];
    banners.map((banner) => {
        if (banner.banner.length > 0) {
            data.push(banner.banner)
        }
    })
    // render() {
    return (
        <div key={"imageSlider"}>
            <Carousel autoPlay interval="5000" onSwipeMove axis="horizontal" transitionTime="2000" infiniteLoop="boolean" showThumbs={false}>
                {data.map((banner) => (
                    <div key={banner.id}>
                        <img src={ImageUrl() + bannersImagePath + "/" + banner} alt="" />
                    </div>
                ))
                }
                {/* <div>
                        <img src="/assets/img/slider/slider-4.png" alt="" />
                    </div>
                    <div>
                        <img src="/assets/img/slider/slider-4.png" alt="" />
                    </div> */}
            </Carousel>
        </div>
    )
    // };
}

export default Gallery