

const WishlistReducer = (state=0, action)=>{
    switch(action.type)
    {
        case "wishlist" :
            return state = action.data;
        default:
            return state;
    }
}
export default WishlistReducer;