import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
import { ImageUrl } from "../API/Url";


    
// const images = [
//   { url: "/assets/img/slider/slider-4.png" },
//   { url: "/assets/img/slider/slider-3.jpg" },
//   { url: "/assets/img/slider/slider-2.jpg" },
// ];

const SimpleGallery = (props) => {
  let banners = props.topBanners
    let bannersImagePath = props.bannersImagePath;
    let images = [];
    banners.map((banner) => {
        if (banner.banner.length > 0) {
          images.push(ImageUrl() + bannersImagePath + "/" + banner.banner)
        }
    })
  return (
    <div>
      <SimpleImageSlider
        width="100%"
        height={395}
        images={images}
        showBullets={true}
        showNavs={true}
        loop={true}
        slideDuration={1}
        autoPlay={true}
      />
    </div>
  );
}
export default SimpleGallery