import axios from "axios";

import { getToken, removeToken } from "./Storage";

const GetProductDetailsUrl = '/packaging/details';
const addPAckagingDetailsUrl = "add/packaging/details";
const AddOrderDetailsUrl = 'order/details';
const CheckPackageUrl = 'check/package/detail';
const GetOrderDetailsUrl = 'get/order/details';
export function GetPackagingDetailsApi(){
    return axios({
        method: "get",
        url: GetProductDetailsUrl,
    })
}
export function AddPackagingDetails(data){
    const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
  return axios.post(addPAckagingDetailsUrl,data,config);
}
export function AddOrderDetails(data,currency){
    const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
  return axios.post(AddOrderDetailsUrl+'?currency='+currency,data,config);
}
export function CheckPackageApi(data){
    const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
  return axios.post(CheckPackageUrl,data,config);
}
export function GetOrderDetails(data="",page,search="",currency,filter){
    const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
  return axios.post(GetOrderDetailsUrl+"?page="+page+"&currency="+currency+"&search="+search+"&filter="+filter,data,config);
}