import axios from "axios";
import {CommonBaseUrl,EVMarketUrl} from "./Url";
import { setToken, getToken } from "./Storage";
const commonBaseUrl = CommonBaseUrl();
const loginUrl = commonBaseUrl+'auth/login';
const signupUrl = commonBaseUrl+'auth/signup';
const sendOtpUrl = commonBaseUrl+'auth/resend-otp';
const verifyOtpUrl = commonBaseUrl+'auth/verify-otp';
const refreshTokenUrl = commonBaseUrl+'auth/refresh/token';
const UpdateProfileUrl = commonBaseUrl+'user/profile/update';
const forgotPasswordUrl = commonBaseUrl+'auth/forgot/password';
const FpVerifyOtpUrl = commonBaseUrl+'auth/forgot/password/verify-otp';
const resetPasswrodUrl = commonBaseUrl+'auth/reset/password';
const resendOtpUrl = commonBaseUrl+"auth/resend-otp"
export function LoginApi(data){
    return axios.post(loginUrl,data);
}
export function RegisterApi(data){
    return axios.post(signupUrl,data);
}
export function SendOtpApi(data,token){
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
    return axios.post(sendOtpUrl,data,config);
}
export function VerifyOtpApi(data,token){
    const config = {
      headers: { 'Authorization': `Bearer ${token}` }
  };
  return axios.post(verifyOtpUrl,data,config);
}
export function logOut(){
    
}
// export function CheckToken(err){
//     if(err.response.status === 401)
//         {
//             if(err.response.data.error_code == "token_expired" || err.response.data.error_code == "session_error")
//             {
//                 RefreshTokenApi();        
//             }
//         }
// }
export function RefreshTokenApi(){
    let token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}`,'Accept': 'application/json' }
    };
    return axios.post(refreshTokenUrl,"",config)
}
export function UpdateProfileApi(data){
    let token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}`,'Accept': 'application/json' }
    };
    return axios.post(UpdateProfileUrl,data,config)
}
export function ForgotPasswordApi(username,mcc_id=null){
    let data = {username:username}
    if(mcc_id)
    {
        data.mcc_id = mcc_id;
    }
    return axios.post(forgotPasswordUrl,data);
}
export function FpVerifyOtp(token,otp){
    const config = {
        headers: { 'Authorization': `Bearer ${token}`,'Accept': 'application/json' }
    };
    let data = {otp:otp};
    return axios.post(FpVerifyOtpUrl,data,config)
}
export function ResetPassword(token,password){
    let url = resetPasswrodUrl;
    const config = {
        headers: { 'Authorization': `Bearer ${token}`,'Accept': 'application/json' }
    };
    let data = {password:password};
    return axios.post(url,data,config)
}
export function ResendOtpApi(token,verify){
    let url = resendOtpUrl;
    const config = {
        headers: { 'Authorization': `Bearer ${token}`,'Accept': 'application/json' }
    };
    let data = {verify:verify};
    return axios.post(url,data,config)
}