import axios from "axios";
import { findDOMNode } from "react-dom";
import { IsAuthenticated } from "./CheckAuth";
import Cookies from 'universal-cookie';
import { getToken } from './Storage';


const productFindUrl = "product/find";
const addRecommendProductUrl = "add/recommend/product";
const RecommendProductListUrl = "recommend/product/list";
const SearchProductListUrl = "/search/product/list";
const SimilarProductListUrl = "/similar/product/list";
const UsersLikedProductListUrl = "/user/like/product/list";
const ViewProductListUrl = "/view/all";
const productDetailsUrl = "/get/product/detail";
export function ProductFindApi(data,currency){
    const pid = data ? "id="+data : "" ;

    return axios.get(productFindUrl+"?currency="+currency+"&"+pid);
}

export function AddRecommendProductApi(product){
    if(IsAuthenticated())
    {
        let token = getToken();
        let data = {product_id:product}
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };
        return axios.post(addRecommendProductUrl,data,config);
    }
    else
    {
        const cookies = new Cookies();
        let data = {product_id:product,cookie_id:cookies.get("uniqueId")}
        return axios.post(addRecommendProductUrl,data);
    }
}

export function RecommendProductListApi(currency){
    if(IsAuthenticated())
    {
        let token = getToken();
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };
        return axios.get(RecommendProductListUrl+"?currency="+currency,config);
    }
    else
    {
        const cookies = new Cookies();
        let data = "cookie_id="+cookies.get('uniqueId')
        return axios.get(RecommendProductListUrl+"?currency="+currency+"&"+data);
    }
}
export function SearchProductListApi(search,category,page,currency,filters="",sortBy=''){
    let filterProducts = "";
    if(filters.length > 0)
    {
        filterProducts = removeDuplicates(filters)
    }    
    if(sortBy == '')
    {
        sortBy = ''
    }
        let data = "search="+search+"&category_id="+category;
        return axios.get(SearchProductListUrl+"?currency="+currency+"&limit=20&page="+page+"&"+data+"&filters="+filterProducts+"&sort_by="+sortBy);
}
export function SimilarProductListApi(product_id,limit,currency){    
    let data = "product_id="+product_id+"&limit="+limit;
    return axios.get(SimilarProductListUrl+"?currency="+currency+"&"+data);
}
export function UsersLikedProductListApi(currency){    
    // let data = "product_id="+product_id+"&limit="+limit;
    return axios.get(UsersLikedProductListUrl+"?currency="+currency+"&");
}

export function removeDuplicates(arr) {
    return arr.filter((item, 
        index) => arr.indexOf(item) === index);
}
export function ViewProductListApi(view,category,page,currency,filters="",sortBy=''){
    let filterProducts = "";
    let data;
    if(filters.length > 0)
    {
        filterProducts = removeDuplicates(filters)
    } 
    if(sortBy == '')
    {
        sortBy = ''
    }   
    if(category === "all")
    {
        data = "view="+view;
    }
    else if(view === "category")
    {
        data = "catid="+category;
    }
    else
    {
        data = "view="+view+"&catid="+category;
    }

        return axios.get(ViewProductListUrl+"?currency="+currency+"&limit=20&page="+page+"&"+data+"&filters="+filterProducts+"&sort_by="+sortBy);
}
export function productDetails(products,currency,page=1,paginate="false"){
    return axios.get(productDetailsUrl+"?currency="+currency+"&limit=4&page="+page+"&products="+products+"&paginate="+paginate);
}