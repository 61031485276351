import React, { useState, useEffect, useRef } from 'react'
import { IsAuthenticated } from '../API/CheckAuth'
import { Navigate, useNavigate, useSearchParams, useLocation, Link, useParams } from 'react-router-dom';
import { ImageUrl } from '../API/Url';
import SimpleImageSlider from "react-simple-image-slider";
import { HomePageApi, TrendingProductsApi, NewArrivalsApi, ProductListApi, TopDealsApi, OtherApi } from '../API/HomeApi';
import { AddToCartApi, CartListApi } from '../API/CartApi';
import { setCartData, setCartIdData, getCartData, getCartIdData, removeCartData, removeCartIdData, getWishlistIdData, getWishlistData, setWishlistData, setWishlistIdData, setPathName, getCurrencyCountry } from '../API/Storage'
import Gallery from './imgslider';
import ServicesTab from './servicesgallery';
import { useSelector, useDispatch } from "react-redux";
import { UserDetails, cartDetails, wishlistDetails, wishlistCount, cartCount } from "../Action/action";
import { AddRecommendProductApi } from "../API/ProductApi";
import { AddWishListApi, WishlistDetailApi } from "../API/WishlistApi";
import { ConvertCurrency } from '../API/currency';
import { checkIndex, filterJson } from '../API/UnAuthApi';
import ProductsCarousel from './productscarousel';
import CategorywiseProductsCarousel from './categorywiseproductscarousel';
import Footer from '../Layouts/footer';
import MobGallery from './mobimgslider';
import SimpleGallery from './simpleimgslider';

const Home = (props) => {

  const redirect = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const cartDataLocalStorage = JSON.parse(getCartData());
  const cartDataIDLocalStorage = JSON.parse(getCartIdData());
  const cartDataReducer = useSelector((state) => state.CartReducer);
  const wishlistCountDataReducer = useSelector((state) => state.WishlistCountReducer);
  const CartListDetails = useSelector((state) => state.CartReducer);
  const WishlistDetailReducer = useSelector((state) => state.WishlistReducer);
  const [othersService, setOthersService] = useState([])
  // const params = new URLSearchParams(location.search)
  const params = new useParams();
  const flashDealsRef = useRef();
  const offerRef = useRef();
  const newArrivalRef = useRef();
  const [urlCatId, setUrlCatId] = useState();
  const [topDeals, setTopDeals] = useState([]);
  const [topBanners, setTopBanners] = useState([])
  const [popularProducts, setPopularProducts] = useState([]);
  const [flashDealsBanners, setFlashBanners] = useState([])
  const [flashDeals, setFlashDeals] = useState([]);
  const [offersBanner, setOffersBanner] = useState([]);
  const [offers, setOffers] = useState([]);
  const [bannerImagePath, setBannerImagePath] = useState('')
  const [productImagePath, setProductImagePath] = useState('')

  const [trendingTodayBanners, setTrendingTodayBanners] = useState([])
  const [trendingTodayProducts, setTrendingTodayProducts] = useState([]);
  const [newArrivalBanner, setNewArrivalBanner] = useState([])
  const [newArrivalProducts, setNewArrivalProducts] = useState([]);

  const [offersPage, setOffersPage] = useState(1)
  const [offersDetails, setOffersDetails] = useState([])
  const [trendingTodayPage, setTrendingTodayPage] = useState(1)
  const [trendingTodayDetails, setTrendingTodayDetails] = useState([])
  const [newArrivalsPage, setNewArrivalsPage] = useState(1)
  const [newArrivalsDetails, setNewArrivalsDetails] = useState([])
  const [popularPage, setPopularPage] = useState(1)
  const [popularDetails, setPopularDetails] = useState([])
  const [productPage, setProductPage] = useState(1)
  const [flashDealsPage, setFlashDealsPage] = useState(1)

  const [homeTitle, setHomeTitle] = useState()
  const [homeAllResponse, setHomeAllResponse] = useState()
  const [banners, setBanners] = useState()
  const [flashDealsVisible, setFlashDealsVisible] = useState(false);
  const [offersVisible, setOffersVisible] = useState(false);
  const [newArrivalVisable, setNewArrivalVisable] = useState(false);
  const [categoryCount, setCategoryCount] = useState(0);
  const [getCategoryId, setGetCategoryId] = useState([]);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [cartId, setCartId] = useState([]);
  const [showFooter, setShowFooter] = useState(false)


  const wishlistDataLocalStorage = JSON.parse(getWishlistData());
  const wishlistDataIdLocalStorage = JSON.parse(getWishlistIdData());
  const [wishlistId, setWishlistId] = useState([]);
  const [wishlist, setwishlist] = useState([]);
  const profile = useSelector((state) => state.ProfileReducer);
  const rate = useSelector((state) => state.CurrencyRateReducer);
  const [currency, setCurrency] = useState()
  const [didYouFind, setDidYouFind] = useState(true)

  useEffect(() => {
    setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
  });
  useEffect(() => {
    setPathName(window.location.pathname);
  }, [])

  useEffect(() => {
    let id;
    if (props.categoryListProps.length > 0) {
      props.categoryListProps.map((category) => {
        if (params.name) {
          if (category.name === params.name.replace(/-/g, " ")) {
            id = category.id;
          }
        }
      })
      if (urlCatId !== id) {
        setTrendingTodayBanners([]);
        setNewArrivalBanner([]);
        setNewArrivalProducts([]);
        setTrendingTodayProducts([]);
        setProducts([]);
        setCategoryCount(0);
        setGetCategoryId([]);
        setPopularProducts([]);
        setOffers([])
        setOffersPage(1)
        setTrendingTodayPage(1)
        setNewArrivalsPage(1)
        setPopularPage(1)
        setTopBanners([])
        setFlashBanners([])
        setFlashDeals([])
        setOffersBanner([])
        setHomeTitle("");
        setFlashDealsPage(1)
        setShowFooter(false);
        setTopDeals([]);
        setOthersService([]);
      }
      setUrlCatId(id);
    }
  })
  useEffect(() => {
    setTimeout(() => {
      if (!showFooter) {
        setShowFooter(true)
      }
    }, 3000);

  }, [showFooter])
  // start Home
  useEffect(() => {
    let flash_deal_array = [];
    if (currency) {
      let code = "";
      let page = "";
      if (homeTitle == 'popular') {
        code = 'popular';
        page = popularPage;
      }
      else if (homeTitle == 'offer') {
        code = 'offer';
        page = offersPage;
      }
      else if (homeTitle == 'flash') {
        code = 'flash';
        page = flashDealsPage;
      }
      else {
        code = '';
        page = 1;
      }
      HomePageApi(urlCatId, currency, page, code).then((response) => {
        if (response.data.status === "success") {
          if (code == 'popular') {
            setPopularProducts([...popularProducts, ...response.data.popular_products.data]);
            setPopularDetails(response.data.popular_products);
            setPopularPage(response.data.popular_products.current_page)
          }
          else if (code == 'offer') {
            setOffers([...offers, ...response.data.offers.data]);
            setOffersDetails(response.data.offers);
            setOffersPage(response.data.offers.current_page)
          }
          else if (code == 'flash') {
            if (response.data.flash_deals.data.length > 0) {
              setFlashDealsPage(response.data.flash_deals.current_page)
              const chunkSize = Math.ceil(response.data.flash_deals.data.length / 2);
              for (let i = 0; i < response.data.flash_deals.data.length; i += chunkSize) {
                flash_deal_array.push(response.data.flash_deals.data.slice(i, i + chunkSize));
              }
              let d1 = [...flashDeals[0], ...flash_deal_array[0]];
              let d2 = [...flashDeals[1], ...flash_deal_array[1]];

              setFlashDeals([d1, d2]);
            }
          }
          else {
            setTopBanners(response.data.top_banners);
            setPopularProducts(response.data.popular_products.data);
            setPopularDetails(response.data.popular_products);
            setPopularPage(response.data.popular_products.current_page)

            setFlashBanners(response.data.flash_banners)
            setOffersBanner(response.data.offerBanners)
            setOffers(response.data.offers.data);
            setOffersDetails(response.data.offers);
            setOffersPage(response.data.offers.current_page)
            const chunkSize = Math.ceil(response.data.flash_deals.data.length / 2);
            for (let i = 0; i < response.data.flash_deals.data.length; i += chunkSize) {
              flash_deal_array.push(response.data.flash_deals.data.slice(i, i + chunkSize));
            }
            setFlashDeals(flash_deal_array);
            setBannerImagePath(response.data.banner_image_location);
            setProductImagePath(response.data.product_image_location);
          }
        }
      }).catch((err) => {
        setTopBanners([]);
        setPopularProducts([]);
        setFlashBanners([])
        setFlashDeals([]);
        setOffersBanner([])
        setOffers([]);
      });
    }
  }, [urlCatId, currency, offersPage, popularPage, flashDealsPage])

  // end Home
  // Start Trending
  setTimeout(() => {
    if (flashDealsRef.current) {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        setFlashDealsVisible(entry.isIntersecting);
      })
      observer.observe(flashDealsRef.current);
    }
  }, 3000);

  useEffect(() => {
    if (trendingTodayProducts.length === 0) {
      if (document.documentElement.scrollTop > 2000) {
        setFlashDealsVisible(true);
      }
    }
  }, [])

  useEffect(() => {
    if (flashDealsVisible === true) {
      TrendingProductsApi(urlCatId, currency, trendingTodayPage).then((response) => {
        if (response.data.status == 'success') {
          setTrendingTodayBanners(response.data.trending_today_banners)
          setTrendingTodayProducts(response.data.trending_todays.data);
          setTrendingTodayDetails(response.data.trending_todays);
          setTrendingTodayPage(response.data.trending_todays.current_page)
        }
        else {
          setTrendingTodayBanners([]);
          setTrendingTodayProducts([]);
        }

      }).catch((err) => {
        console.log(err);
        setTrendingTodayProducts([])
      });
    }
    if (trendingTodayPage >= 2) {
      TrendingProductsApi(urlCatId, currency, trendingTodayPage).then((response) => {
        if (response.data.status == 'success') {
          setTrendingTodayBanners(response.data.trending_today_banners)
          setTrendingTodayProducts([...trendingTodayProducts, ...response.data.trending_todays.data]);
          setTrendingTodayDetails(response.data.trending_todays);
          setTrendingTodayPage(response.data.trending_todays.current_page)
        }
        else {
          setTrendingTodayBanners([]);
          setTrendingTodayProducts([]);
        }

      }).catch((err) => {
        console.log(err);
        setTrendingTodayProducts([])
      });
    }


  }, [flashDealsVisible, currency, trendingTodayPage]);
  // End Trending

  // Start New Arrivals
  useEffect(() => {
    if (offerRef.current) {
      const offerObserver = new IntersectionObserver((entries) => {
        const offerEntry = entries[0];
        setOffersVisible(offerEntry.isIntersecting);
      })
      offerObserver.observe(offerRef.current);
    }
  })


  useEffect(() => {
    if (newArrivalProducts.length === 0) {
      if (document.documentElement.scrollTop > 2000) {
        setOffersVisible(true);
      }
    }
  }, [])

  useEffect(() => {
    if (offersVisible === true) {
      NewArrivalsApi(urlCatId, currency, newArrivalsPage).then((response) => {
        if (response.data.status == "success") {
          setNewArrivalBanner(response.data.new_arrivals_banners)
          setNewArrivalProducts(response.data.new_arrivals.data);
          setTrendingTodayDetails(response.data.new_arrivals);
          setTrendingTodayPage(response.data.new_arrivals.current_page)
        }
        else {
          setNewArrivalBanner([])
          setNewArrivalProducts([]);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
    else if (newArrivalsPage >= 2) {
      NewArrivalsApi(urlCatId, currency, newArrivalsPage).then((response) => {
        if (response.data.status == "success") {
          setNewArrivalBanner(response.data.new_arrivals_banners)
          setNewArrivalProducts([...newArrivalProducts, ...response.data.new_arrivals.data]);
          setTrendingTodayDetails(response.data.new_arrivals);
          setTrendingTodayPage(response.data.new_arrivals.current_page)
        }
        else {
          setNewArrivalBanner([])
          setNewArrivalProducts([]);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
    if (offersVisible === true) {
      let p;
      if (urlCatId) {
        p = "catid=" + urlCatId;
      }
      else {
        p = "is_home=1";
      }
      TopDealsApi(p, currency).then((response) => {
        setTopDeals(response.data.data);
      }).catch((err) => {
        setTopDeals([]);
      })
      OtherApi().then((result) => {
        setOthersService(result.data.data);
      }).catch((err) => {

      })
    }

  }, [offersVisible, currency, newArrivalsPage]);
  // End New Arrivals
  // Start category wise product list
  function getProductsData(data = 0, length = 0, cc = 0) {
    let catid = data;
    ProductListApi(catid, currency).then((response) => {
      if (response.data.status == 'success') {
        products.push(response.data.products);
      }
    }).catch((err) => {

    }).finally(() => {
      if (length - 1 > cc) {
        setCategoryCount(categoryCount + 1);
      }
    });
  }
  useEffect(() => {
    if (newArrivalRef.current) {
      const newArrivalobserver = new IntersectionObserver((entries) => {
        const newArrivalEntry = entries[0];
        setNewArrivalVisable(newArrivalEntry.isIntersecting);

        let category_id;
        if (props.categoryListProps.length > 0) {
          if (urlCatId) {
            category_id = urlCatId;
          }
          else {
            category_id = props.categoryListProps[categoryCount].id;
          }
          let index = getCategoryId.indexOf(category_id);
          if (index === -1) {
            getProductsData(category_id, props.categoryListProps.length, categoryCount);
            getCategoryId.push(category_id);
          }
        }
      })
      newArrivalobserver.observe(newArrivalRef.current);
    }
  })
  useEffect(() => {
    setGetCategoryId([])
    setCategoryCount(0)
    setTrendingTodayBanners([])
    setTrendingTodayProducts([])
    setNewArrivalBanner([])
    setNewArrivalProducts([])
    setProducts([]);
    setPopularProducts([]);
    setOffers([])
    setOffersPage(1)
    setTrendingTodayPage(1)
    setNewArrivalsPage(1)
    setPopularPage(1)
    setTopBanners([])
    setFlashBanners([])
    setFlashDeals([])
    setOffersBanner([])
    setHomeTitle("");
    setFlashDealsPage(1)
    setShowFooter(false);
    setTopDeals([]);
    setOthersService([]);
  }, [currency])

  // Start all Banners
  let offerBanner;
  let flashDealsTopBanner;
  let flashDealsBottomBanner;
  let bannersImagePath;
  let i_url;
  // let trendingTodayBanners;
  let newArrivalsBanner;
  // if (banners) {
  //   bannersImagePath = homeAllResponse.banner_image_location;
  //   if (banners.flash_deal_banners) {
  //     let flash_deals_split = banners.flash_deal_banners.split(",");
  //     flashDealsTopBanner = flash_deals_split[0];
  //     flashDealsBottomBanner = flash_deals_split[1];
  //   }
  //   else {
  //     let flash_deals_split = [];
  //     flashDealsTopBanner = flash_deals_split;
  //     flashDealsBottomBanner = flash_deals_split;
  //   }
  //   offerBanner = banners.offer_banner;
  //   if (banners.trending_today_banners) {
  //     trendingTodayBanners = banners.trending_today_banners.split(",");
  //   }
  //   newArrivalsBanner = banners.new_arrivals_banner
  //   i_url = topBanners ? topBanners[0].path : "";
  // }
  // if (!trendingTodayBanners) {
  //   trendingTodayBanners = [];
  // }
  // End all Banners

  function addToCart(data) {
    if (IsAuthenticated()) {
      AddToCartApi(data, "", currency).then((response) => {
        if (response.data.status == "success") {
          dispatch(cartDetails(response.data.data));
        }
      }).catch((err) => {
        if (err.response.status === 422) {

        }
      });
    }
    else {
      setCartData(CartListDetails !== 0 ? [...CartListDetails, data] : [data]);
      dispatch(cartDetails(CartListDetails !== 0 ? [...CartListDetails, data] : [data]));
    }
  }
  function removeFromCart(data) {
    if (IsAuthenticated()) {
      AddToCartApi(data, "", currency).then((response) => {
        if (response.data.status == "success") {
          dispatch(cartDetails(response.data.data))
        }
      }).catch((err) => {
        if (err.response.status === 422) {
          dispatch(cartDetails([]))
        }
      });
    }
    else {
      let cartD = CartListDetails;
      let index = checkIndex(cartD, data.id);

      cartD.splice(index, 1)
      // dispatch(cartDetails(cartD.splice(index,1)));
      // setCartData( cartD );

      setCartData([...cartD]);
      dispatch(cartDetails([...cartD]));
    }
  }
  // End Add to cart
  // start Wishlist
  function AddWishlist(data) {

    if (IsAuthenticated()) {
      let wishlistProductIds = []
      AddWishListApi(data, "", "", currency).then((response) => {
        dispatch(wishlistDetails(response.data.data.data));
        response.data.product_ids.map((item) => {
          wishlistProductIds.push(item.product_id);
        })
        dispatch(wishlistCount(wishlistProductIds))
      }).catch((err) => {
        if (err.response.status === 422) {
          dispatch(wishlistCount(0))
        }

      });
    }
    else {
      let wishlistD = WishlistDetailReducer;
      let index = WishlistDetailReducer === 0 ? -1 : checkIndex(wishlistD, data.id);

      let wishlistProductIds = []

      if (index === -1) {
        setWishlistData(WishlistDetailReducer !== 0 ? [...WishlistDetailReducer, data] : [data]);
        dispatch(wishlistDetails(WishlistDetailReducer !== 0 ? [...WishlistDetailReducer, data] : [data]));
      }
      else {
        wishlistD.splice(index, 1);
        setWishlistData([...wishlistD]);
        dispatch(wishlistDetails([...wishlistD]));
      }
      JSON.parse(getWishlistData()).map((item) => {
        wishlistProductIds.push(item.id)
      })
      dispatch(wishlistCount(wishlistProductIds));
    }
  }
  return (
    <>
      {topBanners && topBanners.length > 0 ?
        <section className="mb-40">
         <div className='mobile-slider'> <MobGallery></MobGallery></div>
         <div className='desktop-slider'><SimpleGallery topBanners={topBanners} bannersImagePath={bannerImagePath}></SimpleGallery></div>
         {/* <div className='desktop-slider'><Gallery topBanners={topBanners} bannersImagePath={bannerImagePath}></Gallery></div>  */}
        </section>
        : null}
      {popularProducts && popularProducts.length > 0 ?
        <section className="product_area mb-40 mr-20 ml-20">
          <div className="container bg-grey pt-20 pb-20">
            <div className="row">
              <div className="col-12">
                <div className="section_title">
                  <h2 className='border-bottom'>Popular Products</h2>
                </div>
              </div>
            </div>
            <div className="banner_area">
              <div className="container mob-padding">
                <div className="row">
                  <ProductsCarousel products={popularProducts} productImagePath={productImagePath} changeProductState={setPopularProducts} setPage={setPopularPage} currentPage={popularPage} title='popular' setHomeTitle={setHomeTitle} currency={currency}></ProductsCarousel>
                  {/* {popularProducts.map((popularProduct) => {
                    return (
                      <div className="col-lg-3 col-md-3" key={popularProduct.id}>
                        <div className="single_banner mb-30">
                          <div className="banner_thumb"> <a href="#"><img src={ImageUrl() + productImagePath + "/" + popularProduct.popular_product_image} alt="" /></a> </div>
                        </div>
                      </div>
                    )
                  })} */}
                </div>
              </div>
            </div>
          </div>
        </section>
        : null
      }
      {flashDealsBanners.length > 0 ?
        <div className="banner_area mb-40 mr-20 ml-20">
          <div className="container">
            <div className="row">
              <div className="col-12" style={{ padding: "0" }}>
                <div className="single_banner">
                  <div className="banner_thumb">
                    <a>

                      {flashDealsBanners[0].position === 1 ?
                        <img src={ImageUrl() + bannerImagePath + "/" + flashDealsBanners[0].banner} alt="Flash Deal" />
                        :
                        <img src={ImageUrl() + bannerImagePath + "/" + flashDealsBanners[1].banner} alt="Flash Deal" />
                      }
                      {/* {flashDealsBanners.length > 0 ? 
                    flashDealsBanners[0] && flashDealsBanners[1] ?
                    flashDealsBanners[0].position === 1 || flashDealsBanners[1].position === 1? 
                    <img src={flashDealsBanners[0] ? ImageUrl() + bannerImagePath + "/" + flashDealsBanners[0].banner : ImageUrl() + bannerImagePath + "/" + flashDealsBanners[1].banner} alt="Flash Deal" />
                    : null
                    : null
                    : null } */}
                      {/* {flashDealsBanners[0].position === 1 ?
                        <img src={ flashDealsBanners[0] ? ImageUrl() + bannerImagePath + "/" +flashDealsBanners[0].banner : ImageUrl() + bannerImagePath+flashDealsBanners[1].banner} alt="Flash Deal" />
                        :  
                        <img src={ImageUrl() + bannerImagePath + "/" + flashDealsBanners[1] ? flashDealsBanners[1].banner : ImageUrl() + bannerImagePath+flashDealsBanners[1].banner} alt="Flash Deal" />
                      } */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null}
      {flashDeals.length > 0 ?
        <section className="product_area mb-40 mr-20 ml-20" ref={flashDealsRef}>
          <div className="container pt-20 pb-20 bg-grey">
            <div className="row">
              <div className='col-6'>
                <div className="section_title">
                  <h2 className='border-bottom'>Flash Deals</h2>
                </div>
              </div>
              {/*<div className='col-lg-3 col-md-3 col-sm-12'>
                <div className="section_title">
                   <h2>Ends In</h2> <span className='time-tag'>20:52:33</span> 
                </div>
              </div>*/}
              <div className='col-6 viewall-btn'>
                {/* <a href='#'>View All</a> */}
                <Link to={params.name ? '/view/all/flash_deal/' + params.name : "/view/all/flash_deal/all"}>View All</Link>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className='flash-deal mob-flash-img'>
                  {/* <a className="primary_img deal-fiximg" href="#"><img src='/assets/img/product/flash-deals/charger-station.png' /></a> */}
                  <a className="primary_img pri-img-1" href="#">
                    {flashDealsBanners[0].position === 2 ?
                      <img className='img-fluid' src={ImageUrl() + bannerImagePath + "/" + flashDealsBanners[0].banner} alt="Flash Deal" />
                      :
                      <img className='img-fluid' src={ImageUrl() + bannerImagePath + "/" + flashDealsBanners[1].banner} alt="Flash Deal" />
                    }
                    {/* {flashDealsBanners.length > 0 ? 
                    flashDealsBanners[0] && flashDealsBanners[1] ?
                    flashDealsBanners[0].position === 2 || flashDealsBanners[1].position === 2? 
                    <img src={flashDealsBanners[0] ? ImageUrl() + bannerImagePath + "/" + flashDealsBanners[0].banner : ImageUrl() + bannerImagePath + "/" + flashDealsBanners[1].banner} alt="Flash Deal" />
                    : null
                    : null
                    : null } */}

                  </a>

                  <div className="action_links">
                    <ul>
                      <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                      <li className="quick_button"><a href="#" data-bs-toggle="modal"
                        data-bs-target="#modal_box" title="quick view"> <span
                          className="ion-ios-search-strong"></span></a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-9 col-md-6 col-sm-12 mob-column">
                {flashDeals.map((flasDeal, key) => {
                  return (
                    <div className="row" key={key}>
                      <ProductsCarousel products={flasDeal} productImagePath={productImagePath} changeProductState={setFlashDeals} setPage={setFlashDealsPage} currentPage={flashDealsPage} title={'flash'} setHomeTitle={setHomeTitle} currency={currency}></ProductsCarousel>
                      {/* {flasDeal.map((item) => (
                        <div className='col-md-4' key={item.id}>
                          <article className="single_product">
                            <figure>
                              <div className="product_thumb">
                                <Link to={"/products-details/" + item.id + "/" + item.name}>
                                  <a className="primary_img pri-img-2" href=""><img
                                    src={ImageUrl() + productImagePath + "/" + item.image} alt="" /></a>

                                </Link>
                                <div className="action_links">
                                  <ul>
                                    <li className="wishlist" onClick={() => AddWishlist(item)}><a title="Add to Wishlist" className={wishlistCountDataReducer != 0 ? wishlistCountDataReducer.indexOf(item.id) !== -1 ? "wlishlist_links_active" : null : null}><i
                                      className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                    <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                      data-bs-target="#modal_box" title="quick view"> <span
                                        className="ion-ios-search-strong"></span></a></li>
                                  </ul>
                                </div>

                              </div>
                              <figcaption className="product_content">
                                <h3 className="product_name"><a href="#">{item.name}{item.id}</a></h3>
                                <p className='product-dis'>{item.mini_description}</p>
                                <div className="price_box">
                                  {/* <span className="old_price">{ConvertCurrency(item.price, 1, currency)}</span> *}
                                  <span className="current_price">{ConvertCurrency(item.price, 1, currency, rate)}</span>
                                </div>
                              </figcaption>
                              {CartListDetails !== 0 ?
                                <div className="add_to_cart">
                                  {filterJson(CartListDetails, item.id).length === 0 ?
                                    <a title="add to cart" tabIndex="0" onClick={() => addToCart(item)}>Add To Cart</a>
                                    :
                                    <a title="add to cart" tabIndex="0" onClick={() => removeFromCart(item)}>Remove From Cart</a>
                                  }
                                </div> :
                                <div className="add_to_cart">
                                  <a title="add to cart" tabIndex="0" onClick={() => addToCart(item)}>Add To Cart</a>
                                </div>}
                            </figure>
                          </article>
                        </div>
                      ))} */}

                    </div>
                  )
                })}

              </div>

            </div>
          </div>
        </section>
        : null}
      {offersBanner.length > 0 ?
        <div className="banner_area mb-40 bluewood-bg mr-20 ml-20">
          <div className="container">
            <div className="row">
              <div className="col-12" style={{ padding: "0" }}>
                <div className="single_banner">
                  <div className="banner_thumb">
                    <a href="#"><img src={ImageUrl() + bannerImagePath + "/" + offersBanner[0].banner} alt="Flash Deal" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null}

      {offers.length > 0 ?
        <section className="top_category_product mb-40 mr-20 ml-20" ref={offerRef}>
          <div className="container pt-20 pb-20 bg-grey">
            <div className="row">
              <div className='col-lg-6 col-md-6 col-sm-12'><div className="section_title"><h2 className='border-bottom'>Discounts</h2></div></div>
              <div className='col-lg-6 col-md-6 col-sm-12 text-right viewall-btn'>
                <Link to={params.name ? '/view/all/offer/' + params.name : "/view/all/offer/all"}>View All</Link>

                {/* <a href='#'>View All</a> */}
              </div>
              <ProductsCarousel products={offers} productImagePath={productImagePath} changeProductState={setOffers} setPage={setOffersPage} currentPage={offersPage} title={'offer'} setHomeTitle={setHomeTitle} currency={currency}></ProductsCarousel>
              {/* {offers.map((offer) => (
                <div className="col-md-3" key={offer.id}>

                  <article className="single_product">
                    <Link to={"/products-details/" + offer.id + "/" + offer.name}>
                      <figure>
                        <div className="product_thumb">
                          <div className="category_thumb products-img">
                            <a href="#"><img src={ImageUrl() + productImagePath + "/" + offer.image} alt="" /></a>
                          </div>
                          <div className="action_links">
                            <ul>
                              <li className="wishlist"><a title="Add to Wishlist" className="wlishlist_links_active"><i
                                className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                              <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                data-bs-target="#modal_box" title="quick view"> <span
                                  className="ion-ios-search-strong"></span></a></li>
                            </ul>
                          </div>
                          <figcaption className="category_name">
                            <h3 className='product-name'><a href="#">{offer.name}</a></h3>
                            <p className='product-dis'>{offer.mini_description}</p>
                            <span className="product_price">UGX 9000</span>

                          </figcaption>

                        </div>
                      </figure>
                    </Link>

                    {CartListDetails !== 0 ?
                      <div className="default_btn">
                        {filterJson(CartListDetails, offer.id).length === 0 ?
                          <a title="add to cart" tabIndex="0" onClick={() => addToCart(offer)}>Add To Cart</a>
                          :
                          <a title="add to cart" tabIndex="0" onClick={() => removeFromCart(offer)}>Remove From Cart</a>
                        }
                      </div> :
                      <div className="default_btn">
                        <a title="add to cart" tabIndex="0" onClick={() => addToCart(offer)}>Add To Cart</a>
                      </div>}
                  </article>
                </div>
              ))} */}

            </div>
          </div>
        </section>
        : null}
      {trendingTodayBanners.length > 0 ?
        <div className="banner_area banner_two mb-40 bluewood-bg mr-20 ml-20">
          <div className="container" key={"trendingToday"}>
            <div className="row">
              {trendingTodayBanners.map((ttp, key) => (
                <div className={key === 0 ? "col-lg-6 col-md-6 col-sm-12 mob-tren-left" : "col-lg-6 col-md-6 col-sm-12 mob-tren-right"}>
                  <div className="single_banner">
                    <div className="banner_thumb">
                      <a href="#"><img src={ImageUrl() + bannerImagePath + "/" + ttp.banner} alt="" /></a>
                    </div>
                    <figcaption className="category_name caption-text bg-white mt-0">Electway EV Portable <br />Quick Charger</figcaption>
                  </div>
                </div>
              ))}
              {/* <div className="col-lg-6 col-md-6 pr-0">
              <div className="single_banner">
                <div className="banner_thumb">
                  <a href="#"><img src="/assets/img/bg/trending-2.jpg" alt="" /></a>
                </div>
                <figcaption className="category_name caption-text bg-white mt-0">Electway EV Portable <br/>Quick Charger</figcaption>
              </div>
              </div> */}
            </div>
          </div>
        </div> : null}
      {trendingTodayProducts.length > 0 ?
        <section className="top_category_product mb-40 mr-20 ml-20">
          <div className="container pt-20 pb-20 bg-grey">
            <div className="row">
              <div className='col-lg-6 col-md-6 col-sm-12'><div className="section_title"><h2 className='border-bottom'>Trending Today</h2></div></div>
              <div className='col-lg-6 col-md-6 col-sm-12 text-right viewall-btn'>
                <Link to={params.name ? '/view/all/trending_today/' + params.name : "/view/all/trending_today/all"}>View All</Link>
              </div>
              <ProductsCarousel products={trendingTodayProducts} productImagePath={productImagePath} changeProductState={setTrendingTodayProducts} setPage={setTrendingTodayPage} currentPage={trendingTodayPage} currency={currency}></ProductsCarousel>
            </div>
          </div>
        </section>
        : null}
      {newArrivalProducts.length > 0 ?
        <div className="banner_area mb-40 mr-20 ml-20" ref={newArrivalRef}>
          <div className="container bg-grey">
            <div className="row">
              <div className="col-12 p-0">
                <div className="single_banner">
                  <div className="banner_thumb">
                    <a href="#"><img src={newArrivalBanner.length > 0 ? ImageUrl() + bannerImagePath + "/" + newArrivalBanner[0].banner : null} alt="Flash Deal" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null}
      {newArrivalProducts.length > 0 ?
        <section className="top_category_product mb-40 mr-20 ml-20" >
          <div className="container pt-20 pb-20 bg-grey">
            <div className="row">
              <div className='col-lg-6 col-md-6 col-sm-12'><div className="section_title"><h2 className='border-bottom'>New Arrivals</h2></div></div>
              <div className='col-lg-6 col-md-6 col-sm-12 text-right viewall-btn'>
                <Link to={params.name ? '/view/all/new_arrivals/' + params.name : "/view/all/new_arrivals/all"}>View All</Link>

                {/* <a href='#'>View All</a> */}
              </div>
              <ProductsCarousel products={newArrivalProducts} productImagePath={productImagePath} changeProductState={setNewArrivalsDetails} setPage={setNewArrivalsPage} currentPage={newArrivalsPage} currency={currency}></ProductsCarousel>
            </div>
          </div>
        </section>
        : null}
      {products.map((product, key) => {
        return (
          <div >
            {product.data.length > 0 ?

              <div className="banner_area mb-40 mr-20 ml-20" >
                <div className="container bg-grey">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="single_banner">
                        <div className="banner_thumb">
                          {product.category_banner.length > 0 ?
                            <a><img src={ImageUrl() + bannerImagePath + "/" + product.category_banner[0].banner} alt="Flash Deal" /></a>
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
            {product.data.length > 0 ?
              <section className="top_category_product mb-40 mr-20 ml-20">
                <div className="container pt-20 pb-20 bg-grey">
                  <div className="row">
                    <div className='col-lg-6 col-md-6 col-sm-12'><div className="section_title"><h2 className='border-bottom'>{product.category_name}</h2></div></div>
                    <div className='col-lg-6 col-md-6 col-sm-12 text-right viewall-btn'>
                      <Link to={'/view/all/category/' + product.category_name}>View All</Link>
                    </div>
                    <CategorywiseProductsCarousel products={product.data} productDetails={products} productImagePath={productImagePath} changeProductState={setProducts} setPage={setProductPage} currentPage={product.current_page} categoryId={product.category_id} currency={currency} index={key}></CategorywiseProductsCarousel>
                    {/* {product.data.map((getProduct) => (
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <article className="single_product">
                          <Link to={"/products-details/" + getProduct.id + "/" + getProduct.name}>
                            <figure>
                              <div className="product_thumb products-img">
                                <div className="category_thumb">
                                  <a href="#"><img src={ImageUrl() + productImagePath + "/" + getProduct.image} alt="" /></a>
                                </div>
                                <div className="action_links">
                                  <ul>
                                    <li className="wishlist"><a title="Add to Wishlist" className="wlishlist_links_active"><i
                                      className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                    <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                      data-bs-target="#modal_box" title="quick view"> <span
                                        className="ion-ios-search-strong"></span></a></li>
                                  </ul>
                                </div>
                                <figcaption className="category_name">
                                  <h3 className='product-name'><a href="#">{getProduct.name}</a></h3>
                                  <p className='product-dis'>Speaker for Car Horn, 12V-24V, Electrical Contact Point</p>
                                  <span className="product_price">UGX 9000</span>
                                </figcaption>
                              </div>
                            </figure>
                          </Link>
                          {CartListDetails !== 0 ?
                            <div className="default_btn">
                              {filterJson(CartListDetails, getProduct.id).length === 0 ?
                                <a title="add to cart" tabIndex="0" onClick={() => addToCart(getProduct)}>Add To Cart</a>
                                :
                                <a title="add to cart" tabIndex="0" onClick={() => removeFromCart(getProduct)}>Remove From Cart</a>
                              }
                            </div> :
                            <div className="default_btn">
                              <a title="add to cart" tabIndex="0" onClick={() => addToCart(getProduct)}>Add To Cart</a>
                            </div>}
                        </article>
                      </div>))} */}
                  </div>
                </div>
              </section>
              : null}
          </div>
        )
      })}
      {topDeals.length > 0 && topDeals.length <= 12 ?

        <section className="top_category_product mb-40 mr-20 ml-20 deal-mob-none">
          <div className="container pt-20 pb-20 bg-grey">
            <div className='deal-container'>
              <div className="row">
                <div className="section_title text-center"><h2 className='border-bottom'>Top Deal of This Week</h2></div>
              </div>
              <div className="deal-row">
                <div className="deal-01">
                  {topDeals[0].image1 ? <img src={ImageUrl() + productImagePath + "/" + topDeals[0].image1.banner} /> : null}
                </div>
                <div className="deal-02">
                  {topDeals[1].image2 ? <img className="deal-mb-20" src={ImageUrl() + productImagePath + "/" + topDeals[1].image2.banner} /> : null}
                  {topDeals[2].image3 ? <img src={ImageUrl() + productImagePath + "/" + topDeals[2].image3.banner} /> : null}
                </div>
                <div className="deal-04">
                  {topDeals[3].image4 ? <img className="deal-mb-20" src={ImageUrl() + productImagePath + "/" + topDeals[3].image4.banner} /> : null}
                  {topDeals[4].image5 ? <img className="float-left1 deal-mr-20" src={ImageUrl() + productImagePath + "/" + topDeals[4].image5.banner} /> : null}
                  {topDeals[5].image6 ? <img className="float-left1" src={ImageUrl() + productImagePath + "/" + topDeals[5].image6.banner} /> : null}</div>
              </div>
              <div className="deal-row deal-mt-20">
                <div className="deal-07 deal-mr-20">
                  {topDeals[7].image7 ? <img className="deal-mb-20" src={ImageUrl() + productImagePath + "/" + topDeals[7].image7.banner} /> : null}
                  {topDeals[6].image8 ? <img className="float-left-2 deal-mr-20" src={ImageUrl() + productImagePath + "/" + topDeals[6].image8.banner} /> : null}
                  {topDeals[8].image9 ? <img className="float-left-2" src={ImageUrl() + productImagePath + "/" + topDeals[8].image9.banner} /> : null}
                </div>
                <div className="deal-10 deal-mr-20">
                  {topDeals[9].image10 ? <img className="deal-mb-20" src={ImageUrl() + productImagePath + "/" + topDeals[9].image10.banner} /> : null}</div>
                <div className="deal-12">
                  {topDeals[10].image11 ? <img className="deal-mb-20" src={ImageUrl() + productImagePath + "/" + topDeals[10].image11.banner} /> : null}
                  {topDeals[11].image12 ? <img className="deal-mb-20" src={ImageUrl() + productImagePath + "/" + topDeals[11].image12.banner} /> : null}</div>
              </div>
            </div>
          </div>
        </section>
        : null}



      {/* <section className="top_category_product mb-40 mr-20 ml-20">
        <div className="container pt-20 pb-20 bg-grey">
          <div className="row">
            <div className="section_title text-center"><h2 className='border-bottom'>Top Deal of This Week</h2></div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className='row'>
                <div className="col-lg-6 col-md-6 col-sm-12">

                  <div className="deal_thumb">
                    <a href="#"><img src="/assets/img/product/deal-week/deal-01.png" alt="" /></a>
                  </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-lg-12 padding-calc-bottom">

                      <div className="deal_thumb">
                        <a href="#"><img src="/assets/img/product/deal-week/deal-02.png" alt="" /></a>
                      </div>

                    </div>
                    <div className="col-lg-12">

                      <div className="deal_thumb">
                        <a href="#"><img src="/assets/img/product/deal-week/deal-03.png" alt="" /></a>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className='row'>
                <div className="col-lg-12 padding-calc-bottom">

                  <div className="deal_thumb">
                    <a href="#"><img src="/assets/img/product/deal-week/deal-04.png" alt="" /></a>
                  </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">

                  <div className="deal_thumb">
                    <a href="#"><img src="/assets/img/product/deal-week/deal-05.png" alt="" /></a>
                  </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">

                  <div className="deal_thumb">
                    <a href="#"><img src="/assets/img/product/deal-week/deal-06.png" alt="" /></a>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* first part end *}
          <div className='row'>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className='row'>
                <div className="col-lg-12 padding-calc-bottom">

                  <div className="deal_thumb">
                    <a href="#"><img src="/assets/img/product/deal-week/deal-07.png" alt="" /></a>
                  </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">

                  <div className="deal_thumb">
                    <a href="#"><img src="/assets/img/product/deal-week/deal-08.png" alt="" /></a>
                  </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">

                  <div className="deal_thumb">
                    <a href="#"><img src="/assets/img/product/deal-week/deal-08.png" alt="" /></a>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className='row'>
                <div className="col-lg-6 col-md-6 col-sm-12">

                  <div className="deal_thumb">
                    <a href="#"><img src="/assets/img/product/deal-week/deal-10.png" alt="" /></a>
                  </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-lg-12 padding-calc-bottom">

                      <div className="deal_thumb">
                        <a href="#"><img src="/assets/img/product/deal-week/deal-11.png" alt="" /></a>
                      </div>

                    </div>
                    <div className="col-lg-12">

                      <div className="deal_thumb">
                        <a href="#"><img src="/assets/img/product/deal-week/deal-12.png" alt="" /></a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section> */}

      {othersService.length > 0 ?
        <div className="product_area mb-40 mr-20 ml-20">
          <div className="container bg-grey pt-20 pb-20">
            <div className="section_title align-center"><h2 className='border-bottom'>Other EV Zone Services</h2></div>
            <ServicesTab othersService={othersService}></ServicesTab>
          </div>
        </div>
        : null}
      {othersService.length > 0 ?
        <section className="top_category_product mb-40 mr-20 ml-20">
          <div className="container pt-20 pb-20 bg-grey">
            <div className="row">
              <div className="col-lg-12 align-center">
                <h4>Did you find what you <br />were looking for?</h4>
                <div className="default_btn-small">
                  <a className={!didYouFind ? 'btn-bg-none' : null} title="add to cart" tabIndex="0" onClick={() => { setDidYouFind(true) }}>Yes</a>
                  <a className={didYouFind ? 'btn-bg-none' : null} title="add to cart" tabIndex="0" onClick={() => { setDidYouFind(false) }}>No</a></div>
              </div>

            </div>
          </div>
        </section>
        : null}

      {!didYouFind ?
        <section className="top_category_product mb-40 mr-20 ml-20">
          <div className="container pt-20 pb-20 bg-grey">
            <div className="row">
              <div className="col-lg-12">
                <h4>What problems did you have search experience?</h4>
                <p><span className="custom-checkbox"><input type="checkbox" value="1" name="" /> Too many un related search results</span></p>
                <p><span className="custom-checkbox"><input type="checkbox" value="1" name="" /> I don't know how to filter or sort my search results</span></p>
                <p><span className="custom-checkbox"><input type="checkbox" value="1" name="" /> The product description did not match its title, picture, category, etc.</span></p>
                <p><span className="custom-checkbox"><input type="checkbox" value="1" name="" /> Can you add a feature?</span></p>
                <p><span className="custom-checkbox"><input type="checkbox" value="1" name="" /> Something is broken</span></p>
              </div>

            </div>
          </div>
        </section>
        : null}
      {!didYouFind ?
        <section className="top_category_product mb-40 mr-20 ml-20">
          <div className="container pt-20 pb-20 bg-grey">
            <div className="row">
              <div className="col-lg-12">
                <h4> Please explain your problems here</h4>
                <div className="home-textarea">
                  <textarea placeholder="Message *" name="message" className="form-control2"></textarea>
                </div>
                <div className='text-right'><button className='btn btn-success'>Submit</button></div>
              </div>

            </div>
          </div>
        </section>
        : null}
      {showFooter && <Footer />}
    </>
  );
}
export default Home;