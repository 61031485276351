

const CartReducer = (state=[], action)=>{
    switch(action.type)
    {
        case "cart" :
            return state = action.data;
        default:
            return state;
    }
}
export default CartReducer;