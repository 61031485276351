import React, { useEffect, useState } from 'react'
import './carousel.css'
import { HomePageApi, TrendingProductsApi, NewArrivalsApi, ProductListApi } from '../../API/HomeApi';

const Carousel = (props) => {
    const {children, show, changeProductState, setPage} = props

    const [currentIndex, setCurrentIndex] = useState(0)
    const [length, setLength] = useState(children.length)

    const [touchPosition, setTouchPosition] = useState(null)
    // Set the length to match current children from props
    useEffect(() => {
        setLength(children.length)
    }, [children])
    const next = () => {
        
        if(props.categoryId)
        {
            let old = (props.productDetails[props.index].data);
            let p = [];
            ProductListApi(props.categoryId, props.currency,props.currentPage+1).then((response) => {
                if (response.data.status == 'success') {
                    p = ([...old,...response.data.products.data])
                    props.productDetails[props.index].data = p;
                    props.productDetails[props.index].current_page = response.data.products.current_page
                    console.log(props.productDetails)
                    changeProductState([...props.productDetails]);
                }
              }).catch((err) => {
          
              })
        }
        else
        {
            if(props.currentPage)
            {
                setPage(parseInt(props.currentPage)+1);
            }
            if(props.title)
            { 
                props.setHomeTitle(props.title)
            }
        }
        if (currentIndex < (length - show)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }

    const prev = () => {
        // setPage(parseInt(props.currentPage)-1);
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition

        if(touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                {/* You can alwas change the content of the button to other things */}
                {
                    currentIndex > 0 &&
                    <button onClick={prev} className="left-arrow">
                        &lt;
                    </button>
                }
                <div
                    className="carousel-content-wrapper"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    <div
                        className={`carousel-content show-${show}`}
                        style={{ transform: `translateX(-${currentIndex * (100 / show)}%)` }}
                    >
                        {children}
                    </div>
                </div>
                {/* You can alwas change the content of the button to other things */}
                {
                    currentIndex < (length - show) &&
                    <button onClick={next} className="right-arrow">
                        &gt;
                    </button>
                }
            </div>
        </div>
    )
}

export default Carousel
