import React from 'react'
import { useAddToCart, useRemoveFromCart } from '../API/CartChanges';
import { ConvertCurrency } from '../API/currency';
import { ImageUrl } from '../API/Url';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";

const QuickViewPopup = (props) => {
  const rate = useSelector((state) => state.CurrencyRateReducer);
  const CartListDetails = useSelector((state) => state.CartReducer);
  const addToCart = useAddToCart();
  const removeFromCart = useRemoveFromCart();
  const handleClose = () => props.setShow(false);
  return (
    <Modal show={props.showModal} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.popProduct ?
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="modal_tab">
                    <div className="tab-content product-details-large">
                      <div className="tab-pane fade show active" id="tab1" role="tabpanel">
                        <div className="modal_tab_img">
                          <a href="#"><img src={ImageUrl() + props.productImagePath + "/" + props.popProduct.image} alt="" /></a>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tab2" role="tabpanel">
                        <div className="modal_tab_img">
                          <a href="#"><img src="assets/img/product/product2.jpg" alt="" /></a>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tab3" role="tabpanel">
                        <div className="modal_tab_img">
                          <a href="#"><img src="assets/img/product/product3.jpg" alt="" /></a>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tab4" role="tabpanel">
                        <div className="modal_tab_img">
                          <a href="#"><img src="assets/img/product/product5.jpg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="modal_tab_button">
                      <ul className="nav product_navactive owl-carousel" role="tablist">
                        <li>
                          <a className="nav-link active" data-bs-toggle="tab" href="#tab1" role="tab"
                            aria-controls="tab1" aria-selected="false"><img
                              src="assets/img/product/product1.jpg" alt="" /></a>
                        </li>
                        <li>
                          <a className="nav-link" data-bs-toggle="tab" href="#tab2" role="tab"
                            aria-controls="tab2" aria-selected="false"><img
                              src="assets/img/product/product2.jpg" alt="" /></a>
                        </li>
                        <li>
                          <a className="nav-link button_three" data-bs-toggle="tab" href="#tab3"
                            role="tab" aria-controls="tab3" aria-selected="false"><img
                              src="assets/img/product/product3.jpg" alt="" /></a>
                        </li>
                        <li>
                          <a className="nav-link" data-bs-toggle="tab" href="#tab4" role="tab"
                            aria-controls="tab4" aria-selected="false"><img
                              src="assets/img/product/product5.jpg" alt="" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="modal_right">
                    <div className="modal_title mb-10">

                      <h2> {props.popProduct.name}</h2>
                    </div>
                    <div className="modal_price mb-10">
                      <span className="new_price">{ConvertCurrency(props.popProduct.price, 1, props.currency, rate)}</span>
                      {/* <span className="old_price">$78.99</span> */}
                    </div>
                    <div className="modal_description mb-15">
                      <p> {props.popProduct.description} </p>
                    </div>
                    <div className="variants_selects">
                      <div className="modal_add_to_cart">
                        <form action="#">
                          <div className={CartListDetails.some(item => item.id === props.popProduct.id) ? 'pop-up-added_btn' : 'pop-up-add_btn'}>
                          {CartListDetails.some(item => item.id === props.popProduct.id) ?
                          <button type="button" onClick={() => removeFromCart(props.popProduct)}>Remove From Cart </button>
                          :
                          <button type="button" onClick={() => addToCart(props.popProduct)}>add to cart</button>
                          }
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="modal_social">
                      <h2>Share this product</h2>
                      <ul>
                        <li className="facebook"><a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent("ev-market-frontend.heptotechnologies.org/products-details/" + props.popProduct.id + "/" + props.popProduct.name)}`}><i className="fa fa-facebook"></i></a></li>
                        <li className="twitter"><a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent("ev-market-frontend.heptotechnologies.org/products-details/" + props.popProduct.id + "/" + props.popProduct.name)}`}><i className="fa fa-twitter"></i></a></li>
                        <li className="pinterest"><a href={`https://pinterest.com/pin/create/button/?url=${encodeURIComponent("ev-market-frontend.heptotechnologies.org/products-details/" + props.popProduct.id + "/" + props.popProduct.name)}`}><i className="fa fa-pinterest"></i></a></li>
                        <li className="google-plus"><a href={`https://plus.google.com/share?url=${encodeURIComponent("ev-market-frontend.heptotechnologies.org/products-details/" + props.popProduct.id + "/" + props.popProduct.name)}`}><i className="fa fa-google-plus"></i></a>
                        </li>
                        <li className="linkedin"><a href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent("ev-market-frontend.heptotechnologies.org/products-details/" + props.popProduct.id + "/" + props.popProduct.name)}`}><i className="fa fa-linkedin"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : null}
        </Modal.Body>
      </Modal>
  )
}

export default QuickViewPopup;
