import React from 'react'
import { BrowserRouter, Link } from 'react-router-dom';

const MyAccountMenu = () => {
    let urlForActive = window.location.pathname;
    return (
        <>
            <ul role="tablist" className="nav flex-column dashboard-list">
                <li  key="{profile}"><Link className={urlForActive == '/profile' ? "nav-link active" : "nav-link"} to={'/profile'}>Profile</Link></li>
                <li  key="{MyAddress}"><Link className={urlForActive == '/address' ? "nav-link active" : "nav-link"} to={'/address'}>Addresses</Link></li>
                <li  key="{Wallet}"><Link className={urlForActive == '/wallet' ? "nav-link active" : "nav-link"} to={'/wallet'}>Wallet</Link></li>
                <span className='profile-title'>Orders</span>
                <li  key="{MyOrders}"><Link className={urlForActive == '/orders' ? "nav-link active" : "nav-link"} to={'/orders'}>My Orders</Link></li>
                <li  key="{ReturnOrders}"><Link className={urlForActive == '/returnorders' ? "nav-link active" : "nav-link"} to={'/returnorders'}>Returns</Link></li>
                <span className='profile-title'>wishlist</span>
                <li  key="{MyWishList}"><Link className={urlForActive == '/wishlist' ? "nav-link active" : "nav-link"} to={'/wishlist'}>Wishlist</Link></li>
                {/* <span className='profile-title'>Credits</span>
                <li  key="{AvailableCoupons}"><Link className={urlForActive == '/availablecoupons' ? "nav-link active" : "nav-link"} to={'/availablecoupons'}>Coupons</Link></li>
                <li  key="{GiftCards}"><Link className={urlForActive == '/giftcards' ? "nav-link active" : "nav-link"} to={'/giftcards'}>Gift Cards</Link></li> */}
            </ul>
        </>
    );
}
export default MyAccountMenu;