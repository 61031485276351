import React from 'react'

const Footer = () => {
    return (

        <footer className="footer_widgets">
            <div className="footer_top">
                <div className="container">
                <div className='value-text pb-20 bottom-grey-border'>
                        <div className='value-text-content'>
                            <span className='footer-top-icon'><img src="/assets/img/icon/value-money.png" alt="" /></span>
                            <h4>Value for Money</h4>
                            <p>We offer the best prices for millions of great products to give you value for money</p>
                        </div>
                        <div className='value-text-content'>
                            <span className='footer-top-icon'><img src="/assets/img/icon/worldwide-shipping.png" alt="" /></span>
                            <h4>Worldwide Shipping</h4>
                            <p>We ship to over 150 countries & regions allover the world with multiple language options</p>
                        </div>
                        <div className='value-text-content'>
                            <span className='footer-top-icon'><img src="/assets/img/icon/flexible-payments.png" alt="" /></span>
                            <h4>Flexible Payments</h4>
                            <p>We enable secure payments with the most diverse and flexible payment methods</p>
                        </div>
                        <div className='value-text-content'>
                            <span className='footer-top-icon'><img src="/assets/img/icon/secure-shopping.png" alt="" /></span>
                            <h4>Secure Shopping</h4>
                            <p>Our buyer protection policy covers you entire purchase process</p>
                        </div>
                        <div className='value-text-content'>
                            <span className='footer-top-icon'><img src="/assets/img/icon/help-center.png" alt="" /></span>
                            <h4>24/7 Help Center</h4>
                            <p>24/7 customer support for a seamless shopping experence</p>
                        </div>
                    </div>
                    {/* <div className='row align-center pb-20 bottom-grey-border'>
                        
                        <div className='col-lg-2 col-md-3 col-sm-12'>
                            <span className='footer-top-icon'><img src="/assets/img/icon/value-money.png" alt="" /></span>
                            <h4>Value for Money</h4>
                            <p>We offer the best prices for millions of great products to give you value for money</p>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-12'>
                            <span className='footer-top-icon'><img src="/assets/img/icon/worldwide-shipping.png" alt="" /></span>
                            <h4>Worldwide Shipping</h4>
                            <p>We ship to over 150 countries & regions allover the world with multiple language options</p>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-12'>
                            <span className='footer-top-icon'><img src="/assets/img/icon/flexible-payments.png" alt="" /></span>
                            <h4>Flexible Payments</h4>
                            <p>We enable secure payments with the most diverse and flexible payment methods</p>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-12'>
                            <span className='footer-top-icon'><img src="/assets/img/icon/secure-shopping.png" alt="" /></span>
                            <h4>Secure Shopping</h4>
                            <p>Our buyer protection policy covers you entire purchase process</p>
                        </div>
                        <div className='col-lg-2 col-md-3 col-sm-12'>
                            <span className='footer-top-icon'><img src="/assets/img/icon/help-center.png" alt="" /></span>
                            <h4>24/7 Help Center</h4>
                            <p>24/7 customer support for a seamless shopping experence</p>
                        </div>
                        
                    </div> */}
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="widgets_container newsletter widget-top">
                                <h3>Connect with us</h3>
                                <div className="footer_social_link">
                                    <ul>
                                        <li><a className="facebook" href="#" title="Facebook"><i className="fa fa-facebook"></i></a>
                                        </li>
                                        <li><a className="twitter" href="#" title="Twitter"><i className="fa fa-twitter"></i></a>
                                        </li>
                                        <li><a className="instagram" href="#" title="instagram"><i
                                            className="fa fa-instagram"></i></a></li>
                                        <li><a className="linkedin" href="#" title="linkedin"><i className="fa fa-linkedin"></i></a>
                                        </li>
                                        <li><a className="rss" href="#" title="rss"><i className="fa fa-rss"></i></a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="widgets_container widget_menu">
                                <h3>Customer Support</h3>
                                <div className="footer_menu">
                                    <ul>
                                        <li><a href="#">Customer Service </a></li>
                                        <li><a href="#">Transaction Services Agreement for <br />non-EU/UK Transactions</a></li>
                                        <li><a href="#">Terms & Conditions for <br />EU/EEA/UK Transactions</a></li>
                                        <li><a href="#">Feedback Survey</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="widgets_container widget_menu">
                                <h3>Become a Seller</h3>
                                <div className="footer_menu">
                                    <ul>
                                        <li><a href="https://seller.evzone.app" target='blank'>Sell as a Business</a></li>
                                        <li><a href=" https://seller.evzone.app/ " target='blank'>Sell as an Individual</a></li>
                                        <li><a href="#" target='blank'>Seller Help Center</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom bg-green">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4">
                            <div className="copyright_area">
                                <p>Download the EV zone User App today</p>
                                <div className='store-icon'><a className='margin-right' href='#'><img src="/assets/img/icon/app-store.png" alt="" /></a><a href='#'><img src="/assets/img/icon/google-play.png" alt="" /></a></div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4"><p className="copyright-text"> Copyright &copy; 2023 EVzone.All Rights Reserved.</p></div>
                        <div className="col-lg-4 col-md-4">
                            <div className="footer_payment text-end">
                                Terms of Services Privacy Policy
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="switcher-wrapper">
                <div className="demo_changer">
                    <div className="demo-icon bg_dark"><i className="fa fa-cog fa-spin  text_primary"></i></div>
                    {/* <div className="form_holder right-sidebar">
                        <div className="row">
                            <div className="predefined_styles">
                                <div className="skin-theme-switcher">
                                    <div className='row border-white'>
                                        <div className='col-md-3'><a className='margin-right' href='#'><img src="/assets/img/icon/question-circle.png" alt="" /></a></div>
                                        <div className='col-md-9'><h3>Help Center</h3>
                                            <p>Guide to customer care</p>
                                        </div>
                                    </div>
                                    <div className='row' style={{marginTop:"20px"}}>
                                        <div className='col-md-3'><a className='margin-right' href='#'><img src="/assets/img/icon/shopping-cart.png" alt="" /></a></div>
                                        <div className='col-md-9'><h3>Return Product</h3>
                                            <p>Easy Return & Easy Refund</p>
                                        </div>
                                    </div>
                                    <div className="swichermainleft">
                                        <div className="switch_section">

                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </footer>
    );
}
export default Footer;