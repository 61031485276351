import ProfileReducer from "./ProfileReducer";
import CartReducer from "./CartReducer";
import WishlistReducer from "./WishlistReducer";

import { createStore, combineReducers } from 'redux';
import wishlistCountReducer from "./WishlistCountReducer";
import CartCountReducer from "./CartCountReducer";
import CurrencyRateReducer from "./CurrencyRateReducer";

const store = combineReducers({
    ProfileReducer:ProfileReducer,
    CartReducer:CartReducer,
    WishlistReducer:WishlistReducer,
    WishlistCountReducer:wishlistCountReducer,
    CartCountReducer:CartCountReducer,
    CurrencyRateReducer:CurrencyRateReducer
})
export default store;