import React, { useState, useEffect } from 'react'
import { BrowserRouter, Link } from 'react-router-dom';
import Footer from '../../Layouts/footer';
import MyAccountMenu from './myaccountmenu';


const WithdrawFunds = (props) => {
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li><a href="#">My Account</a></li>
                                    <li><a href="#">Wallet</a></li>
                                    <li>Withdraw Funds</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                <div className="tab-content dashboard_content bordered padding-full-side40">
                                    <div className="section_title border-none"><h2 className='border-bottom'>Withdraw Funds</h2></div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="card bg-orange img-card box-primary-shadow">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="text-white text-center" style={{ width: "100%" }}>
                                                            <p className="wallet-p text-white mb-0">Avilable Balance</p>
                                                            <h2 className="wallet-h mb-0 number-font">UGX 23,536</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="card bg-green img-card box-success-shadow">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="text-white text-center" style={{ width: "100%" }}>
                                                            <p className="wallet-p text-white mb-0">Wallet Balance</p>
                                                            <h2 className="wallet-h mb-0 number-font">UGX 45,789</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row mt-20'>
                                        <div className='col-lg-8 col-md-8 col-sm-12'>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="search_container fund-container">
                                                    <form>
                                                        <div className="search_box"><input className='fundinput' placeholder="10,000" type="text" name="" /></div>
                                                        <div className="hover_category">
                                                            <select name="category" id="" className="nice-select fund">
                                                                <option value="all">UGX</option>
                                                                <option>IND</option>
                                                            </select></div>
                                                    </form></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-12 fund-btn'>
                                            <button>20k</button> <button>50k</button> <button>100k</button>
                                        </div>
                                    </div>
                                    <div className="row mt-40">
                                        <div className="section_title border-none"><h2 className='border-bottom'>Trasnfer Funds to</h2></div>
                                        <div className='col-lg-12 fund-choose'>
                                            <div className='card orange-border'>
                                                <div className='card-box p-10'>
                                                    <ul className='fundli'>
                                                        <li>Mobile Money</li>
                                                        <li>Debit/Credit Card</li>
                                                        <li>Bank Transfer</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='card orange-border'>
                                                <div className='card-box p-10'>
                                                    <a href=''><img src='/assets/img/icon/gpay.png' alt='' /></a>
                                                </div>
                                            </div>
                                            <div className='card orange-border'>
                                                <div className='card-box p-10'>
                                                    <a href=''><img src='/assets/img/icon/apple-pay.png' alt='' /></a>
                                                </div>
                                            </div>
                                            <div className='card orange-border'>
                                                <div className='card-box p-10'>
                                                    <a href=''><img src='/assets/img/icon/paypal.png' alt='' /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className='row mt-60'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="section_title border-none"><h2 className='border-bottom'>Withdraw Transaction Details</h2></div>
                                            <div className='table-responsive'>
                                                <table className='table card-table table-vcenter'>
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Transaction Type</th>
                                                            <th>Name</th>
                                                            <th>Transaction source</th>
                                                            <th>Transaction ID</th>
                                                            <th>Transaction Token</th>
                                                            <th>Balance to Date</th>
                                                            <th>Amount</th>
                                                            <th>Transaction Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>21-12-2022</td>
                                                            <td>Withdraw</td>
                                                            <td>Test</td>
                                                            <td>To Mobile Money/Bank/wallet</td>
                                                            <td>5000</td>
                                                            <td>Test</td>
                                                            <td>Test</td>
                                                            <td>Test</td>
                                                            <td>Successful</td>
                                                        </tr>
                                                        <tr>
                                                            <td>21-12-2022</td>
                                                            <td>Withdraw</td>
                                                            <td>Test</td>
                                                            <td>To Mobile Money/Bank/wallet</td>
                                                            <td>5000</td>
                                                            <td>Test</td>
                                                            <td>Test</td>
                                                            <td>Test</td>
                                                            <td>Successful</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12 col-md-12 col-sm-12 export">
                                            <a href='#' className='img-w100'><img className='mr-5' src="/assets/img/icon/folder-export.png" alert="" /> Export</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default WithdrawFunds;