import React from 'react'
import { BrowserRouter, Link } from 'react-router-dom';

const CheckOutMenu = () => {

    return (
        <>
            <ul className="tab_list">
                <li className="active">Final Country</li>
                <li>Shipment Route</li>
                <li>Packaging Method</li>
                <li>Destination Port</li>
                <li>Tax Clearance Office</li>
                <li>Address</li>
            </ul>
        </>
    );
}
export default CheckOutMenu;
