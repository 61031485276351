import React, { useState, useEffect } from 'react'
import { BrowserRouter, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Footer from '../../Layouts/footer';
import MyAccountMenu from './myaccountmenu';

const Wallet = (props) => {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState("All");

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };
    const depositfunds = () => {
        navigate('/depositfunds');
    }
    const withdrawlfunds = () => {
        navigate('/withdrawlfunds');
    }
    const walletfunds = () => {
        navigate('/incomeingpage');
    }
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li><a href="#">My Account</a></li>
                                    <li>Wallet</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-9">

                                <div className="text-center mt-3">
                                    <h4>Wallet</h4>
                                    <p style={{ fontWeight: '400', fontSize: '18px' }}>Manage your Wallet details</p>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="box p-4 border " style={{ backgroundColor: 'white' }}>
                                                <div className="d-flex flex-column ps-4">
                                                    <h5 className="text-start fw-800 mt-2">Wallet Balance</h5>
                                                    <div className="d-flex mt-2">
                                                        <img className="me-md-3 mb-3 mb-md-0" src='/assets/img/icon/actual_balance-01.png' alt="actual balance" width="60px" height="60px" />
                                                        <div className="d-flex ps-3 flex-column ml-md-2">
                                                            <p style={{ color: 'grey', fontSize: '16px' }}>Actual balance</p>
                                                            <p style={{ color: 'rgb(20, 212, 148)', fontSize: '20px', fontWeight: 'bold' }}>UGX 37, 420</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="d-flex flex-column ps-4">
                                                    <div className="d-flex mt-2">
                                                        <img className="me-md-3 mb-3 mb-md-0" src='/assets/img/icon/wallet_balance-01.png' alt="actual balance" width="60px" height="60px" />
                                                        <div className="d-flex ps-3 flex-column ml-md-2">
                                                            <p style={{ color: 'grey', fontSize: '16px' }}>Wallet balance</p>
                                                            <p style={{ color: '#F77F00', fontSize: '20px', fontWeight: 'bold' }}>UGX 12, 000</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                            <div className="box p-4 border mt-5" style={{ backgroundColor: 'white' }}>
                                                <div className="d-flex flex-column">
                                                    <h5 className="text-start fw-800 mt-2">Funds</h5>
                                                    <div className="d-flex flex-column mt-2">
                                                        <button onClick={depositfunds} className="btn deposits d-flex align-items-center justify-content-center">
                                                            <div className="d-flex align-items-center">
                                                                <img src='/assets/img/icon/withdraw_funds-01.png' className="me-2" alt="deposits" width="40px" height="auto" />
                                                                <span >Deposit Funds</span>
                                                            </div>
                                                        </button>
                                                        <button onClick={withdrawlfunds} className="btn withdrawls mt-3 d-flex align-items-center justify-content-center">
                                                            <div className="d-flex align-items-center">
                                                                <img src='/assets/img/icon/withdraw_funds-01.png' className="me-2" alt="withdrawls" width="40px" height="auto" />
                                                                <span>Withdrawal Funds</span>
                                                            </div>
                                                        </button>
                                                        <button onClick={walletfunds} className="btn wallettowallet mt-3 d-flex align-items-center justify-content-center">
                                                            <div className="d-flex align-items-center">
                                                                <img src='/assets/img/icon/wallet_to_wallet_transfer-01.png' className="me-2" alt="wallettowallet" width="40px" height="auto" />
                                                                <span>Wallet to Wallet Transfer</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="box p-3 border" style={{ backgroundColor: 'white' }}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="text-start">Payment History</h5>
                                                    <i className="fa fa-ellipsis-v text-end"></i>
                                                </div>
                                                <div className="payment-history-container overflow-auto" style={{ maxHeight: "100px" }}>
                                                    <ul className="list-unstyled d-flex flex-nowrap mx-2">
                                                        <li
                                                            className={`me-3 flex-grow-1 ${selectedItem === "All" ? "selected-item" : ""}`}
                                                            onClick={() => handleItemClick("All")}
                                                        >
                                                            All
                                                        </li>
                                                        <li
                                                            className={`me-3 flex-grow-1 ${selectedItem === "Deposit" ? "selected-item" : ""}`}
                                                            onClick={() => handleItemClick("Deposit")}
                                                        >
                                                            Deposit
                                                        </li>
                                                        <li
                                                            className={`me-3 flex-grow-1 ${selectedItem === "Withdrawal" ? "selected-item" : ""}`}
                                                            onClick={() => handleItemClick("Withdrawal")}
                                                        >
                                                            Withdrawal
                                                        </li>
                                                        <li
                                                            className={`me-3 flex-grow-1 ${selectedItem === "Wallet to Wallet" ? "selected-item" : ""}`}
                                                            onClick={() => handleItemClick("Wallet to Wallet")}
                                                        >
                                                            Wallet
                                                        </li>
                                                        <li
                                                            className={`me-3 flex-grow-1 ${selectedItem === "Payouts" ? "selected-item" : ""}`}
                                                            onClick={() => handleItemClick("Payouts")}
                                                        >
                                                            Payouts
                                                        </li>
                                                        <li
                                                            className={`flex-grow-1 ${selectedItem === "Collections" ? "selected-item" : ""}`}
                                                            onClick={() => handleItemClick("Collections")}
                                                        >
                                                            Collections
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="d-flex mt-4 align-items-center">
                                                    <img className="me-md-3 mb-3 mb-md-0" src='/assets/img/icon/deposit-01.png' alt="wallet" width="60px" height="60px"
                                                    />
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ fontSize: '16px', color: 'black', fontWeight: '700' }}>Deposit</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>Bank transfer</p>
                                                    </div>
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ fontSize: '16px', color: 'black', fontWeight: '700' }}>UGX 3000</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>20.05.2023</p>
                                                    </div>
                                                </div>
                                                <hr style={{ marginLeft: '26%' }}></hr>
                                                <div className="d-flex mt-4 align-items-center">
                                                    <img className="me-md-3 mb-3 mb-md-0" src='/assets/img/icon/withdrawl-01.png' alt="wallet" width="60px" height="60px"
                                                    />
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ fontSize: '16px', color: 'black', fontWeight: '700' }}>Withdrawal</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>Bank transfer</p>
                                                    </div>
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>UGX 3000</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>20.05.2023</p>
                                                    </div>
                                                </div>
                                                <hr style={{ marginLeft: '26%' }}></hr>
                                                <div className="d-flex mt-4 align-items-center">
                                                    <img
                                                        className="me-md-3 mb-3 mb-md-0"
                                                        src='/assets/img/icon/wallet_to_wallet-01.png'  // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="60px"
                                                        height="60px"
                                                    />
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>Wallet to Wallet</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>Bank transfer</p>
                                                    </div>
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>UGX 3000</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>20.05.2023</p>
                                                    </div>
                                                </div>
                                                <hr style={{ marginLeft: '26%' }}></hr>
                                                <div className="d-flex mt-4 align-items-center">
                                                    <img
                                                        className="me-md-3 mb-3 mb-md-0"
                                                        src='/assets/img/icon/deposit-01.png'  // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="60px"
                                                        height="60px"
                                                    />
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>Deposit</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>Bank transfer</p>
                                                    </div>
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>UGX 3000</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>20.05.2023</p>
                                                    </div>
                                                </div>
                                                <hr style={{ marginLeft: '26%' }}></hr>
                                                <div className="d-flex mt-4 align-items-center">
                                                    <img
                                                        className="me-md-3 mb-3 mb-md-0"
                                                        src='/assets/img/icon/withdrawl-01.png'  // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="60px"
                                                        height="60px"
                                                    />
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>Withdrawal</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>Bank transfer</p>
                                                    </div>
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>UGX 3000</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>20.05.2023</p>
                                                    </div>
                                                </div>
                                                <hr style={{ marginLeft: '26%' }}></hr>
                                                <div className="d-flex mt-4 align-items-center">
                                                    <img
                                                        className="me-md-3 mb-3 mb-md-0"
                                                        src='/assets/img/icon/deposit-01.png'  // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="60px"
                                                        height="60px"
                                                    />
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>Wallet to Wallet</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>Bank transfer</p>
                                                    </div>
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>UGX 3000</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>20.05.2023</p>
                                                    </div>
                                                </div>
                                                <hr style={{ marginLeft: '26%' }}></hr>
                                                <div className="d-flex mt-4 align-items-center">
                                                    <img
                                                        className="me-md-3 mb-3 mb-md-0"
                                                        src='/assets/img/icon/deposit-01.png'  // Replace 'yourImageSourceHere' with the actual image source URL
                                                        alt="wallet"
                                                        width="60px"
                                                        height="60px"
                                                    />
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>Wallet to Wallet</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>Bank transfer</p>
                                                    </div>
                                                    <div className="d-flex flex-column flex-fill ml-md-2">
                                                        <p style={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>UGX 3000</p>
                                                        <p style={{ color: 'grey', fontSize: '16px', fontWeight: '400' }}>20.05.2023</p>
                                                    </div>
                                                </div>
                                                <hr style={{ marginLeft: '26%' }}></hr>
                                                <div className="text-end">
                                                    <button className="btn btn-warning export-pdf"><img className="me-2" src='/assets/img/icon/export-01.png' alt="export" width='17px' height='auto'></img>Export to PDF</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default Wallet;