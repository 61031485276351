import React, { useState, useEffect } from 'react'
import Carousel from "../components/Carousel/Carousel"
import { ImageUrl } from '../API/Url';
import { Navigate, useNavigate, useSearchParams, useLocation, Link, useParams } from 'react-router-dom';
import { ConvertCurrency } from '../API/currency';
import { useSelector, useDispatch } from "react-redux";
import { IsAuthenticated } from '../API/CheckAuth'
import { AddToCartApi, CartListApi } from '../API/CartApi';
import { setCartData, setCartIdData, getCartData, getCartIdData, removeCartData, removeCartIdData, getWishlistIdData, getWishlistData, setWishlistData, setWishlistIdData, setPathName, getCurrencyCountry } from '../API/Storage'
import { UserDetails, cartDetails, wishlistDetails, wishlistCount, cartCount } from "../Action/action";
import { checkIndex, filterJson } from '../API/UnAuthApi';
import { AddWishListApi, WishlistDetailApi } from "../API/WishlistApi";
import { Modal, Button } from 'react-bootstrap';
import { useAddToCart, useRemoveFromCart } from '../API/CartChanges';
import QuickViewPopup from './quickViewPopup';
import { useAddToWishlist, useRemoveFromWishlist } from '../API/WishlistChanges';
const ProductsCarousel = (props) => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [popCartDetails, setPopCartDetails] = useState();
  const [popProduct, setPopProduct] = useState();

  const dispatch = useDispatch();

  const profile = useSelector((state) => state.ProfileReducer);
  const CartListDetails = useSelector((state) => state.CartReducer);
  const WishlistDetailReducer = useSelector((state) => state.WishlistReducer);
  const [currency, setCurrency] = useState()
  const rate = useSelector((state) => state.CurrencyRateReducer);
  function QuickView(product) {
    setShow(true)
    setPopCartDetails(CartListDetails);
    setPopProduct(product)
  }
  useEffect(() => {
    setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
  });
  const addToCart = useAddToCart();
  const removeFromCart = useRemoveFromCart();

  const AddWishlist = useAddToWishlist();
  const RemoveWishlist = useRemoveFromWishlist();
  const isMobile = window.innerWidth <= 768;
  const isTab = window.innerWidth <= 1024;
  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', }}>
      {props.products.length > 0 ?

        <Carousel
        
        show={
          isMobile && props.title === 'popular' ? 2 : isMobile ? 1 : isTab ? 2 : props.title === 'flash' ? 3 : 4
        }
          products={props.products} changeProductState={props.changeProductState} setPage={props.setPage} currentPage={props.currentPage} title={props.title} setHomeTitle={props.setHomeTitle}>
          {props.products.map((product) => (
            <div className='popular_productmob'>
              <div className={props.title !== "popular" ? "product_main mob-padding-none" : "popular_product_main"}>
                <article className={props.title !== "popular" ? "single_product" : "popular_single_product"}>

                  <figure>
                    <div className={props.title !== "popular" ? "product_thumb product-img-width" : "product_thumb popular_products"}>
                      <Link to={"/products-details/" + product.id + "/" + product.name}><a className={props.title === 'flash' ? "product-img-width primary_img pri-img-2" : 'primary_img'}><img
                        src={ImageUrl() + props.productImagePath + "/" + product.image} alt="" /></a></Link>
                      {/* <a className="secondary_img" href="#"><img
                                        src="assets/img/product/product2.jpg" alt="" /></a> */}
                      <div className="action_links">
                        <ul>
                          {WishlistDetailReducer !== 0 && WishlistDetailReducer.findIndex(item => item.id === product.id) !== -1 ?
                            <li className="wishlist" onClick={() => RemoveWishlist(product)}><a title="Add to Wishlist" className="wlishlist_links_active"><i
                              className="fa fa-heart-o" aria-hidden="true"></i></a></li> :
                            <li className="wishlist" onClick={() => AddWishlist(product)}><a title="Add to Wishlist" className=""><i
                              className="fa fa-heart-o" aria-hidden="true"></i></a></li>}

                          <li className="quick_button" onClick={() => QuickView(product)}><a
                            title="quick view"> <i className="fa fa-window-maximize" aria-hidden="true"></i></a></li>
                        </ul>
                      </div>

                    </div>
                    {props.title !== "popular" ?
                      <figcaption className="product_content">
                        <h3 className="product_name"><a href="">{product.name}</a></h3>

                        <p className='product-dis'>{product.mini_description}</p>
                        <div className="price_box">
                          <span className="current_price">{ConvertCurrency(product.price, 1, props.currency, rate)}</span>
                        </div>

                      </figcaption>
                      : null}
                  </figure>
                  {props.title !== "popular" ?
                    <div className={CartListDetails.some(item => item.id === product.id) ? 'bottom-btn default_btn bg-red' : 'bottom-btn default_btn'}>
                      {CartListDetails.some(item => item.id === product.id) ?
                        <a title="remove from cart" tabIndex="0" onClick={() => removeFromCart(product)}>Remove From Cart</a>
                        :
                        <a title="add to cart" tabIndex="0" onClick={() => addToCart(product)}>Add To Cart</a>}
                    </div>
                    : null}
                </article>
              </div>
            </div>
          ))}
        </Carousel>
        : null}
      {/* <Button variant="primary" onClick={handleShow}>
        View Product
      </Button> */}

      <QuickViewPopup setShow={setShow} showModal={showModal} popProduct={popProduct} productImagePath={props.productImagePath} currency={props.currency} />
    </div>

  )

}
export default ProductsCarousel;