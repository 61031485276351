import React, { useState, useEffect } from 'react'
import CartSummery from './cartSummery';
import { setPathName } from '../API/Storage';
import { useSelector, useDispatch } from "react-redux";
import Footer from '../Layouts/footer';

const PaymentMethod = () => {
    
    const [paymentMethodData, setPaymentMethodData] = useState({paymentMethod:'wallet'})
    const [minimumTotalAmount, setMinimumTotalAmount] = useState(0)
    const rate = useSelector((state) => state.CurrencyRateReducer);
    const [paymentMethodValidate, setpaymentMethodValidate] = useState({
        is_error:false,
        message:""
    })
    function addPaymentMethod(e){
        setPaymentMethodData({paymentMethod:e.target.value});
    }
    useEffect(()=>{
        setPathName(window.location.pathname)
    },[])
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li><a href="#">Products</a></li>
                                    <li>Payment Method</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                paymentMethodValidate.is_error ? 
                <div className="alert alert-danger" role="alert">{paymentMethodValidate.message}</div> : null}
            <section className="product_area mb-46">
                <div className="container bg-white pt-20 pb-20">
                    <div className="row">
                        <div className="col-12">
                            <div className="section_title">
                                <h2 className='border-bottom'>Payment Method</h2>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-8 col-md-8 col-sm-12'>
                            <div className='card-header'><h3 className=" card-title mb-0">Choose Payment Method</h3></div>
                            <div className='card-body mt-40'>
                                <div className="expanel expanel-default expanel-body-active">
                                    <div className="expanel-body">
                                        <div className='row'>
                                            <div className='col-md-1'><input className='bg-orange' type="radio" name="paymentmethod" value={'wallet'} onChange={addPaymentMethod} checked={paymentMethodData.paymentMethod === "wallet" ? 'checked' : null}/> <i className="fa fa-credit-card" aria-hidden="true"></i></div>
                                            <div className='col-md-11'><p>Wallet</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body mt-20'>
                                <div className="expanel expanel-default">
                                    <div className="expanel-body">
                                        <div className='row'>
                                            <div className='col-md-1'><input className='bg-orange' type="radio" name="paymentmethod" value={"cod"} onChange={addPaymentMethod} checked={paymentMethodData.paymentMethod === "cod" ? 'checked' : null}/> <i className="fa fa-money" aria-hidden="true"></i></div>
                                            <div className='col-md-11'><p>Cash on delivery</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body mt-20'>
                                <div className="expanel expanel-default">
                                    <div className="expanel-body">
                                        <div className='row'>
                                            <div className='col-md-1'><input className='bg-orange' type="radio" name="paymentmethod" value={'emi'} onChange={addPaymentMethod} checked={paymentMethodData.paymentMethod === "emi" ? 'checked' : null}/> <i className="fa fa-id-card-o" aria-hidden="true"></i></div>
                                            <div className='col-md-11'><p>EMI / Pay Later</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CartSummery PaymentMethod={paymentMethodData} setMinimumTotalAmount={setMinimumTotalAmount} setpaymentMethodValidate={setpaymentMethodValidate}/>
                    </div>
                </div>
            </section>
      <Footer />
        </>
    );
}
export default PaymentMethod;