import axios from "axios";
import {CommonBaseUrl,EVMarketUrl} from "./Url";
import { getToken, removeToken } from "./Storage";
const commonBaseUrl = CommonBaseUrl();

const AddressAddApiUrl = commonBaseUrl+"user/location/store";
const AddressGetApiUrl = commonBaseUrl+"user/locations";
const AddressDeleteApiUrl = commonBaseUrl+"user/location/delete";
const AddressSetPrimaryUrl = commonBaseUrl+"user/location/set-primary";
export function AddressAddApi(data){
    const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
  return axios.post(AddressAddApiUrl,data,config);
}
export function AddressGetApi(){
  const token = getToken();
  const config = {
      headers: { 'Authorization': `Bearer ${token}` }
  };
  return axios.get(AddressGetApiUrl,config);
}
export function AddressDeleteApi(data){
  const token = getToken();
  const config = {
      headers: { 'Authorization': `Bearer ${token}` }
  };
  return axios.post(AddressDeleteApiUrl,data,config);
}
export function AddressSetPrimaryApi(data){
  const token = getToken();
  const config = {
      headers: { 'Authorization': `Bearer ${token}` }
  };
return axios.post(AddressSetPrimaryUrl,data,config);
}