
import axios from "axios";
import { getCurrencyCountry, getToken, removeToken, getCartQuantity } from "./Storage";

const addToCartUrl      = "/add/cart";
const removeFromCartUrl = "/remove/cart";
let currency = getCurrencyCountry().currencyCode;

export function AddToCartApi(product,bulk="",currency){
  let data = {data:product};
  const token = getToken();
  const config = {
      headers: { 'Authorization': `Bearer ${token}` }
  };
  return axios.post(addToCartUrl+"?currency="+currency,data,config);
}
export function RemoveFromCartApi(product,bulk="",currency){
  let data = {data:product};
  const token = getToken();
  const config = {
      headers: { 'Authorization': `Bearer ${token}` }
  };
  return axios.post(removeFromCartUrl+"?currency="+currency,data,config);
}
export function AddToCartBulkApi(product){
  const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
  return axios.post(addToCartUrl,product,config);
}
export function CartListApi(page="",currency){
const cartListtUrl      = '/cart/list?currency='+currency+'&page='+page;
  const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}`,'Accept': 'application/json' }
    };
  return axios.get(cartListtUrl,config);
}