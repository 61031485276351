export function setToken(data){
    localStorage.setItem('token',data);
}
export function getToken(){
    return localStorage.getItem("token"); 
}

export function removeToken(){
    localStorage.removeItem("token");
}


export function setCartData(data){
    localStorage.setItem('cart',JSON.stringify(data))
}
export function setCartIdData(data){
    localStorage.setItem('cartID', JSON.stringify(data))
}
export function getCartData(){
    return localStorage.getItem('cart')
}
export function getCartIdData(){
    return localStorage.getItem('cartID')
}
export function removeCartData(){
    localStorage.removeItem('cart')
}
export function removeCartIdData(){
    localStorage.removeItem('cartID')
}

export function setWishlistData(data){
    localStorage.setItem('wishlist',JSON.stringify(data))
}
export function setWishlistIdData(data){
    localStorage.setItem('wishlistID', JSON.stringify(data))
}
export function getWishlistData(){
    return localStorage.getItem('wishlist')
}
export function getWishlistIdData(){
    return localStorage.getItem('wishlistID')
}
export function removeWishlistData(data){
    localStorage.removeItem('wishlist')
}
export function removeWishlistIdData(data){
    localStorage.removeItem('wishlistID')
}

export function setPathName(data){
    localStorage.setItem('path',data);
}
export function removePathName(){
    localStorage.removeItem('path');
}

export function setCurrencyCountry(currencyCode,countryCode){
    localStorage.setItem('currencyCode',currencyCode);
    localStorage.setItem('countryCode',countryCode);
}
export function getCurrencyCountry(){
    let data
    return data = {currencyCode:localStorage.getItem('currencyCode'),countryCode:localStorage.getItem('countryCode')}
}

export function setCartQuantity(data){
    localStorage.setItem('cartQuantity', JSON.stringify(data))
}
export function getCartQuantity(){
    return localStorage.getItem('cartQuantity')
}
export function removeCartQuantity(){
    localStorage.removeItem('cartQuantity')
}
export function setFPToken(data){
    localStorage.setItem('fp_token',data);
}