import React, { useState, useEffect } from 'react'
import { BrowserRouter, Link } from 'react-router-dom';
import MyAccountMenu from './myaccountmenu';
import { AddressGetApi, AddressDeleteApi, AddressSetPrimaryApi } from '../../API/Address';
import Footer from '../../Layouts/footer';

const MyAddress = (props) => {
    const [allAddress, setAllAddress] = useState([]);
    const [isDelete, setIsDelete] = useState(false)
    const [isPrimary, setIsPrimary] = useState(false)
    useEffect(()=>{
        AddressGetApi().then((result)=>{
            setAllAddress(result.data.data);
        }).catch((err)=>{
            console.log(err)
        })
    },[isDelete,isPrimary])
    function AddressDelete(address_id){
        let data = {id:address_id}
        AddressDeleteApi(data).then((result)=>{
            setIsDelete(!isDelete)
        }).catch((err)=>{
            console.log(err);
        })
    }
    function AddressSetPrimary(address_id){
        let data = {id:address_id}
        AddressSetPrimaryApi(data).then((result)=>{
            setIsPrimary(!isPrimary)
        }).catch((err)=>{
            console.log(err);
        })
    }
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li>Address</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                <div className="tab-content dashboard_content bordered padding-full-side40">
                                <div className="section_title border-none"><h2 className='border-bottom'>Manage Address</h2></div>
                                    <div className='row ml-0 mr-0'>
                                    <Link to={"/addaddress"}><div className="col-lg-12 col-md-12 bg-green add-address"><h3><a href=""><i className="fa fa-plus mr-5" aria-hidden="true"></i>
 Add New Address</a></h3></div></Link>
                                    </div>
                                    <div className="row">
                                        {allAddress.length > 0 ? 
                                        allAddress.map((address)=>(
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="expanel expanel-default">
                                                <div className="expanel-heading">
                                                    <h3 className="expanel-title color-green"><i className="fa fa-map-marker mr-5" aria-hidden="true"></i>
                                                        {address.location_type ? address.location_type.name : null}</h3>
                                                    <div className="col-auto">
                                                        <label className="colorinput">
                                                            <input name="color" type="radio" value="" checked={address.is_primary == 1 ? "checked" : null} onClick={()=>AddressSetPrimary(address.id)} className="colorinput-input" />
                                                            <span className="colorinput-color bg-green"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="expanel-body address-body">
                                                    <h4><strong>{address.first_name}  {address.last_name}</strong></h4>
                                                    <p>{address.address_line_1}, {address.state.name}, {address.city.name}, {address.country.name}</p>
                                                </div>
                                                <div className="expanel-footer bg-orange"><a >Edit</a><a className="mydelete" onClick={()=>AddressDelete(address.id)}>Delete</a></div>
                                            </div>
                                        </div>
                                        ))
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default MyAddress;