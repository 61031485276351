import React, { useState, useEffect, useRef } from 'react'
import { IsAuthenticated } from '../API/CheckAuth'
import { AddToCartApi, CartListApi } from '../API/CartApi';
import { useSelector, useDispatch } from "react-redux";
import { UserDetails, cartDetails, cartCount } from "../Action/action";
import { setCartData, setCartIdData, getCartData, getCartIdData, removeCartData, removeCartIdData, getCurrencyCountry, setCartQuantity } from '../API/Storage';
import { ImageUrl } from '../API/Url';
import { RecommendProductListApi, productDetails } from '../API/ProductApi';
import { ProductListApi } from '../API/HomeApi';
import { ConvertCurrency } from '../API/currency';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import CartSummery from './cartSummery';
import { RefreshTokenApi } from '../API/Auth';
import { setPathName, getCartQuantity } from '../API/Storage';
import { checkIndex, filterJson } from '../API/UnAuthApi';
import Footer from '../Layouts/footer';
import { useAddToCart, useRemoveFromCart } from '../API/CartChanges';

const ShoppingCart = (props) => {
    const redirect = useNavigate();
    const recommendProductsdRef = useRef()
    const dispatch = useDispatch();

    const [recommendProducts, setRecommendProducts] = useState([]);
    const [productImagePath, setProductImagePath] = useState();
    const [recommendProductsVisible, setRecommendProductsVisible] = useState(false);
    const [categoryCount, setCategoryCount] = useState(0);
    const [getCategoryId, setGetCategoryId] = useState([]);
    const [products, setProducts] = useState([]);
    const [cartId, setCartId] = useState([])
    const [cart, setCart] = useState([])
    const profile = useSelector((state) => state.ProfileReducer);
    const CartListDetails = useSelector((state) => state.CartReducer);
    const [currency, setCurrency] = useState()
    const [quantity, setQuantity] = useState([])
    const rate = useSelector((state) => state.CurrencyRateReducer);
    const [cartError, setCartError] = useState("Loading...");
    const [showFooter, setShowFooter] = useState(false)

    useEffect(()=>{
      setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
    });
    
    useEffect(()=>{
        setPathName(window.location.pathname)
    },[])
// Start Add to cart
useEffect(()=>{
    
        const data = CartListDetails.map(item => item.id);
        if(currency && CartListDetails.length > 0) 
        {
            productDetails(data,currency).then((result) => {
                setCart(result.data.data)
                if(CartListDetails.length === 0)
                {
                    setCartError("Your cart is empty")
                }
            }).catch((err) => {
                
            });
        }
        else
        {
            setCartError("Loading")
            setTimeout(()=>{
                setCartError("Your cart is empty")
            },5000)
        }
        setTimeout(()=>{
            setShowFooter(true)
        },2000)
},[currency,CartListDetails])

const addToCart = useAddToCart();
const removeFromCart = useRemoveFromCart();


// Start recommend products
useEffect(()=>{
    if(currency){
        RecommendProductListApi(currency).then((result) => {
            setRecommendProducts(result.data.data);
            setProductImagePath(result.data.product_image_path);

        }).catch((err) => {
            setRecommendProducts([]);
            // console.log(err);
        });
    }
},[currency])
    function ProductListApiCall(data = 0, length = 0, cc = 0, currency) {
        let catid = data;
        ProductListApi(catid,currency).then((response) => {
          if (response.data.status == 'success') {
            products.push(response.data.products);
          }
        }).catch((err) => {
    
        }).finally(() => {
          if (length - 1 > cc) {
            setCategoryCount(categoryCount + 1);
          }
        });
      }
useEffect(() => {
    const recommendProductsObserver = new IntersectionObserver((entries) => {
        const recommendProductsEntry = entries[0];
        setRecommendProductsVisible(recommendProductsEntry.isIntersecting);
        let category_id;
        if (props.categoryListProps.length > 0) {
            category_id = props.categoryListProps[categoryCount].id;
            let index = getCategoryId.indexOf(category_id);
            if (index === -1) {
                ProductListApiCall(category_id, props.categoryListProps.length, categoryCount,currency);
            getCategoryId.push(category_id);
            }
        }
    })
    recommendProductsObserver.observe(recommendProductsdRef.current);
}, [categoryCount, recommendProductsVisible])
// End recommend products
// Start add quantity
function addQuantity(data,quantity){
    let cartD = CartListDetails;
    let cart = cartD.map((item)=>{
        return data.id === item.id ? {...item,cartQuantity:quantity} : item
    })
    dispatch(cartDetails(cart));
    if(!IsAuthenticated())
    {
        setCartData(cart);    
    }
    
    // setCartData(cart);
    // setQuantity((prev)=>([...prev,{[data.id]:quantity}]))
    // let d = [];
    // setCart(cart.map((item)=>{
    //         return data.id === item.id ? {...data,cartQuantity:quantity} : item
    // }))
    // d = cart.map((item,key)=>{
    //         return data.id === item.id ? quantity : item.cartQuantity ? item.cartQuantity : 1  
    // })
    // setCartQuantity(d);
    // let cartD = CartListDetails;
    // let cart = cartD.map((item)=>{
    //     return data.id === item ? {...data,cartQuantity:quantity} : item
    // })
    // dispatch(cartDetails(cart));
    // setCartData(cart);
}
// End add quantity

// Start Order

// End Order 
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li>Shopping Cart</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shopping_cart_area pt-40 bg-white">
                <div className="container">
                    <form action="#">
                    {cart && cart.length > 0  && CartListDetails.length > 0 ? 

                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <div className='widget_list'><h2>Your Shopping Cart</h2></div>
                                {cart.map((cartData,key)=>{
                                    const foundObject = CartListDetails.find((obj) => obj.id === cartData.id);
                                    return(
                                <div className="table_desc">
                                    <div className='row'>
                                        {/* <div className='col-md-1 margin-auto'>
                                            <label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" value="" checked />
                                                {cartData.id}
                                                <span className="custom-control-label">&nbsp;</span>
                                            </label>
                                        </div> */}
                                        <div className='col-md-2 margin-auto'><img src={ImageUrl()+productImagePath+cartData.image} alt='Headphone' /></div>
                                        <div className='col-md-4 margin-auto'><h3 className="cart-product-name">{cartData.name}</h3>
                                            <p>{cartData.description}</p>
                                            {currency ? 
                                            <span className='price-tag'>{ConvertCurrency(cartData.price,1,currency,rate)}</span>: null}
                                            {/* {currency ? <span className='cart-old-price'>{ConvertCurrency(cartData.price,1,currency,rate)}</span>: null} */}
                                            </div> 
                                        <div className='col-md-1 margin-auto'><h4 className='instock-tag'>Instock</h4></div>
                                        <div className='col-md-1 margin-auto product_quantity'><input min="1" max="100"  type="number" onChange={(e)=>addQuantity(cartData,e.target.value)} value={foundObject && foundObject.cartQuantity ? foundObject.cartQuantity : 1}  /></div>
                                        {/* <div className='col-md-1 margin-auto product_quantity'><input min="1" max="100"  type="number" onChange={(e)=>addQuantity(cartData,e.target.value)}/></div> */}
                                        {currency ? 
                                        <div className='col-md-3 margin-auto cart-price'>
                                            <span className='price-tag'>
                                            { ConvertCurrency(cartData.price,1, currency,rate)}
                                            </span> 
                                           <span className='delete-btn'> <a onClick={ ()=>removeFromCart(cartData) } ><i className="fa fa-times" aria-hidden="true"></i>
                                            </a></span>
                                        </div>
                                        : null}
                                    </div>
                                </div>
                                )})}
                            </div>
                            <CartSummery cart={cart} mobileCode={props.mobileCode}/>
                            {/* <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className='small-title mt-20 mb-20'>Offers & Benefits</div>
                                <div className="single_shipping mb-20">
                                    <div className="shipping_icone">
                                        <img src="/assets/img/icon/badge-discount.png" alt="" />
                                    </div>
                                    <div className="shipping_content">
                                        <h2>&quot;WELCOME1000&quot; applied</h2>
                                        <p><span className='color-orange'>UGX 1000</span> coupon saving</p>
                                    </div>
                                    <div className="remove-text"><a href="">Remove</a></div>
                                </div>
                                <div className="coupon_code right">
                                    <h3>Bill Summary</h3>
                                    <div className="coupon_inner">
                                        <div className="cart_subtotal">
                                            <p><strong>Order Value</strong></p>
                                            <p className="cart_amount total-amount"> {ConvertCurrency(productsTotalAmount ? productsTotalAmount : 0.00 )}</p>
                                        </div>
                                        <div className="cart_subtotal">
                                            <p>Delivery Pointer Fee</p>
                                            <p className="cart_amount"><span className='text-through'>UGX 100</span> <span className='color-orange'>Free</span></p>
                                        </div>
                                        {cart.map((item)=>(
                                        <div className="">
                                            <div className='row'>
                                                <div className='col-md-6'>{item.name}</div>
                                                <div className='col-md-2 text-right'>{ item.cartQuantity ? item.cartQuantity : 1 }</div>
                                                <div className='col-md-4 color-orange text-right'>{ ConvertCurrency(item.price, item.cartQuantity ? item.cartQuantity : 1) }</div>
                                            </div>
                                            
                                        </div>
                                        ))}
                                        <div className="cart_subtotal">
                                            <p>Discount on MRP</p>
                                            <p className="cart_amount color-green">- UGX 2000</p>
                                        </div>
                                        <div className="cart_subtotal">
                                            <p>Coupon Discount</p>
                                            <p className="cart_amount color-green">- UGX 1000</p>
                                        </div>
                                        <div className="cart_subtotal">
                                            <p>Tax & Charges</p>
                                            <p className="cart_amount">- UGX 1000</p>
                                        </div>
                                        <div className="cart_subtotal total-amount border-top mb-20 pt-20">
                                            <p className='total-amount'>Total Amount</p>
                                            <p className="total-amount cart_amount">UGX 50,360</p>
                                        </div>
                                        <div className="checkout_btn">
                                            <a onClick={ProceedToOrder}>Proceed to Order</a>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div> 
                        : 
                            <div className="row">
                                {cartError}
                            </div>
                                }
                    </form>
                </div>
            </div>
            <section className="top_category_product mb-40 bg-white">
                <div className="container pt-20 pb-20 ">
                    <div ref={recommendProductsdRef} ></div>
                    {recommendProducts.length > 0 ? 
                    <div className="row">
                        <div className="section_title" ><h2 className='border-bottom'>Recommend for you</h2></div>
                        {recommendProducts.map((recommendProduct)=>(
                        <div className="col-lg-3">
                            <article className="single_product">
                                <figure>
                                    <div className="product_thumb product-img-width">
                                        <a href="#"><img src={ImageUrl()+productImagePath+recommendProduct.image} alt="" /></a>
                                    </div>
                                    {currency ? 
                                    <figcaption className="category_name">
                                        <h3 className='product_name product-name text-left'><a href="#">{recommendProduct.name}</a></h3>
                                        <p className='product-dis'>{recommendProduct.mini_description}</p>
                                        <h2 className='recom-price text-left'>{ ConvertCurrency(recommendProduct.price, 1,currency,rate) }</h2>
                                        
                                    </figcaption>
                                    : null}
                                </figure>
                                {/* <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                                 */}
                                 
                                <div className={CartListDetails.some(item => item.id === recommendProduct.id) ? 'default_btn bg-red' : 'default_btn'}>
                                {CartListDetails.some(item => item.id === recommendProduct.id) ?
                                <a title="remove from cart" tabIndex="0" onClick={() => removeFromCart(recommendProduct)} >Remove From Cart</a>
                                :  
                                <a title="add to cart" tabIndex="0" onClick={() => addToCart(recommendProduct)}>Add To Cart</a>}          
                                </div>
                            </article>
                        </div>
                        ))}
                        {products.map((prs)=>{
                            return (
                                prs.data.map((product)=>(
                            
                        <div className="col-lg-3">
                            <article className="single_product">
                                <figure>
                                    <div className="product_thumb product-img-width">
                                        <a href="#"><img src={ImageUrl()+productImagePath+product.image} alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                    <h3 className='product_name product-name text-left'><a href="#">{product.name}</a></h3>
                                    <p className='product-dis'>{product.mini_description}</p>
                                        <h2 className='recom-price text-left'>{ConvertCurrency(product.price, 1, currency,rate)}</h2>
                                        
                                    </figcaption>
                                </figure>
                                <div className={CartListDetails.some(item => item.id === product.id) ? 'default_btn bg-red' : 'default_btn'}>
                                {CartListDetails.some(item => item.id === product.id) ?
                                <a title="remove from cart" tabIndex="0" onClick={() => removeFromCart(product) } >Remove From Cart</a>
                                :  
                                <a title="add to cart" tabIndex="0" onClick={() => addToCart(product)}>Add To Cart</a>}          
                                </div>
                            </article>
                        </div>
                        ))
                        )})}
                        {/* <div className="col-lg-3">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/ev-charge-factroy.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-3">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/wall-mounted.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Sho Now</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-3">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-3 mt-20">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-3 mt-20">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-3 mt-20">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-3 mt-20">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-3 mt-20">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-3 mt-20">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-3 mt-20">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-3 mt-20">
                            <article className="single_category">
                                <figure>
                                    <div className="category_thumb">
                                        <a href="#"><img src="/assets/img/product/upto-offer/tt-charger.jpg" alt="" /></a>
                                    </div>
                                    <figcaption className="category_name">
                                        <h2 className='recom-price text-left'>UGX 117,699</h2>
                                        <h3 className='product-name text-left'><a href="#">Home Accessories</a></h3>
                                    </figcaption>
                                </figure>
                                <div className="default_btn">
                                    <a href="#" title="add to cart" tabIndex="0">Shop Now</a>
                                </div>
                            </article>
                        </div> */}

                    </div>
                    : null }

                </div>
            </section>
{showFooter && <Footer />}
        </>
    );
}
export default ShoppingCart;
