import React, { useState, useEffect } from 'react'
import { RegisterApi, SendOtpApi, VerifyOtpApi } from '../API/Auth';
import { IsAuthenticated } from '../API/CheckAuth';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import Modal from '../components/modal/modal';
import { send } from 'q';
import { ProfileApi } from '../API/User';
import { useSelector, useDispatch } from "react-redux";
import { UserDetails, cartDetails, wishlistDetails, wishlistCount  } from "../Action/action";
import { getToken, removeToken, getCurrencyCountry } from "../API/Storage";
import { AddToCartApi,CartListApi } from '../API/CartApi';
import { getCartData, getWishlistData } from '../API/Storage'
import { AddWishListApi, WishlistDetailApi } from "../API/WishlistApi";
import { removeCartData, removeCartIdData, removeWishlistData, removeWishlistIdData, setToken } from '../API/Storage';
import ReactCountryFlag from 'react-country-flag';
import {Helmet} from "react-helmet"
import Footer from '../Layouts/footer';
import { useAddToCart } from '../API/CartChanges';
import { useAddToWishlist } from '../API/WishlistChanges';
const Signup = (props) => {
    const dispatch = useDispatch();
    const time = 10;
    const total_otp_allowed = 5;
    const redirect = useNavigate();
    let mobileCode = props.mobileCode;
    let is_mcc = mobileCode.length > 0 ? true : false;
    const [isClickTerms, setIsClickTerms] = useState(false);
    const [termsErrorMsg, setTermsErrorMsg] = useState("");

    const initialLoginStateErrors = {
        username: { isError: 'false', errMsg: [] },
        name: { isError: 'false', errMsg: [] },
        email: { isError: 'false', errMsg: [] },
        mobile: { isError: 'false', errMsg: [] },
        verify: { isError: 'false', errMsg: [] },
        password: { isError: 'false', errMsg: [] },
        mcc_id: { isError: 'false', errMsg: [] },
        country_id: { isError: 'false', errMsg: [] },
    };
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [errors, setErrors] = useState(initialLoginStateErrors);
    const [showModal, setShowModal] = useState(false);
    const [signupDetails, setSignupDetails] = useState({
        verify: "mobile",
        device_type: "web",
        token: "740f4707bebcf74f9b7c25d48e3358945f6aa01da5ddb387462c7eaf61bb78ad",
        udid: "740f4707bebcf74f9b7c25d48e3358945f6aa01da5ddb387462c7eaf61bb78ad",
        guard: "user",
    });
    const [isResponseSignupError, setIsResponseSignupError] = useState(false);
    const [responseSignupErrors, setResponseSignupErrors] = useState();
    const [responseSignupErrorCode, setResponseSignupErrorCode] = useState();
    const [otpTimer, setOtpTimer] = useState(0)
    const [otpCount, setOtpCount] = useState(1)
    const [reSendOtpTo, setReSendOtpTo] = useState('mobile');
    const [tokenForOtp, setTokenForOtp] = useState();
    const [verifyOtpDetails, setVerifyOtpDetails] = useState({})
    const profile = useSelector((state) => state.ProfileReducer);

    const [currency, setCurrency] = useState()
    useEffect(()=>{
      setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
    });

    function validation(data) {
        let onlyText = /^[A-Za-z]+$/;
        let onlyNumber = /^\d+$/;
        let onlyEmail = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/
        let errorsAvailable = false;
        if (!data.mcc_id) {
            data.mcc_id = mobileCode[0].id;
        }
        if (!(onlyNumber.test(data.country_id))) {
            delete data.country_id;
        }
        if (!data.username) {
            errorsAvailable = true;
            initialLoginStateErrors.username.errMsg.push("Username is required");
            initialLoginStateErrors.username.isError = true;
        }
        if (!data.name) {
            errorsAvailable = true;
            initialLoginStateErrors.name.errMsg.push("name is required");
            initialLoginStateErrors.name.isError = true;
        }
        if (!(onlyText.test(data.name))) {
            errorsAvailable = true;
            initialLoginStateErrors.name.errMsg.push("name only a text");
            initialLoginStateErrors.name.isError = true;
        }
        if (!data.mobile) {
            errorsAvailable = true;
            initialLoginStateErrors.mobile.errMsg.push("mobile is required");
            initialLoginStateErrors.mobile.isError = true;
        }
        if (!(onlyNumber.test(data.mobile))) {
            errorsAvailable = true;
            initialLoginStateErrors.mobile.errMsg.push("mobile only a number");
            initialLoginStateErrors.mobile.isError = true;
        }
        if (!data.email) {
            errorsAvailable = true;
            initialLoginStateErrors.email.errMsg.push("email is required");
            initialLoginStateErrors.email.isError = true;
        }
        if (!(onlyEmail.test(data.email))) {
            errorsAvailable = true;
            initialLoginStateErrors.email.errMsg.push("is not a valid email");
            initialLoginStateErrors.email.isError = true;
        }
        if (!data.password) {
            errorsAvailable = true;
            initialLoginStateErrors.password.errMsg.push("password is required");
            initialLoginStateErrors.password.isError = true;
        }
        if (!data.country_id) {
            errorsAvailable = true;
            initialLoginStateErrors.country_id.errMsg.push("Country is required");
            initialLoginStateErrors.country_id.isError = true;
        }
        setErrors(initialLoginStateErrors);
        return errorsAvailable;
    }
    function SignupData(e) {
        if(e.target.name === "terms")
        {
            setIsClickTerms(!isClickTerms);
        }
        if (e.target.name === "mobile") {
            if (isNaN(e.target.value)) {
                return false;
            }
        }
        setSignupDetails({ ...signupDetails, [e.target.name]: e.target.value })
    }
    function Signup(e) {
        e.preventDefault();
        if(!isClickTerms)
        {
            setTermsErrorMsg("You must check this")
        }
        else
        {
            setTermsErrorMsg("")
        }
        setIsResponseSignupError(false)
        let validate = validation(signupDetails);
        if (!validate && isClickTerms) {
            RegisterApi(signupDetails).then((response) => {
                if (response.data.message === "Success") {
                    let token = response.data.data.access_token;
                    // setToken(token);
                    setTokenForOtp(token);
                    setShowModal(true);
                    setOtpCount(otpCount + 1)
                    setOtpTimer(otpCount * 1);
                    // return redirect("/home")

                }
            }).catch((err) => {
                setResponseSignupErrorCode(err.response.status);
                if (err.response.status == 422) {
                    setIsResponseSignupError(true);
                    setResponseSignupErrors(err.response.data.error);
                }
                else {
                    setIsResponseSignupError(true);
                    setResponseSignupErrors([error => "something went worng"]);
                }

            });
        }
    }

    function ProfileApiCall(token) {
        ProfileApi(token).then((result) => {
            dispatch(UserDetails(result.data.data));
        }).catch((err) => {
            dispatch(UserDetails(err));

            if (err.response.status == 401) {
                if (getToken()) {
                    removeToken();
                    return redirect("/login");
                }
            }
        });
    }
    //Start resend otp
    useEffect(() => {
        setTimeout(() => {
            if (otpTimer > 0) {
                setOtpTimer(otpTimer - 1);
            }
        }, 1000);
    }, [otpTimer])
    function resendOtp() {
        if (otpCount <= total_otp_allowed) {
            setOtpTimer(otpCount * time)
            setOtpCount(otpCount + 1)
            callOtpApi(reSendOtpTo, tokenForOtp);
        }
    }
    function deviceOtp() {
        let data = "";
        if (otpCount <= total_otp_allowed) {
            if (reSendOtpTo == 'mobile') {
                setReSendOtpTo('email')
                data = "email";
            }
            else {
                setReSendOtpTo('mobile')
                data = "mobile";
            }
            setOtpTimer(otpCount * 1)
            setOtpCount(otpCount + 1)
            callOtpApi(data, tokenForOtp);
        }
    }

    function callOtpApi(verify, token = "") {
        let data = { verify: verify }
        SendOtpApi(data, token).then((result) => {
            setTokenForOtp(result.data.data.access_token)
        }).catch((err) => {
            console.log("register otp is not working");
        });
    }
    //End resend otp
    // Start Verify Otp
    function VerifyOtpText(e) {
        setVerifyOtpDetails({ ...verifyOtpDetails, [e.target.name]: e.target.value })
    }

    const addToCart = useAddToCart();
    const addToWishlist = useAddToWishlist();
  
    function VerifyOtpForm(e) {
        e.preventDefault();
        VerifyOtpApi(verifyOtpDetails, tokenForOtp).then((response) => {
            setToken(response.data.data.access_token);
            ProfileApiCall(response.data.data.access_token)
            const cartDataLocalStorage = JSON.parse(getCartData());
            const wishlistDataLocalStorage = JSON.parse(getWishlistData());
            addToCart();
            addToWishlist();           
            return redirect("/home")
        }).catch((err) => {
            alert('otp did not match')
        });
    }
    // End Verify Otp
    function showPassword() {
        setIsShowPassword(isShowPassword === true ? false : true)
    }
    if (IsAuthenticated()) {
        return <Navigate to="/home" />
    }


    return (
        <>
        <div className="signup-bg">
            <Helmet>
            <title>Electric Vehicle Marketplace | Evmarket</title>
<link rel="canonical" href="https://ev-market-frontend.heptotechnologies.org/home" />
<meta name="robots" content="index, follow" />
<meta name="description" content="Visit Evmarket, established marketplace for Electric Vehicles. Explore the website and browse through our full range of offers." />
            </Helmet>
            <div className="container">
                {showModal && (
                    <Modal
                        title={<span>Verify your {reSendOtpTo}</span>}
                        content={

                            <div className='row'>
                                <form action="" onSubmit={VerifyOtpForm}>
                                    {/* <div className="col-md-3 form-group">Verification Code</div> */}
                                    <div className="col-md-6 form-group">
                                        <input className='form-control' type="text" onChange={VerifyOtpText} name="otp" />
                                        <div style={{ "display": "flex", "justify-content": "space-between" }}>
                                            {otpTimer === 0 ?
                                                <a className="btn btn-primary" onClick={deviceOtp}>use {reSendOtpTo == "mobile" ? 'email' : 'mobile'}</a> : <a></a>}
                                            <a className='btn btn-danger'>{otpTimer > 0 ? otpTimer : (<span onClick={resendOtp}>{otpCount <= total_otp_allowed ? 'resend' : null}</span>)}</a>
                                        </div>
                                        <p className='alert mt-2 hint-text'>Code sent to your {reSendOtpTo == "mobile" ? signupDetails.mobile : signupDetails.email}</p>

                                    </div>

                                    {/* <div className="col-md-3 form-group"><input className='form-control' placeholder='seconds' type="text" readOnly /></div> */}

                                    <div className='col-md-12'>
                                        <button className='submit-btn'>Submit</button>
                                    </div>
                                </form>
                            </div>}
                        close={setShowModal}
                    />
                )}
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className='sign-up-error-msg'>
                        {isResponseSignupError &&
                        <div className="alert alert-danger" role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-hidden="true" onClick={()=>{setIsResponseSignupError(false)}}>×</button>
                                {responseSignupErrorCode !== 500 ?
                                    Object.entries(responseSignupErrors).map((e) => {
                                        const [key, msg] = e;
                                        return (
                                            <div key={e}>
                                                {key} :
                                                {msg.map((v) => (
                                                    <div key={v}>{v}</div>
                                                ))}
                                            </div>
                                        )
                                    })
                                    : <div>Something went wrong</div>}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="account_form login-form">
                            <form onSubmit={Signup}>
                                <div className='login-header'>
                                    <h3><span className='border-bottom'>Sign Up</span></h3>
                                    <div className="form-group m-0">
                                        <div className="row">
                                            <div className="col-md-12 form-group error-postion">
                                                <input type="text" className="form-control " placeholder="Email Address" name="email" onChange={SignupData} />
                                                {errors.email.isError &&
                                                    <span className='error-msg'>{errors.email.errMsg[0]}</span>
                                                }

                                            </div>
                                            <div className="col-md-12 form-group error-postion">

                                                <input type="text" className="form-control" placeholder="Name" name="name" onChange={SignupData} />
                                                {errors.name.isError &&
                                                    <span className='error-msg'>{errors.name.errMsg[0]}</span>
                                                }
                                            </div>
                                            <div className="col-md-12 form-group error-postion">
                                                <select className="form-control" name='country_id' onChange={SignupData}>
                                                    <option >Select Country</option>
                                                    {props.countryLists.map((countryList) => (
                                                        <option value={countryList.id}>{countryList.name}</option>
                                                    ))}
                                                </select>
                                                {errors.country_id.isError &&
                                                    <span className='error-msg'>{errors.country_id.errMsg[0]}</span>

                                                }
                                            </div>
                                            <div className="col-md-12 form-group error-postion">
                                                <input type="text" className="form-control" placeholder="User Name" name="username" onChange={SignupData} />
                                                {errors.username.isError &&
                                                    <span className='error-msg'>{errors.username.errMsg[0]}</span>
                                                }
                                            </div>
                                            {is_mcc && <div className="col-md-3 form-group">
                                                <select name="mcc_id" id="" className='form-control' onChange={SignupData}>
                                                    {mobileCode.map((m) => (
                                                        <option value={m.id} selected={ profile.currency ? m.country_code === profile.currency.country_code ? "selected" : null : null}><ReactCountryFlag countryCode={m.country_code}/>  +{m.code}</option>
                                                        
                                                    ))}
                                                </select>
                                            </div>}
                                            <div className="col-md-9 form-group error-postion">
                                                <input type="text" className="form-control" placeholder="Mobile Number" name="mobile" onChange={SignupData} value={(isNaN(signupDetails.mobile)) || (signupDetails.mobile == "0") || (signupDetails.mobile * 1 === 0) ? "" : signupDetails.mobile * 1} />
                                                {errors.mobile.isError &&
                                                    <span className='error-msg'>{errors.mobile.errMsg[0]}</span>
                                                }
                                            </div>
                                            {/* <div className="col-md-4">
                                                <a className='text-orange' onClick={() => setShowModal(true)}> Verify With OTP</a>
                                            </div> */}
                                            <div className='col-md-12 error-postion'>
                                                <input type={isShowPassword ? "text" : "password"} className="form-control" placeholder="Password" name="password" onChange={SignupData} />
                                                {/* <span className='pwd-error-msg'>Password Mismatch</span> */}
                                                <span className='hide-password' onClick={showPassword}><i className={isShowPassword ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i></span>
                                                {errors.password.isError &&
                                                <span className='pwd-error-msg'>{errors.password.errMsg[0]}</span>   
                                                }
                                            </div>
                                            <div className='col-md-12 mt-4 form-group error-postion'>
                                                <input type="text" className="form-control" placeholder="Referal Code" name="referal_code" />
                                                {/* <span className='error-msg'>Error Message</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="login_submit">
                                        <div className='forgot-hint-text'> <label htmlFor="remember">
                                            <input id="remember" type="checkbox" name="terms" onClick={SignupData} />
                                            You must agree our terms and conditions
                                        </label>
                                        {termsErrorMsg !== "" ? 
                                        <span className='' style={{color:"red"}}>{termsErrorMsg}</span>
                                         : null}
                                        </div>
                                        <div className='login-submit-btn'>
                                            <button type="submit" >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className='login-footer'>
                                <div>
                                    <button className='google-btn' href='#'>
                                        <i className="fa fa-google" aria-hidden="true"></i> &nbsp; Sign in with google
                                    </button>
                                </div>
                                <div className='dont-account'>
                                    <Link to={'/login'}>Existing user? Log in</Link>
                                </div>
                            </div>
                            {/* <div className="alert alert-success" role="alert">
                                <a className="close" data-dismiss="alert" aria-hidden="true">×</a>Success
                            </div> */}
                            {/* <div className="alert alert-danger" role="alert">
                            <a className="close" data-dismiss="alert" aria-hidden="true">×</a>Danger alert
                            </div> */}

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <Footer />
        </>
    );
}
export default Signup;

