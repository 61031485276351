

const CartCountReducer = (state=0, action)=>{
    switch (action.type) {
        case "cartCount":
            return state = action.data;
        default:
            return state;
    }
}
export default CartCountReducer;