import React from 'react'
import ServicesTab from './servicesgallery';

const ElectricVehicles = () => {
   return (
      <section className="product_area mb-46">
         <div className="container bg-grey pt-20 pb-20">
            <div className="row">
               <div className="col-12">
                  <div className="section_title">
                     <h2 className='border-bottom'>Popular Products</h2>
                  </div>
                  <ServicesTab></ServicesTab>
               </div>
            </div>
         </div>
      </section>
      
   );
}
export default ElectricVehicles;