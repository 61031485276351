import React,{useEffect, useState} from 'react'
import MyAccountMenu from './myaccountmenu';
import { AddressAddApi } from '../../API/Address';
import { useSelector, useDispatch } from "react-redux";
import ReactCountryFlag from 'react-country-flag';
import { GetStateList, GetCityList, GetLocationType } from '../../API/UnAuthApi';
import { Navigate, useNavigate, useSearchParams, useLocation, Link, useParams } from 'react-router-dom';
import Footer from '../../Layouts/footer';
const AddressPage = (props) => {
    const profile = useSelector((state) => state.ProfileReducer);
    let mobileCode = props.mobileCode;
    const [addData, setAddData] = useState({address_line_1:props.address ? props.address.address : null ,is_primary:1});
    const [countryId, setCountryId] = useState();
    const [stateId, setstateId] = useState();
    const [allState, setAllState] = useState([]);
    const [allCity, setAllCity] = useState([]);
    const redirect = useNavigate();
    const [locationTypes, setLocationTypes] = useState([])

    useEffect(()=>{
        GetLocationType().then((response)=>{
            setLocationTypes(response.data.data)
        }).catch((err)=>{
            setLocationTypes([]);
        })
    },[])

    let addressFieldName = ['first_name','last_name','mcc_id','mobile','address_line_1','country_id','state_id','city_id','postal_code','location_type_id'];
    let addressFieldErrorName = ['First name','Last name','Mobile code','Mobile','Address Line 1','Country','State','City','Postal code','Location Type'];

    useEffect(() => {
        if (props.address) {
            const cid = props.countryLists.find((countryList) => countryList.name == props.address.country);
            let sid;
            let cityid
            if(allState.length > 0)
            {
                sid = allState.find((ast) => ast.name == props.address.state
                );
                setstateId(sid ? sid.id : null);
            }
            if(allCity.length > 0)
            {
                cityid = allCity.find((city)=> city.name == props.address.city);
            }
            setAddData((prev) => ({
                ...prev,
                address_line_1: props.address.address,
                country_id: cid ? cid.id : null,
                state_id: sid ? sid.id : null,
                city_id: cityid ? cityid.id : null,
                postal_code: props.address.pincode
            }));
            setCountryId(cid ? cid.id : null);
        }
      }, [props]);

    const initialLoginStateErrors = {
        first_name : {
            is_error : false,
            message  : ""
        },
        last_name : {
            is_error : false,
            message  : ""
        },
        mcc_id : {
            is_error : false,
            message  : ""
        },
        mobile : {
            is_error : false,
            message  : ""
        },
        address_line_1 : {
            is_error : false,
            message  : ""
        },
        country_id : {
            is_error : false,
            message  : ""
        },
        state_id : {
            is_error : false,
            message  : ""
        },
        city_id : {
            is_error : false,
            message  : ""
        },
        postal_code : {
            is_error : false,
            message  : ""
        },
        location_type_id : {
            is_error : false,
            message  : ""
        },
    };
    const [addressValidate, setAddressValidate] = useState(initialLoginStateErrors)
    useEffect(()=>{
        if(countryId)
        {
            GetStateList(countryId).then((result)=>{
                setAllState(result.data.data);
                console.log(result.data.data);
                let sid;
                if(props.address)
                {
                    sid = result.data.data.find((ast) => ast.name == props.address.state);
                }
                setstateId(sid ? sid.id : null);
            setAddData((prev) => ({
                ...prev,
                state_id: sid ? sid.id : null,
                city_id: null,
            }));
            setAllCity([]);
            }).catch((err)=>{
                setAllState([]);
            })
        }
    },[countryId])
    useEffect(()=>{
        if(countryId && stateId)
        {
            console.log('sdfj');
            GetCityList(countryId,stateId).then((result)=>{
                setAllCity(result.data.data);
                let cityid
                if(props.address)
                {
                    cityid = result.data.data.find((city)=> city.name == props.address.city);
                }
                setAddData((prev) => ({
                    ...prev,
                    city_id: cityid ? cityid.id : null,
                }));
            }).catch((err)=>{
                setAllCity([]);
            })
        }
    },[stateId])
    function validation(data) {
        let onlyText = /^[A-Za-z ]+$/;
        let onlyNumber = /^\d+$/;
        let onlyEmail = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/
        let errorsAvailable = false;
        let paramName = addressFieldName;
        let validReg = '';
        let isError = false;
        for(let i=0; i<paramName.length; i++)
        {
            let p_name =  paramName[i];
            if(i >= 3)
            {
                if(data.name == p_name)
                {
                    if(data.value !== "")
                    {
                        addressValidate[p_name].message = "";
                        addressValidate[p_name].is_error = false;
                    }
                    else{
                        addressValidate[p_name].message = addressFieldErrorName[i]+" is required";
                        addressValidate[p_name].is_error = true;
                    }
                } 
            }
            else
            {
                if(i === 2)
                {
                    validReg = onlyNumber;
                }
                else
                {
                    validReg = onlyText;
                }
                if(data.name == p_name)
                {
                    if(!validReg.test(data.value))
                    {
                        addressValidate[p_name].message = "Enter valid "+addressFieldErrorName[i];
                        addressValidate[p_name].is_error = true;
                    }
                    else{
                        addressValidate[p_name].message = "";
                        addressValidate[p_name].is_error = false;
                    } 
                } 
            }
        }
        setAddressValidate(addressValidate);
    }
    function addressFormData(e){
        validation(e.target)
        if(e.target.name == "country_id"){
            setCountryId(e.target.value)
        }
        if(e.target.name == "state_id")
        {
            setstateId(e.target.value)
        }
        setAddData({ ...addData, [e.target.name]: e.target.value })
    }
    function addSubmit(e){
        e.preventDefault();
        
        for(let i=0; i<addressFieldName.length; i++)
        {
            let paramName = addressFieldName[i];
            if(!addData[paramName]||addData[paramName] == "")
            {
                initialLoginStateErrors[paramName].message = addressFieldErrorName[i]+" is required";
                initialLoginStateErrors[paramName].is_error = true;
            }
            else
            {
                initialLoginStateErrors[paramName].message = "";
                initialLoginStateErrors[paramName].is_error = false;
            }
        }
        setAddressValidate(initialLoginStateErrors);
        if(addressValidate.first_name.is_error || addressValidate.last_name.is_error || addressValidate.mcc_id.is_error || addressValidate.mobile.is_error || addressValidate.address_line_1.is_error || addressValidate.country_id.is_error || addressValidate.state_id.is_error || addressValidate.city_id.is_error || addressValidate.postal_code.is_error || addressValidate.location_type_id.is_error)
        {

        }
        else
        {
            AddressAddApi(addData).then((result)=>{
                if(localStorage.getItem('path') === "/checkout")
                {
                    props.setShowModal(false);
                }
                else
                {
                    return redirect("/address");
                }
            }).catch((err)=>{
                console.log('address error')
            })
        }
    }

  return (
                            <form onSubmit={addSubmit}>
                    <div className="card-body">
                        <div className="row">
                            <div className='col-md-6 col-sm-12'>
                                <div className="form-group">
                                    <label className="form-label">First Name <span className='color-red'>*</span></label>
                                    <input type="text" className="form-control" name="first_name" placeholder="First Name" onChange={addressFormData}/>
                                    {addressValidate.first_name.is_error ? 
                                <span style={{color:"red"}}>{addressValidate.first_name.message}</span>
                                : null}
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className="form-group">
                                    <label className="form-label">Last Name <span className='color-red'>*</span></label>
                                    <input type="text" className="form-control" name="last_name" placeholder="Last Name" onChange={addressFormData}/>
                                    {addressValidate.last_name.is_error ? 
                                <span style={{color:"red"}}>{addressValidate.last_name.message}</span>
                                : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className='row'>
                                    <label className="form-label">Mobile Number <span className='color-red'>*</span></label>
                                    <div className="col-md-3 form-group">
                                        <select name="mcc_id" id="" className='form-control' onChange={addressFormData}>
                                            <option value="">Select</option>
                                            {props.mobileCode.length > 0 && props.mobileCode.map((m) => (
                                                <option value={m.id} ><ReactCountryFlag countryCode={m.country_code}/>  +{m.code}</option>
                                            
                                            ))}
                                        </select>
                                        {addressValidate.mcc_id.is_error ? 
                                <span style={{color:"red"}}>{addressValidate.mcc_id.message}</span>
                                : null}
                                    </div>
                                    <div className="col-md-9 form-group">
                                        <input type="text" className="form-control" name="mobile" placeholder="+256 987 456 1230" onChange={addressFormData}/>
                                        {addressValidate.mobile.is_error ? 
                                <span style={{color:"red"}}>{addressValidate.mobile.message}</span>
                                : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Address<span className='color-red'>*</span></label>
                                    <input type="text" className="form-control" name="address_line_1" placeholder="Address" onChange={addressFormData} value={addData.address_line_1 ? addData.address_line_1 : null}/>
                                    {addressValidate.address_line_1.is_error ? 
                                <span style={{color:"red"}}>{addressValidate.address_line_1.message}</span>
                                : null}
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className="form-group">
                                    <label className="form-label">Country <span className='color-red'>*</span></label>
                                    <select className="form-control" name="country_id" id="" onChange={addressFormData}>
                                        <option value="">Select Country</option>
                                    {props.countryLists.map((countryList) => (
                                            <option value={countryList.id} selected={addData.country_id === countryList.id ? 'selected' : null}>{countryList.name}</option>
                                    ))}
                                    </select>
                                    {addressValidate.country_id.is_error ? 
                                <span style={{color:"red"}}>{addressValidate.country_id.message}</span>
                                : null}
                                </div>
                            </div>
                            
                            <div className='col-md-6 col-sm-12'>
                                <div className="form-group">
                                    <label className="form-label">State / Province <span className='color-red'>*</span></label>
                                    <select name="state_id" id="" className='form-control' onChange={addressFormData}>
                                        <option value="">Select State</option>
                                        {allState.length > 0 ? 
                                        allState.map((state)=>(
                                            <option value={state.id} selected={addData.state_id && state.id == addData.state_id ? "selected" : null}>{state.name}</option>
                                        ))
                                        : null}
                                    </select>  
                                    {addressValidate.state_id.is_error ? 
                                <span style={{color:"red"}}>{addressValidate.state_id.message}</span>
                                : null}                                              
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className="form-group">
                                    <label className="form-label">City / Town <span className='color-red'>*</span></label>
                                    <select name="city_id" id="" className='form-control' onChange={addressFormData}>
                                        <option value="">Select City</option>
                                        {allCity.length > 0 ? 
                                        allCity.map((city)=>{
                                            return(
                                            <option value={city.id} selected={addData.city_id && addData.city_id == city.id ? "selected" : null}>{city.name}</option>
                                        )})
                                        : null}
                                    </select>
                                    {addressValidate.city_id.is_error ? 
                                <span style={{color:"red"}}>{addressValidate.city_id.message}</span>
                                : null}
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className="form-group">
                                    <label className="form-label">Postal Code <span className='color-red'>*</span></label>
                                    <input type="text" className="form-control" name="postal_code" placeholder="Postal Code" onChange={addressFormData} value={addData.postal_code}/>
                                    {addressValidate.postal_code.is_error ? 
                                <span style={{color:"red"}}>{addressValidate.postal_code.message}</span>
                                : null}
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="form-group form-elements">
                                    <div className="custom-controls-stacked display-flex">
                                        {locationTypes.length > 0 ? 
                                        locationTypes.map((item)=>{return(
                                        <label className="custom-control custom-radio mr-20">
                                            <input type="radio" className="custom-control-input" name="location_type_id" value={item.id} onChange={addressFormData}/>
                                            <span className="custom-control-label">{item.name}</span>
                                        </label>
                                        )})
                                        : null}
                                    </div>
                                    {addressValidate.location_type_id.is_error ? 
                                <span style={{color:"red"}}>{addressValidate.location_type_id.message}</span>
                                : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success w-100">Save Address</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
  )
}

export default AddressPage
