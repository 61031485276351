import axios from "axios";
import { getToken, removeToken } from "./Storage";

export function AddWishListApi(product,bulk="",page="",currency){
  const addWishlistUrl        = "/add/wishlist?currency="+currency+"&page="+page;
  let data = {data:product};
    const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
  return axios.post(addWishlistUrl,data,config);
}
export function RemoveWishListApi(product,bulk="",page="",currency){
  const removeWishlistUrl        = "/remove/wishlist?currency="+currency+"&page="+page;
  let data = {data:product};
    const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
  return axios.post(removeWishlistUrl,data,config);
}
export function WishlistDetailApi(page="",currency){
  const wishlistDetailUrl     = "/wishlist/list?currency="+currency+"&page="+page; 

    const token = getToken();
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
  return axios.get(wishlistDetailUrl,config);
}