import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AddToCartApi, CartListApi } from '../API/CartApi';
import { IsAuthenticated } from '../API/CheckAuth';
import { ConvertCurrency } from '../API/currency'
import {  } from '../API/Storage';
import { removeCartData, removeCartIdData, removeWishlistData, removeWishlistIdData, setToken, getCartData, getCurrencyCountry, getCartQuantity, getWishlistData, getToken, removeToken } from '../API/Storage';

import { useSelector, useDispatch } from "react-redux";
import { RecommendProductListApi, productDetails } from '../API/ProductApi';
import { removeCartQuantity } from '../API/Storage';

import { AddOrderDetails, AddPackagingDetails, CheckPackageApi } from '../API/OrderApi';
import { cartDetails, cartCount, UserDetails, wishlistDetails, wishlistCount } from "../Action/action";
import { Modal, Button } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';
import { LoginApi } from '../API/Auth';
import { AddWishListApi, WishlistDetailApi } from "../API/WishlistApi";
import { ProfileApi } from '../API/User';
import LoginPage from './loginPage';

function CartSummery(props) {
    const redirect = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const packageId = searchParams.get('id');
    const [cart, setCart] = useState(props.cart ? props.cart : []);
    const [quantity, setQuantity] = useState([]);
    const [price, setPrice] = useState([]);

    const CartListDetails = useSelector((state) => state.CartReducer);
    const rate = useSelector((state) => state.CurrencyRateReducer);

    const [productsTotalAmount, setProductsTotalAmount] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [getUrlPath, setGetUrlPath] = useState()
    const profileData = useSelector((state) => state.ProfileReducer);
    const cartCountDataReducer = useSelector((state) => state.CartCountReducer);

    const profile = useSelector((state) => state.ProfileReducer);

    const [currency, setCurrency] = useState(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);

    useEffect(() => {
        setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
    });

    useEffect(() => {
        setGetUrlPath(window.location.pathname);
    }, [])

    useEffect(() => {
        if (getUrlPath == "/payment-method") {
            if (CartListDetails.length === 0) {
                redirect("/shopping-cart")
            }
            if (!packageId) {
                redirect('/shopping-cart')
            }
            else {
                let data = { packageId: packageId }
                CheckPackageApi(data).then((result) => {

                }).catch((err) => {
                    redirect('/shopping-cart')
                });
            }
        }
    }, [getUrlPath])
    useEffect(() => {
        if (getUrlPath == "/checkout") {
            if (CartListDetails.length === 0) {
                redirect("/shopping-cart")
            }
        }
    }, [getUrlPath])

    useEffect(() => {
        let product_total_amount = 0;
        let total_amount_amount = 0;
        let cartQuantity = 0;
        let p = 0;
        
        if (CartListDetails.length > 0) {
            const data = CartListDetails.map(item => item.id);
            productDetails(data, currency).then((result) => {
                result.data.data.map((item, key) => {
                    const cartQuantity = CartListDetails[key].cartQuantity;
                    const p = item.price;
                    product_total_amount += cartQuantity * (p * rate);
                });
                setProductsTotalAmount(product_total_amount);
                total_amount_amount = totalAmount + product_total_amount;
                setTotalAmount(product_total_amount);
                if (props.setMinimumTotalAmount) {
                    props.setMinimumTotalAmount(product_total_amount);
                }
            })
            .catch((err) => { });
        }

    }, [currency, CartListDetails])
    function ProceedToOrder() {
        if (IsAuthenticated()) {
            if (getUrlPath === "/shopping-cart") {
                AddToCartApi(CartListDetails, "yes", currency).then((result) => {
                    redirect('/checkout')
                }).catch((err) => {
                    console.log(err);
                });
            }

            if (getUrlPath === '/checkout') {
                if (!props.packageDetailsData.finalCountry) {
                    props.packageDetailsData.finalCountry = profileData.country_id;
                }
                AddPackagingDetails(props.packageDetailsData).then((result) => {
                    let packagingDetailsId = result.data.message;
                    redirect('/payment-method?id=' + packagingDetailsId);
                }).catch((err) => {
                    props.setIsPackagingError(true)
                });
            }
            if (getUrlPath === '/payment-method') {
                let codMinimumPayment = rate * 30;
                let is_order = true;
                if (props.PaymentMethod.paymentMethod === "cod") {
                    if (codMinimumPayment >= totalAmount) {
                        is_order = true;
                        props.setpaymentMethodValidate({
                            is_error: false,
                            message: ""
                        })
                    }
                    else {
                        is_order = false;
                        props.setpaymentMethodValidate({
                            is_error: true,
                            message: "Cash on Delivery unavailable for Orders beyond " + ConvertCurrency(codMinimumPayment, 1, currency, 1)
                        })
                    }

                }
                else if (props.PaymentMethod.paymentMethod === "wallet") {
                    is_order = false;
                    redirect('/wallet')
                }
                else if (props.PaymentMethod.paymentMethod === "emi") {
                    is_order = false;
                    props.setpaymentMethodValidate({
                        is_error: true,
                        message: "Emi not available"
                    })
                }
                else {
                    is_order = true;
                    props.setpaymentMethodValidate({
                        is_error: false,
                        message: ""
                    })
                }
                if (is_order) {
                    AddOrderDetails(props.PaymentMethod, currency).then((result) => {
                        dispatch(cartDetails([]));
                        removeCartQuantity();
                        redirect('/orders')
                    }).catch((err) => {

                    });
                }
            }
        }
        else {
            setShowModal(true);
        }
    }
    // Start Login
    const [isResponseLoginError, setIsResponseLoginError] = useState(false);
    const [responseLoginErrors, setResponseLoginErrors] = useState({});
    // End Login

    return (
        <>
            <div className="col-lg-4 col-md-4 col-sm-12">
                {/* <div className='small-title mt-20 mb-20'>Offers & Benefits</div>
        <div className="single_shipping mb-20">
            <div className="shipping_icone">
                <img src="/assets/img/icon/badge-discount.png" alt="" />
            </div>
            <div className="shipping_content">
                <h2>&quot;WELCOME1000&quot; applied</h2>
                <p><span className='color-orange'>UGX 1000</span> coupon saving</p>
            </div>
            <div className="remove-text"><a href="">Remove</a></div>
        </div> */}
                <div className="coupon_code right">
                    <h3>Bill Summary</h3>
                    <div className="coupon_inner">
                        {/* <div className="cart_subtotal">
                    <p><strong>Order Value</strong></p>
                    <p className="cart_amount total-amount"> {ConvertCurrency(productsTotalAmount ? productsTotalAmount : 0.00 )}</p>
                </div> */}
                        {/* <div className="cart_subtotal">
                    <p>Delivery Pointer Fee</p>
                    <p className="cart_amount"><span className='text-through'>UGX 100</span> <span className='color-orange'>Free</span></p>a
                </div> */}
                        {getUrlPath == "/shopping-cart" ?
                            props.cart.map((item, key) => {
                                const foundObject = CartListDetails.find((obj) => obj.id === item.id);
                                return (
                                    <div className="" key={item.id}>
                                        <div className='row'>
                                            <div className='col-md-6'>{item.name}  {item.id}</div>
                                            <div className='col-md-2 text-right'>{foundObject && foundObject.cartQuantity ? foundObject.cartQuantity : 1}</div>
                                            <div className='col-md-4 color-orange text-right'>{ConvertCurrency(item.price, foundObject && foundObject.cartQuantity ? foundObject.cartQuantity : 1, currency, rate)}</div>
                                        </div>

                                    </div>
                                )
                            })
                            :
                            <div className="cart_subtotal">
                                <p><strong>Order Value</strong></p>
                                <p className="cart_amount total-amount"> {ConvertCurrency(productsTotalAmount ? productsTotalAmount : 0.00, 1, currency, 1)}</p>
                            </div>
                        }
                        {/* <div className="cart_subtotal">
                    <p>Discount on MRP</p>
                    <p className="cart_amount color-green">- UGX 2000</p>
                </div>
                <div className="cart_subtotal">
                    <p>Coupon Discount</p>
                    <p className="cart_amount color-green">- UGX 1000</p>
                </div> */}
                        {/* <div className="cart_subtotal">
                    <p>Tax & Charges</p>
                    <p className="cart_amount">- UGX 1000</p>
                </div> */}
                        <div className="cart_subtotal total-amount border-top mb-20 pt-20">
                            <p className='total-amount'>Total Amount</p>
                            <p className="total-amount cart_amount">{ConvertCurrency(totalAmount, 1, currency, 1)}</p>
                        </div>
                        <div className="checkout_btn">
                            <a onClick={ProceedToOrder}>Proceed to Order</a>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                            {isResponseLoginError &&
                            <div className="alert alert-danger" role="alert">
                                <a className="close" data-dismiss="alert" aria-hidden="true">×</a>
                                {Object.entries(responseLoginErrors).map((e)=>{
                                    const [key, msg] = e;
                                    return(
                                        <div key={key}> 
                                            {msg.map((v)=>(
                                                <div>{v}</div>
                                            ))}
                                        </div>
                                    )
                                })}

                            </div>
                            }
                                <div className="account_form login-form">
                                    <div className="account_form login-form">
                                    <LoginPage mobileCode={props.mobileCode} isPopup={true} setIsResponseLoginError={setIsResponseLoginError} setResponseLoginErrors={setResponseLoginErrors}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default CartSummery;