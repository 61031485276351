import React, { useState, useEffect } from 'react';
import { LoginApi } from '../API/Auth';
import { removeCartData, removeCartIdData, removeWishlistData, removeWishlistIdData, setToken, getCartData, getWishlistData, getCurrencyCountry } from '../API/Storage';
import { IsAuthenticated } from '../API/CheckAuth'
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { UserDetails, cartDetails, wishlistDetails, wishlistCount } from "../Action/action";

import { ProfileApi } from '../API/User';
import { getToken, removeToken } from "../API/Storage";
import { AddToCartApi, CartListApi} from '../API/CartApi';
import { AddWishListApi, WishlistDetailApi } from "../API/WishlistApi";
import ReactCountryFlag from 'react-country-flag';
import Cookies from 'universal-cookie';
import Select from 'react-select'
import { useAddToCart } from '../API/CartChanges';
import { useAddToWishlist } from '../API/WishlistChanges';
const LoginPage = (props) => {
    const dispatch = useDispatch();
    const redirect = useNavigate();
    const cookies = new Cookies();

    const [isRememberMe, setIsRememberMe] = useState(false);

    let mobileCode = props.mobileCode;
    let is_mcc = mobileCode.length > 0 ? true : false;
    const initialLoginStateErrors = {
        password: { required: false, errMsg: "" },
        username: { required: false, errMsg: "" },
    };
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [loginErrors, setLoginErrors] = useState(initialLoginStateErrors);
    
    const [isMobile, setIsMobile] = useState(isNaN(cookies.get("username")) || cookies.get("username") == "" ? false : true);
    // const [mobileCode, setMobileCode] = useState(mcc)
    const [hasError, setHasError] = useState(false);
    const [loginErrorNames, setLoginErrorNames] = useState([]);
    const [login, setLogin] = useState({
        username:  cookies.get("username") || "",
        password:  cookies.get("password") || "",
        mcc_id: "103",
        device_type: "web",
        token: "740f4707bebcf74f9b7c25d48e3358945f6aa01da5ddb387462c7eaf61bb78ad",
        udid: "740f4707bebcf74f9b7c25d48e3358945f6aa01da5ddb387462c7eaf61bb78ad",
        verify: "email",
        guard: "user",
    });
    const profile = useSelector((state) => state.ProfileReducer);

    const [currency, setCurrency] = useState()
    useEffect(()=>{
      setCurrency(getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code);
    });
    function loginCredentials(e) {
        if (e.target.name === 'username') {
            if (isNaN(e.target.value) || e.target.value == "") {
                setIsMobile(false)
            }
            else {
                setIsMobile(true);
            }
            if(isMobile)
            {
                if (!login.mcc_id)
                {
                    login.mcc_id = props.mobileCode[0].id;
                }
            }
            else
            {
                delete login.mcc_id;
            }
        }
        setHasError(false)
        setLogin({ ...login, [e.target.name]: e.target.value });
    }
    const addToCart = useAddToCart();
    const addToWishlist = useAddToWishlist();
  
    function userLogin(e) {
        e.preventDefault();
        let errors = initialLoginStateErrors;
        let hasError = false;
        if (!login.username) {
            errors.username.required = true;
            errors.username.errMsg = "username is required";
            hasError = true;
        }
        if (!login.password) {
            errors.password.required = true;
            errors.password.errMsg = "password is required";
            hasError = true;
        }
        
        setHasError(hasError);
        setLoginErrors(errors);
        if (!hasError) {
            LoginApi(login).then((response) => {
                if (response.data.message === "Success") {
                    let token = response.data.data.access_token;
                    if(isRememberMe)
                    {
                        cookies.set('username', login.username, { path: '/' });
                        cookies.set('password', login.password, { path: '/' });
                    }
                    setToken(token);
                    ProfileApiCall(token)
                    addToCart();
                    addToWishlist();
                    if(props.isPopup)
                    {
                        return redirect('/checkout');
                    }
                    if(localStorage.getItem('path') === "/login" || localStorage.getItem('path') === "/sign-up")
                    {
                        return redirect("/home");
                    }
                    else
                    {
                        return redirect(-1);
                    }
                }
            }).catch((err) => {
                let reserr = [];
                let errors = err.response.data.error;
                props.setIsResponseLoginError(true);
                props.setResponseLoginErrors(errors);

            }).finally(() => {
               
                
                
                // if(IsAuthenticated() && currency)
                // {
                //     CartListApi("",currency).then((response) => {
                //         if(response.data.status == "success")
                //         {
                //             dispatch(cartDetails(response.data.data))
                //         }
                //     }).catch((err) => {
                //         dispatch(cartDetails([]))
                //     });
                //     WishlistDetailApi("",currency).then((response) => {
                //         let wishlistProductIds = []
                //         dispatch(wishlistDetails(response.data.data.data));  
                //         response.data.product_ids.map((item)=>{
                //             wishlistProductIds.push(item.product_id);
                //         })
                //         dispatch(wishlistCount(wishlistProductIds))
                //     }).catch((err) => {
                //         dispatch(wishlistDetails(0));
                //     });
                // }
                  
            });
        }
    }
   

    function ProfileApiCall(token)
    {
        if(token)
        {
            ProfileApi(token).then((result) => {
                dispatch(UserDetails(result.data.data));
            }).catch((err) => {
            dispatch(UserDetails(err));
        
                if(err.response.status == 401)
                {
                    if(getToken())
                    {
                        removeToken();
                        return redirect("/login");
                    }
                }
            });
        }
    }
    function showPassword(){
        setIsShowPassword(isShowPassword === true ?  false : true )
    }
    if (IsAuthenticated()) {
        return <Navigate to="/home" />
    }
    const options = props.mobileCode && props.mobileCode.length > 0 ? props.mobileCode.map((mcc) => ({
        value: mcc.code,
        label: (
          <>
            <ReactCountryFlag countryCode={mcc.country_code} />
            +{+mcc.code}
          </>
        ),
      })) : [];
  return (
    <>
        <form action="" onSubmit={userLogin}>
            <div className={props.isPopup ? 'login-header pop-up-login' : "login-header" }>
                {!props.isPopup && 
                <h3><span className='border-bottom'>Login</span></h3> }
                    <div className="form-group m-0">
                        {/* <label className="form-label">Phone Number</label> */}

                        <div className="row gutters-xs">
                        {isMobile && <div className="col-3">
                                <select className="form-control select2 custom-select" name="mcc_id" onChange={loginCredentials} >
                                    {
                                        mobileCode.map((mcc) => { 
                                            return(
                                            <option value={mcc.id} selected={profile.currency ? mcc.country_code == profile.currency.country_code ? 'selected' : null : null} key={mcc.id}><ReactCountryFlag countryCode={mcc.country_code}/>  +{mcc.code}</option>
                                        )})
                                    }
                                </select>
                            </div>}
                            <div className={isMobile ? 'col-9 postion-rel' : 'col-12 postion-rel'}>
                                <input type="text" className="form-control" placeholder="Email ID / Mobile Number" name="username" onChange={loginCredentials} value={login.username}/>
                                {hasError && <span style={{ color: "red" }}>{loginErrors.username.errMsg}</span>}
                                {/* <span className='login-otp'>Login with OTP</span> */}
                                {/* <span className='error-msg'>Error Message</span> */}
                            </div>
                            
                            <div className='col-12 postion-rel mt-4 mb-4'>

                                <input type={isShowPassword ? "text" : "password"} name="password" onChange={loginCredentials} placeholder="Password" value={login.password}/>
                                {hasError && <span style={{ color: "red" }}>{loginErrors.password.errMsg}</span>}
                                <span className='hide-password' onClick={showPassword}><i className={isShowPassword ? "fa fa-eye":"fa fa-eye-slash"} aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>

                    <div className="login_submit">
                        <div className='forgot-hint-text'> <label for="remember">
                            <input id="remember" type="checkbox" onClick={()=>{setIsRememberMe(!isRememberMe)}}/>
                            Remember me
                        </label>
                        </div>
                        <Link to="/forgotpassword">Forgot Password?</Link>
                        <div className='login-submit-btn'>
                            <button type="submit" >Sign in</button>
                        </div>
                    </div>
            </div>
            <div className='login-footer'>
                <div><button className='google-btn' href='#'><i className="fa fa-google" aria-hidden="true"></i> &nbsp; Sign in with google</button></div>
                <div className='dont-account'>
                    <Link to={'/sign-up'}>
                        Dont' have an account? 
                        <span className='signup-hint'>Sign up for Free</span>
                    </Link>
                </div>
            </div>
            {/* <div className="alert alert-success" role="alert">
                    <a className="close" data-dismiss="alert" aria-hidden="true">×</a>Success
                </div> */}
                    
            
        </form>
    </>
  )
}

export default LoginPage;
