import React from 'react'
import { IsAuthenticated } from './CheckAuth'
import { AddWishListApi, RemoveWishListApi } from './WishlistApi'
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencyCountry, getWishlistData, setWishlistData } from './Storage';
import { wishlistDetails } from '../Action/action';

const useAddToWishlist = () => {
    const dispatch = useDispatch();
    const WishlistDetailReducer = useSelector((state) => state.WishlistReducer);
    const profile = useSelector((state) => state.ProfileReducer);
    let currency = getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code;
    const handleAddToWishlist = (product=WishlistDetailReducer) => {
        let data;
        if(Array.isArray(product))
        {
            data = product;
        }
        else 
        {
            data = [product]
        }
        if (IsAuthenticated()) 
        {
            AddWishListApi(data, "", "", currency).then((response) => {
                dispatch(wishlistDetails(response.data.data));
                console.log(response.data.data);
            }).catch((err) => {
                if (err.response.status === 422) {
                    dispatch(wishlistDetails([]))
                }    
            });
        }
        else {
            let index = WishlistDetailReducer === 0 ? -1 : WishlistDetailReducer.findIndex(item => item.id === product.id)
            if (index === -1) {
                setWishlistData(WishlistDetailReducer !== 0 ? [...WishlistDetailReducer, {id: product.id}] : [{id:product.id}]);
                dispatch(wishlistDetails(WishlistDetailReducer !== 0 ? [...WishlistDetailReducer, {id:product.id}] : [{id:product.id}]));
            }
        }
    }
    return handleAddToWishlist;
}


const useRemoveFromWishlist = () => {
    const dispatch = useDispatch();
    const WishlistDetailReducer = useSelector((state) => state.WishlistReducer);
    const profile = useSelector((state) => state.ProfileReducer);
    let currency = getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code;
    const handleRemoveFromWishlist = (product) => {
        if(IsAuthenticated())
        {
            RemoveWishListApi(product, "", "", currency).then((response) => {
                dispatch(wishlistDetails(response.data.data));
            }).catch((err) => {
                if (err.response.status === 422) {
                    dispatch(wishlistDetails([]))
                }    
            });
        }
        else{
            const wishlistLocal = JSON.parse(getWishlistData());
            const updatedWishlit = wishlistLocal.filter((item) => item.id !== product.id);
            setWishlistData(updatedWishlit);
            dispatch(wishlistDetails(updatedWishlit));
        }
    }
    return handleRemoveFromWishlist;
}

export { useAddToWishlist, useRemoveFromWishlist };
