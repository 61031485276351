import React, { useState, useEffect } from 'react'
import { BrowserRouter, Link } from 'react-router-dom';
import Footer from '../../Layouts/footer';
import MyAccountMenu from './myaccountmenu';


const GiftCards = (props) => {
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li>Gift Cards</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                <div className="tab-content dashboard_content bordered padding-full-side40">
                                <div className="section_title border-none"><h2 className='border-bottom'>Gift Cards</h2></div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="expanel expanel-default">
                                                <div className="expanel-body p-0">
                                                   <img src='/assets/img/bg/gift-cards-01.png' />
                                                </div>
                                                <div className="gift-card-footer bg-green"><a href='#'>Send Gift Card</a></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="expanel expanel-default">
                                                <div className="expanel-body p-0">
                                                   <img src='/assets/img/bg/gift-card-02.png' />
                                                </div>
                                                <div className="gift-card-footer bg-green"><a href='#'>Send Gift Card</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default GiftCards;