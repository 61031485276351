import React,{useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartDetails } from '../Action/action';
import { AddToCartApi, RemoveFromCartApi } from './CartApi';
import { IsAuthenticated } from './CheckAuth';
import { setCartData, getCurrencyCountry, getCartData } from '../API/Storage'
import { productDetails } from './ProductApi';
// import { setCartData, setCartIdData, getCartData, getCartIdData, removeCartData, removeCartIdData, getWishlistIdData, getWishlistData, setWishlistData, setWishlistIdData, setPathName, getCurrencyCountry } from '../API/Storage'

const useAddToCart = (product) => {
  const CartListDetails = useSelector((state) => state.CartReducer);
  const profile = useSelector((state) => state.ProfileReducer);
  const dispatch = useDispatch();
  let currency = getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code;
    const handleAddToCart = (product = CartListDetails) => {
        let data;
        if(Array.isArray(product))
        {
            data = product;
        }
        else 
        {
            data = [product]
        }
        if(IsAuthenticated()) {
            AddToCartApi(data, "", currency).then((response) => {
                if (response.data.status == "success") {
                    dispatch(cartDetails(response.data.data));
                }
            }).catch((err) => {
                if (err.response.status === 422) {

                }
            });
        }
        else
        {
            let cartItem;
            let cartLocal = JSON.parse(getCartData());
            let existingItemIndex;
            if(cartLocal && cartLocal.length > 0)
            {
                existingItemIndex = cartLocal.findIndex(item => item.id === product.id);
            }
            else
            {
                cartLocal = [];
                existingItemIndex = -1; 
            }
            if (existingItemIndex >= 0) {
                const updatedCart = cartLocal.map((item, index) => {
                    if (index === existingItemIndex) {
                        return { ...item, cartQuantity: product.cartQuantity ? product.cartQuantity : 1 };
                    }
                    return item;
                });
                setCartData(updatedCart);
                cartItem = updatedCart;
            } 
            else {
                const newItem = {
                    id: product.id,
                    price:product.price,
                    cartQuantity: product.cartQuantity ? product.cartQuantity : 1,
                }; 
                setCartData([...cartLocal, newItem]);
                cartItem = newItem;
            }
            const data = cartLocal.map(item => item.id);
            productDetails(data,currency).then((result) => {
                if(!IsAuthenticated())
                {
                    let id = [];
                    result.data.data.map((item,key)=>{
                        id.push({id:item.id,price:item.price,cartQuantity:cartLocal[key].cartQuantity});
                    })
                    if(existingItemIndex >= 0)
                    {
                        dispatch(cartDetails(JSON.parse(getCartData())))
                    }
                    else
                    {
                        dispatch(cartDetails([...id, cartItem]));
                    }
                }

            }).catch((err) => {
    
            });
        };
    }
    return handleAddToCart;
};

const useRemoveFromCart = ()=>{
    const CartListDetails = useSelector((state) => state.CartReducer);
    const profile = useSelector((state) => state.ProfileReducer);
    const dispatch = useDispatch();
    let currency = getCurrencyCountry().currencyCode ? getCurrencyCountry().currencyCode : !profile.currency ? "" : profile.currency.code;
    const handleremoveFromCart = (product)=>{
        if(IsAuthenticated()) {
            RemoveFromCartApi(product, "", currency).then((response) => {
                if (response.data.status == "success") {
                    dispatch(cartDetails(response.data.data));
                }
            }).catch((err) => {
                if (err.response.status === 422) {
                    dispatch(cartDetails([]));
                }
            });
        }
        else
        {
            const cartLocal = JSON.parse(getCartData());
            const updatedCart = cartLocal.filter((item) => item.id !== product.id);
            setCartData(updatedCart);
            dispatch(cartDetails(updatedCart));
        }
    }
    return handleremoveFromCart;
};

export { useAddToCart, useRemoveFromCart };