export function UserDetails(data){
    return {
        type: "Login",
        data: data
    }
}
export function cartDetails(data){
    return {
        type:"cart",
        data:data
    }
}
export function wishlistDetails(data){
    return {
        type:"wishlist",
        data:data
    }
}

export function wishlistCount(data){
    return {
        type:"wishlistCount",
        data:data
    }
}

export function cartCount(data){
    return {
        type:"cartCount",
        data:data
    }
}
export function currencyRate(data){
    return {
        type:"CurrencyRate",
        data:data
    }
}