import React, { useState, useEffect } from 'react';
import LoginPage from './loginPage';
import Footer from '../Layouts/footer';
const Login = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const [isResponseLoginError, setIsResponseLoginError] = useState(false);
    const [responseLoginErrors, setResponseLoginErrors] = useState({});
    useEffect(() => {
        setTimeout(() => {
            setIsResponseLoginError(false)
        }, 5000);
    }, [isResponseLoginError])
    return (
        <>
        <div className="login-bg">
            <div className="container">
                <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className='sign-up-error-msg'>
                            {isResponseLoginError &&
                            <div className="alert alert-danger" role="alert">
                                <a className="close" data-dismiss="alert" aria-hidden="true">×</a>
                                {Object.entries(responseLoginErrors).map((e)=>{
                                    const [key, msg] = e;
                                    return(
                                        <div key={key}> 
                                            {msg.map((v)=>(
                                                <div>{v}</div>
                                            ))}
                                        </div>
                                    )
                                })}

                            </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="account_form login-form">
                             <LoginPage mobileCode={props.mobileCode} isPopup={false} setIsResponseLoginError={setIsResponseLoginError} setResponseLoginErrors={setResponseLoginErrors}/>
                        </div>
                    </div>

            </div>
        </div>
        </div>
        <Footer />

    </>
    );

}
export default Login;