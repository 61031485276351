import React from 'react'
import Carousel from '../components/Carousel/Carousel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export default (props) => (
    <Tabs>
        <TabList>
            {props.othersService.map((item,key)=>{
                return(
                key !== 4 ? 
            <Tab>{item.name}</Tab>
            : null
            )})}
            {/* <Tab>EV Rides</Tab>
            <Tab>EV Charging</Tab>
            <Tab>Shopping & Delivery</Tab>
            <Tab>Vedio Consulations</Tab>
            <Tab>Payments</Tab>
            <Tab>On-Demand Services</Tab>
            <Tab>School App</Tab> */}
        </TabList>
        {props.othersService.map((item, key)=>{
            return(
                key !== 4 ? 
        <TabPanel>
            
            <div className='row tab-container'>
                
                 <Carousel show={3}>
                 {item.user_sub_modules.map((sitem)=>(
                <div className='service-pro-main'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img other-service-img" href="#"><img
                                src={"https://ev.public.heptotechnologies.org/core/"+sitem.image} alt="" /></a>

                                {/* <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div> */}

                            </div>
                            <figcaption className="category_name "><h3 className="product-name other-tab-text"><a href="#">{sitem.name}</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">BooK Now</a> </div>
                    </article>
                    </div>
                ))}
               </Carousel>
                
                {/* <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Taxi Ride</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">BooK Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Taxi Ride</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">BooK Now</a> </div>
                    </article>
                </div> */}
            </div>
            
        </TabPanel>
        : null )})}
        {/* <TabPanel>
            <div className='row tab-container'>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">EV Charging</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">BooK Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">EV Charging</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">BooK Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">EV Charging</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">BooK Now</a> </div>
                    </article>
                </div>
            </div>
        </TabPanel>
        <TabPanel>
            <div className='row tab-container'>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Shopping & Delivery</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Shopping & Delivery</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Shopping & Delivery</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
            </div>
        </TabPanel>
        <TabPanel>
            <div className='row tab-container'>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Vedio Consulations</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Vedio Consulations</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Vedio Consulations</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
            </div>

        </TabPanel>
        <TabPanel><div className='row tab-container'>
            <div className='col-md-4'>
                <article className="single_product service_tab">
                    <figure>
                        <div className="product_thumb"> <a className="primary_img" href="#"><img
                            src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                            <div className="action_links">
                                <ul>
                                    <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                    <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                        data-bs-target="#modal_box" title="quick view"> <span
                                            className="ion-ios-search-strong"></span></a></li>
                                </ul>
                            </div>

                        </div>
                        <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Payments</a></h3></figcaption>
                    </figure>
                    <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                </article>
            </div>
            <div className='col-md-4'>
                <article className="single_product service_tab">
                    <figure>
                        <div className="product_thumb"> <a className="primary_img" href="#"><img
                            src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                            <div className="action_links">
                                <ul>
                                    <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                    <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                        data-bs-target="#modal_box" title="quick view"> <span
                                            className="ion-ios-search-strong"></span></a></li>
                                </ul>
                            </div>

                        </div>
                        <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Payments</a></h3></figcaption>
                    </figure>
                    <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                </article>
            </div>
            <div className='col-md-4'>
                <article className="single_product service_tab">
                    <figure>
                        <div className="product_thumb"> <a className="primary_img" href="#"><img
                            src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                            <div className="action_links">
                                <ul>
                                    <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                        className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                    <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                        data-bs-target="#modal_box" title="quick view"> <span
                                            className="ion-ios-search-strong"></span></a></li>
                                </ul>
                            </div>

                        </div>
                        <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">Payment</a></h3></figcaption>
                    </figure>
                    <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                </article>
            </div>
        </div></TabPanel>
        <TabPanel>
            <div className='row tab-container'>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">On Demand Services</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">On Demand Services</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">On Demand Services</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
            </div>
        </TabPanel>
        <TabPanel>
            <div className='row tab-container'>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">School App</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">School App</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
                <div className='col-md-4'>
                    <article className="single_product service_tab">
                        <figure>
                            <div className="product_thumb"> <a className="primary_img" href="#"><img
                                src="/assets/img/product/other-evzone-servies/classic-street.png" alt="" /></a>

                                <div className="action_links">
                                    <ul>
                                        <li className="wishlist"><a href="#" title="Add to Wishlist"><i
                                            className="fa fa-heart-o" aria-hidden="true"></i></a></li>

                                        <li className="quick_button"><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#modal_box" title="quick view"> <span
                                                className="ion-ios-search-strong"></span></a></li>
                                    </ul>
                                </div>

                            </div>
                            <figcaption className="category_name"><h3 className="product-name other-tab-text"><a href="#">School App</a></h3></figcaption>
                        </figure>
                        <div className="default_btn"> <a href="#" title="add to cart">Shop Now</a> </div>
                    </article>
                </div>
            </div>
        </TabPanel> */}
    </Tabs>
);