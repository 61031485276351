import React, { useState, useEffect } from 'react'
import { BrowserRouter, Link } from 'react-router-dom';
import Footer from '../../Layouts/footer';
import MyAccountMenu from './myaccountmenu';


const ReturnOrders = (props) => {
    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <ul>
                                    <li><a href="#">home</a></li>
                                    <li>Return Orders</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="main_content_area bg-white">
                <div className="container">
                    <div className="account_dashboard">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 bg-dark">
                                <div className="section_title">
                                    <h3>My Account </h3>
                                </div>
                                <div className="dashboard_tab_button">
                                    <MyAccountMenu></MyAccountMenu>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-9 col-lg-9">
                                <div className="tab-content dashboard_content bordered padding-full-side40">
                                    <div className="section_title border-none"><h2 className='border-bottom'>Return Orders</h2></div>
                                    <div className='row mb-20'>
                                        <div className='col-md-10 col-sm-12'><div className="search_box my_order_search">
                                                <input placeholder="Search" type="text" />
                                                <button type="submit"><i className="fa fa-search" aria-hidden="true"></i> Search</button>
                                            </div></div>
                                        <div className='col-md-2 col-sm-12 my-order-filler'><a href='#'><img src='/assets/img/icon/filter.png' alt='Filter' />Filter</a></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="expanel expanel-default">
                                                <div className='my_order_header bg-orange'><div><strong>Order ID</strong> : EV321456</div>
                                                    <div><strong>Date</strong>: 23-11-2022</div>
                                                </div>
                                                <div className="expanel-body">
                                                    <div className='row'><div className='col-md-2 img-w100'><img src='/assets/img/product/my-orders/order-01.png' /></div>
                                                        <div className='col-md-7'><p>Model Y 3 Energy High Performance
                                                            Long Range Electric Vehicle charger</p>
                                                            <span className='color-orange'>UGB 2500X3</span>
                                                        </div>
                                                        <div className='col-md-3 text-right'>
                                                            <p>Status: <span className='color-orange'>Delivered</span></p>
                                                            <p>Total Amount: <span className='color-orange'>UGB 10,020</span></p>
                                                            <div className='return_order_btn'><button>Reason For Order</button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="expanel expanel-default">
                                                <div className='my_order_header bg-orange'><div><strong>Order ID</strong> : EV321456</div>
                                                    <div><strong>Date</strong>: 23-11-2022</div>
                                                </div>
                                                <div className="expanel-body">
                                                    <div className='row'><div className='col-md-2 img-w100'><img src='/assets/img/product/my-orders/order-01.png' /></div>
                                                        <div className='col-md-7'><p>Model Y 3 Energy High Performance
                                                            Long Range Electric Vehicle charger</p>
                                                            <span className='color-orange'>UGB 2500X3</span>
                                                        </div>
                                                        <div className='col-md-3 text-right'>
                                                            <p>Status: <span className='color-orange'>Delivered</span></p>
                                                            <p>Total Amount: <span className='color-orange'>UGB 10,020</span></p>
                                                            <div className='return_order_btn'><button>Reason For Order</button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="expanel expanel-default">
                                                <div className='my_order_header bg-orange'><div><strong>Order ID</strong> : EV321456</div>
                                                    <div><strong>Date</strong>: 23-11-2022</div>
                                                </div>
                                                <div className="expanel-body">
                                                    <div className='row'><div className='col-md-2 img-w100'><img src='/assets/img/product/my-orders/order-01.png' /></div>
                                                        <div className='col-md-7'><p>Model Y 3 Energy High Performance
                                                            Long Range Electric Vehicle charger</p>
                                                            <span className='color-orange'>UGB 2500X3</span>
                                                        </div>
                                                        <div className='col-md-3 text-right'>
                                                            <p>Status: <span className='color-red'>Cancelled</span></p>
                                                            <p>Total Amount: <span className='color-orange'>UGB 10,020</span></p>
                                                            <div className='return_order_btn'><button>Reason For Order</button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="shop_toolbar t_bottom">
                                <div className="pagination">
                                    <ul>
                                        <li className="current">1</li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li className="next"><a href="#">next</a></li>
                                        <li><a href="#">&gt; &gt;</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer />
        </>
    );
}
export default ReturnOrders;